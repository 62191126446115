import { useContext } from "react";
import ContextEstados from "../../context/Estados";
import { FaEye, FaPen, FaTrash } from 'react-icons/fa'


const TablaEquipos = () => {
    const { equipos,
        setEquipos,
        equipoSelected,
        setEquipoSelected,
        setTipoModal,
        setOpenVista,
        roles,
        buscarEquipo
    } = useContext(ContextEstados);


    const asignarEquipo = (id) => {
        let rData = [...equipos];
        rData.forEach(element => {
            if (element.idcentral === id) {
                element.view=!element.view;
            }
        });
        setEquipos(rData);
        equipos.forEach(element => {
            
            if (element.id === id) {
                setEquipoSelected(element)
                return
            }
        });
    }

    const verEquipo = async (id) => {
        asignarEquipo(id);
        setTipoModal(1)
        setOpenVista(true);
    }
    const editarEquipo = async (id) => {
        asignarEquipo(id);
        setTipoModal(2)
        setOpenVista(true);
    }
    const eliminarEquipo = async (id) => {
        asignarEquipo(id);
        setTipoModal(3)
        setOpenVista(true);
    }

    return (
        <>
            <table className='table-auto w-full border-collapse border-spacing-5 border-slate-400 text-gray-700 rounded-xl mx-auto mt-1'>
                <thead>
                    <tr className='uppercase bg-blue-400 text-white'>
                        <th className='border-gray-400 p-2'>
                                Código
                        </th>
                        <th className='border-gray-400 p-2'>
                                Nombre
                        </th>
                        <th className='border-gray-400 p-2'>
                                Yacimiento
                        </th>
                        <th className='border-gray-400 p-2'>
                                Ubicación
                        </th>
                        <th className='border-gray-400 p-2'>
                                Tipo
                        </th>
                        <th className='border-gray-400 p-2'>
                            Estado
                        </th>
                        <th className='border-gray-400 p-2'>
                            Actividad
                        </th>
                        <th className='border-gray-400 p-2'>
                            Acciones
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {equipos.map((item, index) => (
                        item.view && (
                            <tr
                                key={item.id}
                                className={`${equipoSelected.id === item.id ? item.subequipos.length > 0 ? 'font-bold bg-blue-200' : 'bg-blue-200' : equipoSelected.id === item.idcentral ? 'bg-blue-100' : item.subequipos.length > 0 ? 'font-bold bg-blue-50' : item.subequipos.length === 0 ? 'bg-white' : index % 2 === 0 ? 'bg-white hover:bg-slate-200' : 'bg-slate-100 hover:bg-slate-200'} ' hover:cursor-pointer border-b border-gray-200 ')`}
                                onClick={() => asignarEquipo(item.id)}
                            >
                                <td className={`border-gray-400 p-1 pl-3 ${equipoSelected.id === item.idcentral && 'pl-5'}`}>{item.codigo}</td>
                                <td className='border-gray-400 p-1'>{`${item.descripcion} ${item.codigo}`}</td>
                                <td className='border-gray-400 p-1 text-center'>{item.area}</td>
                                <td className='border-gray-400 p-1 text-center'>{item.nombreubicacion}</td>
                                <td className='border-gray-400 p-1 text-center uppercase'>{item.nombretipo}</td>
                                <td className='border-gray-400 p-1 text-center'><p className={`${item.idestado === '1' ? 'bg-green-300' : item.idestado === '2' ? 'bg-red-300' : item.idestado === '3' ? 'bg-orange-300' : 'bg-red-300'} ' p-2 rounded-md '`}>{item.estadoequipo}</p></td>
                                <td className='border-gray-400 p-1 text-center'><p className={`${item.actividad && 'bg-orange-300 p-1 rounded-lg'}`}>{item.actividad}</p></td>
                                <td className='p-1 flex justify-center'>
                                    <button
                                        className='bg-indigo-500 p-3 rounded-md text-white'
                                        onClick={() => verEquipo(item.id)}
                                    >
                                        <FaEye />
                                    </button>
                                    {(roles.includes("1") || roles.includes("11")) && (
                                        <>
                                            <button
                                                className='bg-gray-400 p-3 rounded-md text-white ml-3'
                                                onClick={() => editarEquipo(item.id)}
                                            >
                                                <FaPen />
                                            </button>
                                            <button
                                                className='bg-red-400 p-3 rounded-md text-white ml-3'
                                                onClick={() => eliminarEquipo(item.id)}
                                            >
                                                <FaTrash />
                                            </button>
                                        </>
                                    )}
                                </td>

                            </tr>

                        )
                    ))}
                </tbody>
            </table>
        </>
    )
}

export default TablaEquipos