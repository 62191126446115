import React, { useState, useEffect, useRef, useContext } from 'react'
import { useFormik } from "formik"
import axios from 'axios'
import * as Yup from "yup"
import ContextEstados from '../context/Estados'
import SubirImagenes from './SubirImagenes'
import { Url } from './Url'
import Select from 'react-select';

const FEditEquipo = () => {
    const [tiposEquipos, setTiposEquipos] = useState([]);
    const [equiposCentrales, setEquiposCentrales] = useState([])
    const [equipoCentral, setEquipoCentral] = useState(0)
    const [tipo, setTipo] = useState(0)
    const [areas, setAreas] = useState([]);
    const [area, setArea] = useState(0)
    const [ubicaciones, setUbicaciones] = useState([]);
    const [ubicacion, setUbicacion] = useState(0)
    const [estados, setEstados] = useState([]);
    const [estado, setEstado] = useState(0)
    const { idEquipo, setIdEquipo, equipoSelected, setEquipoSelected, setOpenVista, idUsuario } = useContext(ContextEstados);


    let tipoImagen = "equipos";
    let idHtml = "imagenesEquipo";
    const childRef = useRef()

    useEffect(() => {
        const consultaTipos = async () => {
            const url = Url + 'api/tEqList.php';
            const resultado = await axios.get(url);
            setTiposEquipos(resultado.data.results);
            setTipo(equipoSelected.idtipo);
        }
        consultaTipos()
    }, [])

    useEffect(() => {
        const getEquiposCentrales = async () => {
            const formData = new FormData()
            formData.append('idusuario', idUsuario)
            formData.append('idequipo', equipoSelected.id)
            formData.append('combo', 1)
            try {
                const response = await axios({
                    url: Url + 'api/eqCentralList.php',
                    method: 'POST',
                    data: formData,
                })
                setEquiposCentrales(response.data.results)
                setEquipoCentral(equipoSelected.idcentral)
            } catch (e) {
                console.log(e)
            }
        }
        getEquiposCentrales()
    }, [idEquipo])

    useEffect(() => {
        const consultaArea = async () => {
            const formData = new FormData()
            formData.append('idusuario', idUsuario)
            try {
                const response = await axios({
                    url: Url + 'api/areas.php',
                    method: 'POST',
                    data: formData,
                })
                setAreas(response.data.results)
                setArea(equipoSelected.idarea)
            } catch (e) {
                console.log(e)
            }
        }
        consultaArea()
    }, [])

    useEffect(() => {
        const consultaUbica = async () => {
            if (area !== 0) {
                const formData = new FormData()
                formData.append('idarea', area)
                try {
                    const response = await axios({
                        url: Url + 'api/ubicaciones.php',
                        method: 'POST',
                        data: formData,
                    })
                    setUbicaciones(response.data.results)
                    setUbicacion(equipoSelected.idubicacion)
                } catch (e) {
                    console.log(e)
                }
            }
        }
        consultaUbica()
    }, [area])

    useEffect(() => {
        const consultaEstados = async () => {
            const formData = new FormData()
            try {
                const response = await axios({
                    url: Url + 'api/estadosEq.php',
                    method: 'POST',
                    data: formData,
                })
                setEstados(response.data.results)
                setEstado(equipoSelected.idestado)
            } catch (e) {
                console.log(e)
            }
        }
        consultaEstados()
    }, [])

    useEffect(() => {
        if (idEquipo !== 0) {
            if (typeof childRef.current !== "undefined") {
                childRef.current.handleSubmit();
            }
        }
    }, [idEquipo])

    const eliminarImagen = async () => {
        const formData = new FormData()
        formData.append('t', "equipos");
        formData.append('id', equipoSelected.id);

        let oData = { ...equipoSelected };
        oData.imagen = null;
        setEquipoSelected(oData);
        try {
            const response = await axios({
                url: Url + 'api/deleteimages.php',
                method: 'POST',
                data: formData,
            })
            setIdEquipo(response.data.results.id);
            //setIdEquipo(0);
        } catch (e) {
            console.log(e)
        }
    }

    const formik = useFormik({
        initialValues: {
            codigo: equipoSelected.codigo,
            descripcion: equipoSelected.descripcion,
            observaciones: equipoSelected.observaciones
        },
        validationSchema: Yup.object({
            codigo: Yup.string()
                .required("El código es necesario"),
            descripcion: Yup.string()
                .required("Debe ingresar un nombre de equipo")
        }),
        onSubmit: async (valores, { resetForm }) => {
            const formData = new FormData()
            formData.append('id', equipoSelected.id);
            formData.append('codigo', valores.codigo);
            formData.append('descripcion', valores.descripcion);
            formData.append('tipo', tipo)
            formData.append('estado', estado)
            formData.append('ubicacion', ubicacion)
            formData.append('equipoCentral', equipoCentral)
            formData.append('observaciones', valores.observaciones)
            try {
                const response = await axios({
                    url: Url + 'api/equiposSet.php',
                    method: 'POST',
                    data: formData,
                })
                setIdEquipo(response.data.results.idequipo);
                setOpenVista(false);
            } catch (e) {
                console.log(e)
            }
        }
    });

    const addSubEquipo = async () => {
        const formData = new FormData()
        formData.append('idcentral', equipoSelected.id);
        formData.append('idsubequipo', equipoCentral)
        try {
            const response = await axios({
                url: Url + 'api/addSubEquipo.php',
                method: 'POST',
                data: formData,
            })
            setIdEquipo(response.data.results.idequipo);
            //setOpenVista(false);
            let eqAux = equipoSelected;
            let objSub = {}
            equiposCentrales.forEach(element => {
                if (element.id === equipoCentral) {
                    objSub = {
                        "id": element.id,
                        "codigo": element.codigo,
                        "descripcion": element.descripcion
                    }
                    eqAux.subequipos.push(objSub);
                }
            });
            //eqAux.subequipos=[...eqAux]
            setEquipoSelected(eqAux)

        } catch (e) {
            console.log(e)
        }
    }

    const quitSubEquipo = async (id) => {
        const formData = new FormData()
        formData.append('id', equipoSelected.id);
        formData.append('subEquipo', id)
        try {
            const response = await axios({
                url: Url + 'api/quitSubEquipo.php',
                method: 'POST',
                data: formData,
            })
            setIdEquipo(response.data.results.idequipo);
            //setOpenVista(false);
            let eqAux = equipoSelected;
            let rData = []
            equipoSelected.subequipos.forEach(element => {
                if (element.id !== id) {
                    rData.push(element);
                }
            });

            eqAux.subequipos = [...rData]
            setEquipoSelected(eqAux)
        } catch (e) {
            console.log(e)
        }
    }

    const handleSelect = (val) => {
        setEquipoCentral(val.id)
    }

    return (
        <form
            className="w-full mx-auto p-5 rounded-2xl"
            onSubmit={formik.handleSubmit}
        >
            <div className="transition-all w-full sm:p-3">
                <div className="sm:items-start">
                    <label htmlFor="codigo" className="block mt-5 text-gray-600 uppercase font-bold">Código</label>
                    <input
                        className="py-2 px-3 w-full rounded bg-slate-300"
                        id="codigo"
                        placeholder="Ingrese un código"
                        type="text"
                        value={formik.values.codigo}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoFocus
                    />
                    {formik.touched.codigo && formik.errors.codigo ? (
                        <div className='w-full block mb-2 text-red-500'>
                            <p>{formik.errors.codigo}</p>
                        </div>
                    ) : null}
                </div>
                <div className="sm:items-start mt-5">
                    <label htmlFor="descripcion" className="block text-gray-600 uppercase font-bold">Nombre</label>
                    <input
                        className="py-2 px-3 w-full rounded bg-slate-300"
                        id="descripcion"
                        placeholder="Nombre de Equipo"
                        type="text"
                        value={formik.values.descripcion}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.descripcion && formik.errors.descripcion ? (
                        <div className='w-full block mb-2 text-red-500'>
                            <p>{formik.errors.descripcion}</p>
                        </div>
                    ) : null}
                </div>
                <div className="sm:items-start mt-5">
                    <label htmlFor="sTipos" className="block text-gray-600 uppercase font-bold">Tipo de Equipo</label>
                    <select
                        className='py-2 px-3 w-full rounded bg-slate-300'
                        id='sTipos'
                        onChange={(e) => setTipo(e.target.value)}
                        value={tipo}
                    >
                        {
                            tiposEquipos.map(item => (
                                <option key={item.id} value={item.id}>{item.nombre}</option>
                            ))
                        }
                    </select>
                </div>
                <div className="sm:items-start mt-5">
                    <label htmlFor="sEquipoCentral" className="block text-gray-600 uppercase font-bold">Equipo Central</label>
                    <Select
                        options={equiposCentrales}
                        placeholder="Seleccione Equipo"
                        onChange={(value) => handleSelect(value)}
                        name='sEquipoCentral'
                    />
                </div>
                <div className="sm:items-start mt-5">
                    <label htmlFor="sArea" className="block text-gray-600 uppercase font-bold">Yacimiento</label>
                    <select
                        className='py-2 px-3 w-full rounded bg-slate-300'
                        id='sArea'
                        onChange={(e) => setArea(e.target.value)}
                        value={area}
                    >
                        {typeof areas !== "undefined" ? (
                            areas.map(item => (
                                <option key={item.id} value={item.id}>{item.nombre}</option>))
                        ) : null}
                    </select>
                </div>
                <div className="sm:items-start mt-5">
                    <label htmlFor="sUbicacion" className="block text-gray-600 uppercase font-bold">Ubicación</label>
                    <select
                        className='py-2 px-3 w-full rounded bg-slate-300'
                        id='sUbicacion'
                        onChange={(e) => setUbicacion(e.target.value)}
                        value={ubicacion}
                    >
                        {
                            typeof ubicaciones !== "undefined" && (
                                ubicaciones.map(item => (
                                    <option key={item.id} value={item.id}>{item.nombre}</option>
                                )))
                        }
                    </select>
                </div>
                <div className="sm:items-start mt-5">
                    <label htmlFor="sUbicacion" className="block text-gray-600 uppercase font-bold">Estado de Equipo</label>
                    <select
                        className='py-2 px-3 w-full rounded bg-slate-300'
                        id='sEstado'
                        onChange={(e) => setEstado(e.target.value)}
                        value={estado}
                    >
                        {
                            typeof estados !== "undefined" && (
                                estados.map(item => (
                                    <option key={item.id} value={item.id}>{item.nombre}</option>
                                )))
                        }
                    </select>
                </div>
                <div className="sm:items-start mt-5">
                    <label htmlFor="observaciones" className="block text-gray-600 uppercase font-bold">Observaciones</label>
                    <input
                        className="py-2 px-3 w-full rounded bg-slate-300"
                        id="observaciones"
                        placeholder="Observaciones"
                        type="text"
                        value={formik.values.observaciones}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </div>
            </div>
            <div className='w-full sm:p-3'>
                <h3 className='text-center text-2xl uppercase mt-5 bg-blue-400 text-white'>Sub Equipos</h3>
                {
                    equipoSelected.subequipos && equipoSelected.subequipos.map((item, index) =>
                    (
                        <div key={index} className='w-full flex'>
                            <p
                                className={`w-5/6 text-xl text-gray-600 uppercase p-1 hover:cursor-pointer ${index % 2 === 0 ? 'bg-white hover:bg-slate-200' : 'bg-slate-100 hover:bg-slate-200'}`}

                            >
                                {`${item.codigo} - ${item.descripcion}`}
                            </p>
                            <button
                                type='button'
                                className="w-1/6 p-2 m-1 bg-red-600 text-gray-100 hover:bg-red-400 transition-all uppercase font-bold rounded-lg"
                                onClick={() => quitSubEquipo(item.id)}
                            >
                                Quitar
                            </button>
                        </div>
                    ))
                }
                <Select
                    options={equiposCentrales}
                    placeholder="Seleccione Equipo"
                    onChange={(value) => handleSelect(value)}
                    name='sSubEquipo'
                />
                {equipoCentral !== '0' && (
                    <button
                        type='button'
                        className={`w-1/3 sm:w-1/4 shadow-md p-3 bg-orange-600 text-gray-100 hover:bg-orange-400 transition-all uppercase font-bold rounded-lg`}
                        onClick={() => addSubEquipo()}
                    >Agregar Sub Equipo</button>
                )}
            </div>
            <div className="pb-10">
                <div className='w-full flex mt-10'>
                    {equipoSelected.imagenes.length > 0 ? equipoSelected.imagenes.map(item =>
                    (
                        <div className='lg:w-1/6 md:w-1/4 w-1/2 p-2'>
                            <img className='w-full object-cover rounded-xl' src={`${Url}${item.imagen}`} />
                            <button
                                type='button'
                                className='w-full bg-red-500 text-white rounded-b-md'
                                onClick={() => eliminarImagen()}
                            >Eliminar</button>
                        </div>
                    )
                    ) : (
                        <SubirImagenes
                            id={equipoSelected.id}
                            tipo={tipoImagen}
                            ref={childRef}
                            idHtml={idHtml}
                        />
                    )}
                </div>
            </div>
            <div className="pb-1">
                <div className="w-full rounded-lg px-3 flex justify-center">
                    <input
                        type="submit"
                        className="w-1/3 sm:w-1/4 shadow-md p-3 bg-indigo-600 text-gray-100 hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg"
                        value="Guardar Datos"
                    />
                    <button
                        type="button"
                        className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                        onClick={() => setOpenVista(false)}
                    >
                        Salir
                    </button>
                </div>
            </div>
        </form>
    )
}

export default FEditEquipo