import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import ContextEstados from '../context/Estados';
import { FaEye, FaPen, FaTrash } from 'react-icons/fa'
import { AiFillFileAdd } from 'react-icons/ai'
import { Url } from './Url';

const ListadoVariables = () => {
    const [ variables, setVariables ] = useState([]);
    const { idVariable, setVariableSelected, equipoSelected, setTipoModal , setOpenVista } = useContext(ContextEstados);

    useEffect(()=>{
        const consultaVariables = async () => {
            const formData=new FormData()
            formData.append('equipo',equipoSelected.id)
            try{
                const response = await axios({
                    url: Url+'api/varsListado.php',
                    method: 'POST',
                    data: formData,
                })
                setVariables(response.data.results)
            } catch (e) {
                console.log(e)
            }        
        }
        consultaVariables()
    },[idVariable])

    const asignarVariable = (id) => {
        variables.forEach(element => {
            if (element.id===id)
            {
                setVariableSelected({
                    id:element.id,
                    nombre:element.nombre,
                    idequipo: element.idequipo,
                    equipo: element.equipo,
                    unidad:element.unidad,
                    idunidad:element.idunidad,
                    acumula:element.acumula,
                    ultimovalor:element.ultimovalor,
                    ultimafecha:element.ultimafecha,
                    incremento:element.incremento
                })
            }
        });
    }

    const addVariable = async (id) => {
        asignarVariable(id);
        setTipoModal(13)
        setOpenVista(true);
    }
    const verVariable = async (id) => {
        asignarVariable(id);
        setTipoModal(10)
        setOpenVista(true);
    }
    const editarVariable = async (id) => {
        asignarVariable(id);
        setTipoModal(11)
        setOpenVista(true);
    }
    const eliminarVariable = async (id) => {
        asignarVariable(id);
        setTipoModal(12)
        setOpenVista(true);
    }


    return (
    <div>
        <table className = 'table-auto w-full border-collapse border-spacing-5 border-slate-400 text-gray-700 rounded-xl mx-auto' >
            <thead>
                <tr className='uppercase bg-gray-300'>
                    <th className='border-gray-400 p-2'>Equipo</th>
                    <th className='border-gray-400 p-2'>Nombre</th>
                    <th className='border-gray-400 p-2'>Unidad</th>
                    <th className='border-gray-400 p-2'>Acumulable</th>
                    <th className='border-gray-400 p-2'>Ultimo Valor</th>
                    <th className='border-gray-400 p-2'>Ultima Fecha</th>
                    <th className='border-gray-400 p-2'>Acumulado</th>
                    <th className='border-gray-400 p-2'>Acciones</th>
                </tr>
            </thead>
            <tbody >
            {
                typeof variables !== 'undefined' ? (
                    variables.map((variable) => (
                    
                    <tr 
                        key={variable.id}
                        className={ ('bg-white hover:bg-slate-100 border-b border-gray-200') }
                    >
                        <td className='border-gray-400 p-2 text-center'>{variable.equipo}</td>
                        <td className='border-gray-400 p-2 text-center'>{variable.nombre}</td>
                        <td className='border-gray-400 p-2 text-center'>{variable.unidad}</td>
                        <td className='border-gray-400 p-2 text-center'>{variable.acumula}</td>
                        <td className='border-gray-400 p-2 text-center'>{variable.ultimovalor}</td>
                        <td className='border-gray-400 p-2 text-center'>{variable.ultimafecha}</td>
                        <td className='border-gray-400 p-2 text-center'>{variable.incremento}</td>
                        <td className='p-2 flex justify-center'>
                            <button
                                className='bg-orange-500 p-3 rounded-md text-white'
                                onClick={() => addVariable(variable.id)}
                            >
                                <AiFillFileAdd/>
                            </button>
                            <button
                                className='bg-indigo-500 p-3 rounded-md text-white ml-3'
                                onClick={() => verVariable(variable.id)}
                            >
                                <FaEye/>
                            </button>
                            <button
                                className='bg-gray-400 p-3 rounded-md text-white ml-3'
                                onClick={() => editarVariable(variable.id)}
                            >
                                <FaPen/>
                            </button>
                            <button
                                className='bg-red-400 p-3 rounded-md text-white ml-3'
                                onClick={() => eliminarVariable(variable.id)}
                            >
                                <FaTrash/>
                            </button>
                        </td>
                    </tr>
                    
                ))
                ) : ( null )
            }
            </tbody>
        </table>
    </div>
    )
}

export default ListadoVariables