import { useState, useEffect, useContext } from 'react'
import ContextEstados from '../context/Estados';
import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "../firebase";
import axios from 'axios';
import { Url } from './Url';

const BackNotifications = () => {
    const [ token, setToken ] = useState("");
    const { idUsuario, setNotification, setTokens } = useContext(ContextEstados);

    useEffect(()=>{
        const activarMensajes = async () => {
            if (token==='')
            {
                const tk = await getToken(messaging, {
                    vapidKey: "BG6cVobTixzUe5QGTGciZBjCvTJEbnM9DQFFS3IBY9NmdISIF8tkzA6mnCLkCW5RVREDuo48h_joMekvEMSP-mA"
                }).catch(error => console.log("Ocurrió un error"));
        
                if (tk){
                    
                    setToken(tk)
                }
                if (!tk) console.log("Sin token");
            }
            else {
                const formData=new FormData()
                formData.append('idusuario',idUsuario);
                formData.append('tokenf',token);
                try{
                    const response = await axios({
                        url: Url+'api/userTokenFire.php',
                        method: 'POST',
                        data: formData,
                    })
                    setTokens(response.data.results.tokens)
                } catch (e) {
                    console.log(e)
                }
            }
        }
        activarMensajes()    
    },[])

    useEffect(() => {
        onMessage(messaging, message => {
            setNotification(message.notification.body)
            //toast(message.notification.title);
        })
    }, []);
}

export default BackNotifications