import React, { useContext } from 'react'
import ContextEstados from '../context/Estados';
import axios from 'axios'
import { Url } from './Url'

const FDeleteActRepuesto = () => {
    const { setIdActRepuesto, setOpenVista, actRepuestoSelected, setTipoModal } = useContext(ContextEstados);

    const eliminarActRepuesto = async () => {
        const formData=new FormData()
        formData.append('id',actRepuestoSelected.id);
        try{
            const response = await axios({
                url: Url+'api/actRepuestosDelete.php',
                method: 'POST',
                data: formData,
            })
        setIdActRepuesto(response.data.results.idtarea);
        setTipoModal(16);
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className='mt-10 mb-10 px-10'>

            <h3>Repuestos de Actividad</h3>
            <p className='text-xl mt-4 text-gray-600'>Descripción: {actRepuestoSelected.descripcion}</p>
            <p className='text-xl mt-4 text-gray-600'>Cantidad: {actRepuestoSelected.cantidad}</p>
            <p className='text-xl mt-4 text-gray-600'>Costo: {actRepuestoSelected.costo}</p>
            <div className="w-full rounded-lg mt-10 px-3 flex justify-center">
                <button
                    type="button"
                    className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-red-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => eliminarActRepuesto()}
                >
                    Eliminar
                </button>
                <button
                    type="button"
                    className="w-1/4 sm:w-1/6 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => setTipoModal(16)}
                >
                    Salir
                </button>
            </div>
        </div>
    )
}

export default FDeleteActRepuesto