import React, { useContext } from 'react'
import { useSortableData } from "./useSortableData";
import ContextEstados from '../../context/Estados';
import { FaEye, FaPen, FaTrash } from 'react-icons/fa'
import Tippy from '@tippyjs/react';

const TablaUsuarios = props => {
    const { items, requestSort, sortConfig } = useSortableData(props.usuarios);
    const { 
        setOpenVista, 
        setTipoModal,   
        usuarios,     
        setUsuarioSelected,      
        roles} = useContext(ContextEstados);

        const getClassNamesFor = (name) => {
            if (!sortConfig) {
                return;
            }
            return sortConfig.key === name ? sortConfig.direction : undefined;
        };

    const asignarUsuario = (id) => {
        usuarios.forEach(element => {
            if (element.id===id)
            {
                setUsuarioSelected(element)
            }
        });
    }

    const verUsuario = async (id) => {
        asignarUsuario(id);
        setTipoModal(4)
        setOpenVista(true);
    }
    const editarUsuario = async (id) => {
        asignarUsuario(id);
        setTipoModal(5)
        setOpenVista(true);
    }
    const eliminarUsuario = async (id) => {
        asignarUsuario(id);
        setTipoModal(6)
        setOpenVista(true);
    }


    return (
    <div>
        <table className = 'table-auto w-full border-collapse border-spacing-5 border-slate-400 text-gray-700 rounded-xl mx-auto' >
            <thead>
                <tr className='uppercase bg-blue-400 text-white'>
                    <th className='border-gray-400 p-2'>
                    <button
                        type="button"
                        onClick={() => requestSort('apellido')}
                        className={getClassNamesFor('apellido')}
                    >
                        Apellido
                        </button>
                        </th>
                    <th className='border-gray-400 p-2'>
                    <button
                        type="button"
                        onClick={() => requestSort('nombre')}
                        className={getClassNamesFor('nombre')}
                    >Nombre</button></th>
                    <th className='border-gray-400 p-2'>
                    <button
                        type="button"
                        onClick={() => requestSort('mail')}
                        className={getClassNamesFor('mail')}
                    >Mail</button></th>
                    <th className='border-gray-400 p-2'>
                    <button
                        type="button"
                        onClick={() => requestSort('nombreusuario')}
                        className={getClassNamesFor('nombreusuario')}
                    >Usuario</button></th>
                    <th className='border-gray-400 p-2'>
                    <button
                        type="button"
                        onClick={() => requestSort('especialidad')}
                        className={getClassNamesFor('especialidad')}
                    >Especialidad</button></th>
                    <th className='border-gray-400 p-2'>Habilitado</th>
                    <th className='border-gray-400 p-2'>Acciones</th>
                </tr>
            </thead>
            <tbody >
            {
                typeof items !== 'undefined' ? (
                    items.map((item,index) => (
                    
                    <tr 
                        key={item.id}
                        className={`hover:bg-slate-200 border-b border-gray-200 ${index%2===0 ?'bg-white':'bg-slate-100'}`}
                    >
                        <td className='border-gray-400 p-1 text-center'>{item.apellido}</td>
                        <td className='border-gray-400 p-1 text-center'>{item.nombre}</td>
                        <td className='border-gray-400 p-1 text-center'>{item.mail}</td>
                        <td className='border-gray-400 p-1 text-center'>{item.nombreusuario}</td>
                        <td className='border-gray-400 p-1 text-center'>{item.especialidad}</td>
                        <td className='border-gray-400 p-1 text-center'>{`${item.habilitado==='1' ? "Si":"No"}`}</td>
                        <td className='p-1 flex justify-center'>
                            <Tippy
                                content={ <span className='bg-gray-600 text-white p-2 rounded-md'>Ver Usuario</span> }
                            >
                                <button
                                    className='bg-indigo-500 p-3 rounded-md text-white'
                                    onClick={() => verUsuario(item.id)}
                                >
                                    <FaEye/>
                                </button>
                            </Tippy>
                            { (roles.includes("1")) && (
                            <>
                            <Tippy
                                content={ <span className='bg-gray-600 text-white p-2 rounded-md'>Editar Usuario</span> }
                            >
                                <button
                                    className='bg-gray-400 p-3 rounded-md text-white ml-3'
                                    onClick={() => editarUsuario(item.id)}
                                >
                                    <FaPen/>
                                </button>
                            </Tippy>
                            <Tippy
                                content={ <span className='bg-gray-600 text-white p-2 rounded-md'>Eliminar Usuario</span> }
                            >
                                <button
                                    className='bg-red-400 p-3 rounded-md text-white ml-3'
                                    onClick={() => eliminarUsuario(item.id)}
                                >
                                    <FaTrash/>
                                </button>
                            </Tippy>
                            </>
                            )}
                        </td>
                    </tr>
                    
                ))
                ) : ( null )
            }
            </tbody>
        </table>
    </div>
    )
}

export default TablaUsuarios