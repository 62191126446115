import React, { useState, useEffect, useContext } from 'react'
import { useFormik } from "formik"
import axios from 'axios'
import * as Yup from "yup"
import ContextEstados from '../context/Estados';
import FormActRepuestos from './FormActRepuestos';
import FormSolRepuestos from './FormSolRepuestos';
import ListadoActRepuestos from './ListadoActRepuestos';
import ListadoSolRepuestos from './ListadoSolRepuestos';
import ListadoOtSupers from './ListadoOtSupers';
import ListadoOtTecnicos from './ListadoOtTecnicos';
import RecursosOT from './RecursosOT';
import Recursos from './Recursos';
import RecursosSol from './RecursosSol';
import { Url } from './Url';


const FEditOrden = () => {
    const [tipos, setTipos] = useState([]);
    const [tipo, setTipo] = useState(0);
    const [prioridades, setPrioridades] = useState([]);
    const [prioridad, setPrioridad] = useState(0);
    const [tecnicos, setTecnicos] = useState([]);
    const [tecnico, setTecnico] = useState(0);
    const [supervisores, setSupervisores] = useState([]);
    const [supervisor, setSupervisor] = useState(0);
    const [vistaRecursos, setVistaRecursos] = useState(0);
    const [horasTrabajo, setHorasTrabajo] = useState(0)
    const { setIdOrden, ordenSelected, setMessageOut, setVistaOrdenes, tipoModal, openVista, setOpenVista, idUsuario, tipoUsrLogin } = useContext(ContextEstados);

    useEffect(() => {
        const consultaTipos = async () => {
            const url = Url + '/api/lTiposOrdenes.php';
            const resultado = await axios.get(url);
            setTipos(resultado.data.results);
            if (ordenSelected.idtipo !== 0)
                setTipo(ordenSelected.idtipo)
            else
                setTipo(resultado.data.results[0].id)
        }
        consultaTipos()
    }, [])

    useEffect(() => {
        const consultaPrioridades = async () => {
            const url = Url + '/api/lPrioridades.php';
            const resultado = await axios.get(url);
            setPrioridades(resultado.data.results);
            if (ordenSelected.idprioridad !== 0)
                setPrioridad(ordenSelected.idprioridad)
            else
                setPrioridad(resultado.data.results[0].id)
        }
        consultaPrioridades()
    }, [])

    useEffect(() => {
        const consultaHorasTrabajo = async () => {
            const formData = new FormData()
            formData.append('idot', ordenSelected.id);
            try {
                const resultado = await axios({
                    url: Url + 'api/getHorasTrabajo.php',
                    method: 'POST',
                    data: formData,
                })
                setHorasTrabajo(resultado.data.results.tiempo);
            } catch (e) {
                console.log(e)
            }
        }
        consultaHorasTrabajo()
    }, [])

    useEffect(() => {
        const consultaUsuarios = async () => {
            const formData = new FormData()
            formData.append('tipo', 2);
            formData.append('idot', ordenSelected.id);
            formData.append('idusuario', idUsuario)
            try {
                const resultado = await axios({
                    url: Url + 'api/usuariosListado.php',
                    method: 'POST',
                    data: formData,
                })
                setTecnicos(resultado.data.results);
                setTecnico(resultado.data.results[0].id);
            } catch (e) {
                console.log(e)
            }
        }
        consultaUsuarios()
    }, [])

    useEffect(() => {
        const consultaUsuarios = async () => {
            const formData = new FormData()
            formData.append('tipo', 4);
            formData.append('idusuario', idUsuario)
            try {
                const resultado = await axios({
                    url: Url + 'api/usuariosListado.php',
                    method: 'POST',
                    data: formData,
                })
                setSupervisores(resultado.data.results);
                setSupervisor(resultado.data.results[0].id);
            } catch (e) {
                console.log(e)
            }
        }
        consultaUsuarios()
    }, [])

    useEffect(() => {
        setHorasTrabajo(ordenSelected.horastrabajo)
    }, [])

    const handleAddTecnico = async () => {
        const formData = new FormData()
        formData.append('tecnico', tecnico);
        formData.append('idot', ordenSelected.id);
        try {
            const response = await axios({
                url: Url + 'api/addTecnicoOt.php',
                method: 'POST',
                data: formData,
            })
            setIdOrden(response.data.results.idot);
        } catch (e) {
            console.log(e)
        }
    }

    const handleAddSupervisor = async () => {
        const formData = new FormData()
        formData.append('supervisor', supervisor);
        formData.append('idot', ordenSelected.id);
        try {
            const response = await axios({
                url: Url + 'api/addSuperOt.php',
                method: 'POST',
                data: formData,
            })
            setIdOrden(response.data.results.idot);
        } catch (e) {
            console.log(e)
        }
    }

    const handleSetEstado = async (newState) => {
        const formData = new FormData()
        formData.append('idot', ordenSelected.id);
        formData.append('estado', newState);
        formData.append('idusuario', idUsuario)
        formData.append('observaciones', formik.values.observaciones)
        try {
            const response = await axios({
                url: Url + 'api/setOtState.php',
                method: 'POST',
                data: formData,
            })
            setVistaOrdenes(0)
            setOpenVista(false);
            setMessageOut(response.data.results.notificacion)
            //setIdOrden(response.data.results.idot);
        } catch (e) {
            console.log(e)
        }
    }
    const formik = useFormik({
        initialValues: {
            fechaProg: ordenSelected.fechaprog,
            horaProg: ordenSelected.horaprog,
            detalleTrabajo: ordenSelected.detalletrabajo,
            observaciones: ordenSelected.observaciones
        },
        validationSchema: Yup.object({
            fechaProg: Yup.date()
                .required("La fecha de inicio es necesaria"),
            horaProg: Yup.string()
                .required("La hora de inicio es necesaria"),
            detalleTrabajo: Yup.string().typeError("Debe ingresar una descripción del trabajo")
                .required("Debe ingresar una descripción del trabajo")
        }),
        onSubmit: async (valores, { resetForm }) => {
            const formData = new FormData()
            formData.append('id', ordenSelected.id);
            formData.append('fechaprog', valores.fechaProg);
            formData.append('horaprog', valores.horaProg);
            formData.append('tipo', tipo);
            formData.append('prioridad', prioridad);
            formData.append('tecnico', tecnico);
            formData.append('supervisor', supervisor);
            formData.append('descripciontarea', valores.detalleTrabajo);
            formData.append('observaciones', valores.observaciones);
            formData.append('horastrabajo', horasTrabajo)
            try {
                const response = await axios({
                    url: Url + 'api/ordenesSet.php',
                    method: 'POST',
                    data: formData,
                })
                console.log(response)
                setIdOrden(response.data.results.idot);
                setMessageOut(response.data.results.notificacion)
                setVistaOrdenes(0)
                setOpenVista(false);
            } catch (e) {
                console.log(e)
            }
        }
    });

    return (
        <>
            {vistaRecursos === 0 ? (
                <form
                    className={`${(tipoModal !== 32 && openVista === false) && " rounded-2xl shadow-md "} ," w-full mx-auto p-10 bg-white "`}
                    onSubmit={formik.handleSubmit}
                >
                    <h1 className='text-center text-gray-500 text-3xl mb-2'>
                        Orden de Trabajo Nº {ordenSelected.numero}
                    </h1>

                    <h2 className='text-gray-700 text-xl text-center uppercase mb-5'>
                        Fecha de programación {ordenSelected.fechacreacion}
                    </h2>
                    <p className='text-xl text-gray-700'>Equipo: {ordenSelected.equipoact ? (ordenSelected.equipoact) : (ordenSelected.equiposol)}</p>
                    <div className='w-full mx-auto'>
                        <div className='flex'>
                            <div className='w-1/2 p-3 '>
                                <div className='w-full sm:items-start'>
                                    <label htmlFor="fechaProg" className="block text-gray-600 uppercase font-bold">Fecha de Programación</label>
                                    {(tipoUsrLogin !== '2' && ordenSelected.idestado === '1') ? (
                                        <>
                                            <input
                                                className="py-2 px-3 w-full rounded bg-slate-100"
                                                id="fechaProg"
                                                placeholder="Ingrese una fecha de inicio"
                                                type="date"
                                                value={formik.values.fechaProg}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                autoFocus
                                            />
                                            {formik.touched.fechaProg && formik.errors.fechaProg ? (
                                                <div className='w-full block mb-2 text-red-500'>
                                                    <p>{formik.errors.fechaProg}</p>
                                                </div>
                                            ) : null}
                                        </>
                                    ) : (
                                        <p>{ordenSelected.fechaprog}</p>
                                    )}
                                </div>
                                <div className='w-full'>
                                    <label htmlFor="horaProg" className="block mt-5 text-gray-600 uppercase font-bold">Hora de Programación</label>
                                    {(tipoUsrLogin !== '2') ? (
                                        <>
                                            <input
                                                className="py-2 px-3 w-full rounded bg-slate-100"
                                                id="horaProg"
                                                placeholder="Ingrese una hora de inicio"
                                                type="time"
                                                value={formik.values.horaProg}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.horaProg && formik.errors.horaProg ? (
                                                <div className='w-full block mb-2 text-red-500'>
                                                    <p>{formik.errors.horaProg}</p>
                                                </div>
                                            ) : null}
                                        </>) : (
                                        <p>{ordenSelected.horaprog}</p>
                                    )}
                                </div>
                                <div className='w-full'>
                                    <label htmlFor="horasTrabajo" className="block mt-5 text-gray-600 uppercase font-bold">Cantidad de horas de trabajo</label>
                                    {(tipoUsrLogin !== '2') ? (
                                        <>
                                            <input
                                                className="py-2 px-3 w-full rounded bg-slate-100"
                                                id="horasTrabajo"
                                                placeholder="Cantidad de hs."
                                                type="number"
                                                value={horasTrabajo}
                                                onChange={(e) => setHorasTrabajo(e.target.value)}
                                            />
                                        </>) : (
                                        <p>{ordenSelected.horastrabajo}</p>
                                    )}
                                </div>
                            </div>
                            <div className='w-1/2 p-3'>
                                <div className="w-full sm:items-start">
                                    <label htmlFor="sEstados" className="block text-gray-600 uppercase font-bold">Estado</label>
                                    {
                                        ordenSelected.idestado === '1' ? (
                                            <p className='p-3 bg-orange-300 text-center rounded-lg'>Abierta</p>
                                        ) : ordenSelected.idestado === '2' ? (
                                            <p className='p-3 bg-yellow-300 text-center rounded-lg'>Iniciada</p>
                                        ) : ordenSelected.idestado === '3' ? (
                                            <p className='p-3 bg-red-300 text-center rounded-lg'>Solicitud de aprobación de Cierre</p>
                                        ) : ordenSelected.idestado === '6' ? (
                                            <p className='p-3 bg-cyan-300 text-center rounded-lg'>Suspendida</p>
                                        ) : ordenSelected.idestado === '4' && (
                                            <p>Cerrada</p>
                                        )
                                    }
                                </div>
                                {(tipoUsrLogin !== '2') && (
                                    <>
                                        <div className="w-full sm:items-start">
                                            <label htmlFor="sTipos" className="block mt-5 text-gray-600 uppercase font-bold">Tipo</label>
                                            <select
                                                className='py-2 px-3 w-full rounded bg-slate-100'
                                                id='sTipos'
                                                onChange={(e) => setTipo(e.target.value)}
                                                value={tipo}
                                            >
                                                {typeof tipos !== "undefined" ? (
                                                    tipos.map(item => (
                                                        <option key={item.id} value={item.id}>{item.nombre}</option>))
                                                ) : null}
                                            </select>
                                        </div>
                                        <div className="w-full sm:items-start">
                                            <label htmlFor="sPrioridades" className="block mt-5 text-gray-600 uppercase font-bold">Prioridad</label>
                                            <select
                                                className='py-2 px-3 w-full rounded bg-slate-100'
                                                id='sPrioridades'
                                                onChange={(e) => setPrioridad(e.target.value)}
                                                value={prioridad}
                                            >
                                                {typeof prioridades !== "undefined" ? (
                                                    prioridades.map(item => (
                                                        <option key={item.id} value={item.id}>{item.nombre}</option>))
                                                ) : null}
                                            </select>
                                        </div>
                                    </>)}
                            </div>
                        </div>
                        <hr />
                        <div className="flex mt-5 mb-5">
                            {(tipoUsrLogin !== '2') && (
                                <div className='w-full px-3'>
                                    <h3 className='text-center text-black uppercase text-xl'>Recursos</h3>
                                    <button
                                        className='p-3 w-1/4 bg-blue-600 shadow-md rounded-lg text-white uppercase'
                                        onClick={(() => setVistaRecursos(1))}
                                    >
                                        Ver o agregar Recursos
                                    </button>
                                </div>)
                            }
                        </div>
                        <hr />
                        {(tipoUsrLogin !== '2') && (
                            <div className=''>
                                <div className='w-full p-3 '>
                                    <h3 className='text-center text-xl uppercase'>Supervisores</h3>
                                    <div className="w-full sm:items-start">
                                        <ListadoOtSupers vista="1" />
                                        <div className='mt-10 flex'>
                                            <select
                                                className='py-2 px-3 w-full rounded bg-slate-100'
                                                id='sSupervisores'
                                                onChange={(e) => setSupervisor(e.target.value)}
                                                value={supervisor}
                                            >
                                                {typeof supervisores !== "undefined" ? (
                                                    supervisores.map(item => (
                                                        <option key={item.id} value={item.id}>{item.apellido}</option>))
                                                ) : null}
                                            </select>
                                            <button
                                                type="button"
                                                onClick={() => handleAddSupervisor()}
                                                className='w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-blue-600 text-white hover:bg-blue-400 transition-all uppercase font-semibold rounded-lg'
                                            >Agregar Supervisor
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <hr />
                                <div className='w-full p-3 '>
                                    <h3 className='text-center text-xl uppercase'>Técnicos</h3>
                                    <div className="w-full sm:items-start">
                                        <ListadoOtTecnicos vista="1" />
                                        <div className='mt-10 flex'>
                                            <select
                                                className='py-2 px-3 w-full rounded bg-slate-100'
                                                id='sTecnicos'
                                                onChange={(e) => setTecnico(e.target.value)}
                                            >
                                                {typeof tecnicos !== "undefined" ? (
                                                    tecnicos.map(item => (
                                                        <option key={item.id} value={item.id}>{`${item.apellido}, ${item.nombre} - (${item.especialidad !== null ? item.especialidad : 'Sin asignar'})`}</option>))
                                                ) : null}
                                            </select>
                                            <button
                                                type="button"
                                                onClick={() => handleAddTecnico()}
                                                className='w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-blue-600 text-white hover:bg-blue-400 transition-all uppercase font-semibold rounded-lg'
                                            >Agregar Técnico
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                        }
                        <hr />
                        <div className='flex'>
                            <div className='w-full p-3 '>
                                <div className="w-full sm:items-start">
                                    <label htmlFor="detalleTrabajo" className="block text-gray-600 uppercase font-bold">Descripción o detalle de trabajo</label>
                                    {(tipoUsrLogin !== '2') ? (
                                        <>
                                            <textarea
                                                id="detalleTrabajo"
                                                placeholder='Agregue una descripción del trabajo'
                                                className='w-full rounded p-2 bg-slate-100'
                                                value={formik.values.detalleTrabajo}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            ></textarea>
                                            {formik.touched.detalleTrabajo && formik.errors.detalleTrabajo ? (
                                                <div className='w-full block mb-2 text-red-500'>
                                                    <p>{formik.errors.detalleTrabajo}</p>
                                                </div>
                                            ) : null}
                                        </>
                                    ) : (
                                        <p>{ordenSelected.detalletrabajo}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='flex'>
                            <div className='w-full p-3 '>
                                <div className="w-full sm:items-start">
                                    <label htmlFor="observaciones" className="block text-gray-600 uppercase font-bold">Observaciones</label>
                                    <textarea
                                        id="observaciones"
                                        placeholder='Puede agregar observaciones'
                                        className='w-full rounded p-2 bg-slate-100'
                                        value={formik.values.observaciones !== 'null' ? formik.values.observaciones : ''}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="pb-1 mt-5">
                        <div className="w-full rounded-lg px-3 flex justify-center">
                            {(tipoUsrLogin === '2' || tipoUsrLogin === '3' || tipoUsrLogin === '4') && (
                                ordenSelected.idestado === '1' ? (
                                    <button
                                        type='button'
                                        className="w-1/3 sm:w-1/4 shadow-md p-3 mr-3 bg-green-600 text-gray-100 hover:bg-indigo-400 transition-all uppercase font-semibold rounded-lg"
                                        onClick={() => handleSetEstado(2)}
                                    >Iniciar Orden</button>
                                ) : ordenSelected.idestado === '2' && (
                                    <button
                                        type='button'
                                        className="w-1/3 sm:w-1/4 shadow-md p-3 mr-3 bg-orange-600 text-gray-100 hover:bg-orange-400 transition-all uppercase font-semibold rounded-lg"
                                        onClick={() => handleSetEstado(3)}
                                    >Finalizar</button>
                                )
                            )}
                            {(tipoUsrLogin === '4' || tipoUsrLogin === '3') && (
                                ordenSelected.idestado === '3' ? (
                                    <button
                                        type='button'
                                        className="w-1/3 sm:w-1/4 shadow-md p-3 mr-3 bg-orange-600 text-gray-100 hover:bg-orange-400 transition-all uppercase font-semibold rounded-lg"
                                        onClick={() => handleSetEstado(4)}
                                    >Aprobar Cierre</button>
                                ) :
                                    (ordenSelected.idestado === '1' || ordenSelected.idestado === '2') ? (
                                        <button
                                            type='button'
                                            className="w-1/3 sm:w-1/4 shadow-md p-3 mr-3 bg-cyan-600 text-gray-100 hover:bg-cyan-400 transition-all uppercase font-semibold rounded-lg"
                                            onClick={() => handleSetEstado(6)}
                                        >Suspender O.T.</button>
                                    ) : ordenSelected.idestado === '6' && (
                                        <button
                                            type='button'
                                            className="w-1/3 sm:w-1/4 shadow-md p-3 mr-3 bg-green-600 text-gray-100 hover:bg-green-400 transition-all uppercase font-semibold rounded-lg"
                                            onClick={() => handleSetEstado(1)}
                                        >Reanudar O.T.</button>
                                    )
                            )}

                            {(tipoUsrLogin !== '2') && (
                                <input
                                    type="submit"
                                    className="w-1/3 sm:w-1/4 shadow-md p-3 bg-indigo-600 text-gray-100 hover:bg-indigo-400 transition-all uppercase font-semibold rounded-lg"
                                    value="Guardar Datos"
                                />
                            )}
                            {tipoModal === 32 ? (
                                <button
                                    type="button"
                                    className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-semibold rounded-lg"
                                    onClick={() => setOpenVista(false)}
                                >
                                    Salir
                                </button>
                            ) : (
                                <button
                                    type="button"
                                    className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-semibold rounded-lg"
                                    onClick={() => setVistaOrdenes(0)}
                                >
                                    Salir
                                </button>

                            )}
                        </div>
                    </div>
                </form>
            ) : (
                <div className='w-full p-10 bg-slate-100 shadow-md rounded-xl'>
                    {ordenSelected.idactividad ? (
                        <RecursosOT showBtn={0} />
                    ) : (
                        <RecursosSol showBtn={0} />
                    )}
                    <div className='w-1/3 sm:w-1/4 mx-auto mt-4'>
                        <button
                            type="button"
                            className="w-full shadow-md p-3 mt-5 bg-orange-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                            onClick={() => setVistaRecursos(0)}
                        >
                            Volver
                        </button>

                    </div>
                </div>
            )}
        </>
    )
}

export default FEditOrden