import { useState, useContext, useEffect } from 'react'
import { useSortableData } from "./useSortableData";
import ContextEstados from '../../context/Estados';
import { FaEye, FaPen, FaTrash } from 'react-icons/fa'
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from "react-icons/io";

const TablaRepuestos = props => {
    const { items, requestSort, sortConfig } = useSortableData(props.repuestos);
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(50)
    const { setRepuestoSelected, repuestos, setTipoModal, setOpenVista } = useContext(ContextEstados);


    const indexOfLastItem = currentPage * itemsPerPage;
    const cantPages = Math.ceil(props.repuestos.length/itemsPerPage);

    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = items.slice(indexOfFirstItem, indexOfLastItem)

    useEffect(() => {
        if (currentPage > cantPages && cantPages !== 0)
            setCurrentPage(cantPages)
    }, [cantPages])

    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    /*const asignarRepuesto = (id) => {
        repuestos.forEach(element => {
            if (element.id === id) {
                setRepuestoSelected(element)
            }
        });
    }*/

    const verRepuesto = async (element) => {
        setRepuestoSelected(element)
        //asignarRepuesto(id);
        setTipoModal(7)
        setOpenVista(true);
    }
    const editarRepuesto = async (element) => {
        setRepuestoSelected(element)
        //asignarRepuesto(id);
        setTipoModal(8)
        setOpenVista(true);
    }
    const eliminarRepuesto = async (element) => {
        setRepuestoSelected(element)
        //asignarRepuesto(id);
        setTipoModal(9)
        setOpenVista(true);
    }

    const handleLeft = () => {
        let aux = currentPage;
        aux--;
        setCurrentPage(aux)
    }

    const handleRight = () => {
        let aux = currentPage;
        aux++;
        setCurrentPage(aux)
    }

    return (
        <div>
            <div className='w-1/6 md:w-1/12 ml-auto flex'>
                <div className="w-1/3">
                    {
                        currentPage > 0 && (
                            <button
                                type='button'
                                className={`text-white rounded-l-2xl p-2 w-full text-2xl ${currentPage > 1 ? 'bg-slate-400' : 'bg-slate-300'}`}
                                onClick={() => handleLeft()}
                                disabled={currentPage === 1 && true}
                            ><IoIosArrowDropleftCircle /></button>
                        )
                    }
                </div>
                <div className="w-1/3">
                    <input
                        type='text'
                        className='bg-white p-2 w-full text-center'
                        value={currentPage}
                        onChange={(e) => setCurrentPage(e.target.value)}
                    />
                </div>
                <div className="w-1/3">
                    {
                        currentPage <= cantPages && (
                            <button
                                type='button'
                                className={`text-white rounded-r-2xl p-2 w-full text-2xl ${currentPage < cantPages ? 'bg-slate-400' : 'bg-slate-300'}`}
                                onClick={() => handleRight()}
                                disabled={currentPage === cantPages && true}
                            ><IoIosArrowDroprightCircle /></button>
                        )
                    }
                </div>
            </div>
            <table className='table-auto w-full border-collapse border-spacing-5 border-slate-400 text-gray-700 rounded-xl mx-auto mt-1' >
                <thead>
                    <tr className='uppercase bg-blue-400 text-white'>
                        <th className='border-gray-400 p-2'>
                            <button
                                type="button"
                                onClick={() => requestSort('codigo')}
                                className={getClassNamesFor('codigo')}
                            >Código</button></th>
                        <th className='border-gray-400 p-2'>
                            <button
                                type="button"
                                onClick={() => requestSort('descripcion')}
                                className={getClassNamesFor('descripcion')}
                            >Descripción</button></th>
                        <th className='border-gray-400 p-2'>
                            <button
                                type="button"
                                onClick={() => requestSort('costo', 'number')}
                                className={getClassNamesFor('costo')}
                            >Costo</button></th>
                        <th className='border-gray-400 p-2'>
                            <button
                                type="button"
                                onClick={() => requestSort('nombremarca')}
                                className={getClassNamesFor('nombremarca')}
                            >Marca</button></th>
                        <th className='border-gray-400 p-2'>
                            <button
                                type="button"
                                onClick={() => requestSort('nombreunidad')}
                                className={getClassNamesFor('nombreunidad')}
                            >Unidad</button></th>
                        <th className='border-gray-400 p-2'>Acciones</th>
                    </tr>
                </thead>
                <tbody >
                    {
                        typeof items !== 'undefined' ? (
                            currentItems.map((item, index) => (

                                <tr
                                    key={item.id}
                                    className={`hover:bg-slate-200 border-b border-gray-200 ${index % 2 === 0 ? 'bg-white' : 'bg-slate-100'}`}
                                >
                                    <td className='border-gray-400 p-1 text-center'>{item.codigo}</td>
                                    <td className='border-gray-400 p-1 text-center'>{item.descripcion}</td>
                                    <td className='border-gray-400 p-1 text-center'>{item.costo}</td>
                                    <td className='border-gray-400 p-1 text-center'>{item.nombremarca}</td>
                                    <td className='border-gray-400 p-1 text-center'>{item.nombreunidad}</td>
                                    <td className='p-1 flex justify-center'>
                                        <button
                                            className='bg-indigo-500 p-3 rounded-md text-white'
                                            onClick={() => verRepuesto(item)}
                                        >
                                            <FaEye />
                                        </button>
                                        <button
                                            className='bg-gray-400 p-3 rounded-md text-white ml-3'
                                            onClick={() => editarRepuesto(item)}
                                        >
                                            <FaPen />
                                        </button>
                                        <button
                                            className='bg-red-400 p-3 rounded-md text-white ml-3'
                                            onClick={() => eliminarRepuesto(item)}
                                        >
                                            <FaTrash />
                                        </button>
                                    </td>
                                </tr>

                            ))
                        ) : (null)
                    }
                </tbody>
            </table>
        </div>
    )
}

export default TablaRepuestos