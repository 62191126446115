import React, { useContext } from 'react'
import ContextEstados from '../context/Estados';
import axios from 'axios';
import { Url } from './Url'

const FDeleteEqAct = () => {
    const { setOpenVista, eqActSelected,setIdEqAct } = useContext(ContextEstados);
    const eliminarEqAct = async () => {
        const formData=new FormData()
        formData.append('id',eqActSelected.id);
        try{
            const response = await axios({
                url: Url+'api/eqsActsDelete.php',
                method: 'POST',
                data: formData,
            })
            setIdEqAct(response.data.results.ideqact);
            setOpenVista(false)
        } catch (e) {
            console.log(e)
        }
    }
    return (
        <div className='mt-10 mb-10 px-10'>
            <p className='text-xl mt-4 text-gray-600'>Equipo: {eqActSelected.equipo}</p>
            <p className='text-xl mt-4 text-gray-600'>Actividad: {eqActSelected.actividad}</p>
            <p className='text-xl mt-4 text-gray-600'>Valorperiodo: {eqActSelected.valorperiodo}</p>
            <p className='text-xl mt-4 text-gray-600'>Fecha de inicio: {eqActSelected.fechainicio}</p>
            <p className='text-xl mt-4 text-gray-600'>Unidad: {eqActSelected.unidad}</p>
            <div className="w-full rounded-lg mt-10 px-3 flex justify-center">
                <button
                    type="button"
                    className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-red-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => eliminarEqAct()}
                >
                    Eliminar
                </button>
                <button
                    type="button"
                    className="w-1/4 sm:w-1/6 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => setOpenVista(false)}
                >
                    Salir
                </button>
            </div>
        </div>
    )
}

export default FDeleteEqAct