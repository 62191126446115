import { Fragment, useRef, useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'


import FViewUbicacion from './FViewUbicacion'
import FEditUbicacion from './FEditUbicacion'
import FDeleteUbicacion from './FDeleteUbicacion'
import FViewEquipo from './FViewEquipo'
import FEditEquipo from './FEditEquipo'
import FDeleteEquipo from './FDeleteEquipo'
import FViewUsuario from './FViewUsuario'
import FEditUsuario from './FEditUsuario'
import FDeleteUsuario from './FDeleteUsuario'
import FViewRepuesto from './FViewRepuesto'
import FEditRepuesto from './FEditRepuesto'
import FDeleteRepuesto from './FDeleteRepuesto'
import FViewVariable from './FViewVariable'
import FEditVariable from './FEditVariable'
import FAddValueVariable from './FAddValueVariable'
import FDeleteVariable from './FDeleteVariable'
import FViewActividad from './FViewActividad'
import FEditActividad from './FEditActividad'
import FViewPlan from './FViewPlan'
import FEditPlan from './FEditPlan'
import FDeletePlan from './FDeletePlan'
import FDeleteActividad from './FDeleteActividad'
import FDeleteTarea from './FDeleteTarea'
import FDeleteTareaSol from './FDeleteTareaSol'
import FDeleteActRepuesto from './FDeleteActRepuesto'
import FDeleteSolRepuesto from './FDeleteSolRepuesto'
import FDeleteOtrepuesto from './FDeleteOtRepuesto'
import FViewEqAct from './FViewEqAct'
import FEditEqAct from './FEditEqAct'
import FDeleteEqAct from './FDeleteEqAct'
import FormOrden from './FormOrden'
import FViewOrden from './FViewOrden'
import FDeleteOrden from './FDeleteOrden'
import FEditOrden from './FEditOrden'
import FViewSolicitud from './FViewSolicitud'
import FEditSolicitud from './FEditSolicitud'
import FDeleteSolicitud from './FDeleteSolicitud'
import FormChSucursal from './FormChSucursal'
import FDeleteTecnico from './FDeleteTecnico'
import FEditTipo from './FEditTipo'
import FDeleteTipo from './FDeleteTipo'
import FDeleteSupervisor from './FDeleteSupervisor'
import ContextEstados from '../context/Estados'
import FViewPermiso from './FViewPermiso'
import FEditRecurso from './FEditRecurso'
import FDeleteRecurso from './FDeleteRecurso'
import FDeleteOtRecurso from './FDeleteOtRecurso'

const ModalVista = () => {
  const { tipoModal,openVista, setOpenVista } = useContext(ContextEstados);


  const cancelButtonRef = useRef(null)

  return (
    <>
    <Transition.Root show={openVista} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpenVista}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 md:w-1/2 w-1/2">
                <div className="px-4 pt-2 sm:p-6">
                    <h3 className='text-2xl text-gray-600 text-center uppercase'>{
                      tipoModal>=1 && tipoModal<=3 ? ('Equipo / Activo') : 
                      tipoModal>=4 && tipoModal<=6 ? ('Usuario') : 
                      tipoModal>=6 && tipoModal<=9 ? ('Repuesto') : 
                      tipoModal>=10 && tipoModal<=13 ? ('Variable de Control') :
                      tipoModal>=14 && tipoModal<=17 ? ('Actividades') :
                      tipoModal>=18 && tipoModal<=20 ? ('Plan de Mantenimiento') : 
                      tipoModal>=25 && tipoModal<=27 ? ('Equipos / Activos - Actividadaes') : ('') }</h3>
                    { tipoModal===1 ? (
                        <FViewEquipo/>
                    ) : tipoModal===2 ? (
                        <FEditEquipo/>
                    ) : tipoModal===3 ? (
                        <FDeleteEquipo/>
                    ) : tipoModal===4 ? (
                        <FViewUsuario/>
                    ) : tipoModal===5 ? (
                        <FEditUsuario/>
                    ) : tipoModal===6 ? (
                        <FDeleteUsuario/>
                    ) : tipoModal===7 ? (
                        <FViewRepuesto/>
                    ) : tipoModal===8 ? (
                        <FEditRepuesto/>
                    ) : tipoModal===9 ? (
                        <FDeleteRepuesto/>                    
                    ) : tipoModal===10 ? (
                        <FViewVariable/>
                    ) : tipoModal===11 ? (
                        <FEditVariable/>
                    ) : tipoModal===12 ? (
                        <FDeleteVariable/>
                    ) : tipoModal===13 ? (
                        <FAddValueVariable/>
                    ) : tipoModal===14 ? (
                        <FViewVariable/>
                    ) : tipoModal===15 ? (
                        <FViewActividad/>
                    ) : tipoModal===16 ? (
                        <FEditActividad/>
                    ) : tipoModal===17 ? (
                        <FDeleteActividad/>
                    ) : tipoModal===18 ? (
                        <FViewPlan/>
                    ) : tipoModal===19 ? (
                        <FEditPlan/>
                    ) : tipoModal===20 ? (
                        <FDeletePlan/>
                    ) : tipoModal===21 ? (
                        <FDeleteTarea/>
                    ) : tipoModal===22 ? (
                        <FDeleteTareaSol/>
                    ) : tipoModal===24 ? (
                        <FDeleteActRepuesto/>
                    ) : tipoModal===28 ? (
                        <FDeleteSolRepuesto/>
                    ) : tipoModal===29 ? (
                        <FDeleteOtrepuesto/>
                    ) : tipoModal===25 ? (
                        <FViewEqAct/>
                    ) : tipoModal===26 ? (
                        <FEditEqAct/>
                    ) : tipoModal===27 ? (
                        <FDeleteEqAct/>
                    ) : tipoModal===30 ? (
                        <FViewOrden/>
                    ) : tipoModal===31 ? (
                        <FDeleteOrden/>
                    ) : tipoModal===32 ? (
                        <FEditOrden/>
                    ) : tipoModal===33 ? (
                        <FormOrden/>
                    ) : tipoModal===35 ? (
                        <FViewSolicitud/>
                    ) : tipoModal===36 ? (
                        <FEditSolicitud/>
                    ) : tipoModal===37 ? (
                        <FDeleteSolicitud/>
                    ) : tipoModal===40 ? (
                        <FViewUbicacion/>
                    ) : tipoModal===41 ? (
                        <FEditUbicacion/>
                    ) : tipoModal===42 ? (
                        <FDeleteUbicacion/>
                    ) : tipoModal===50 ? (
                        <FormChSucursal/>
                    ) : tipoModal===51 ? (
                        <FDeleteTecnico/>
                    ) : tipoModal===60 ? (
                        <FEditTipo/>
                    ) : tipoModal===61 ? (
                        <FDeleteTipo/>
                    ) : tipoModal===71 ? (
                        <FDeleteSupervisor/>
                    ) : tipoModal===80 ? (
                        <FViewPermiso/>
                    ) : tipoModal===90 ? (
                        <FEditRecurso/>
                    ) : tipoModal===91 ? (
                        <FDeleteRecurso/>
                    ) : tipoModal===92 ? (
                        <FDeleteOtRecurso/>
                    ) : null}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    
    </>
  )
}

export default ModalVista