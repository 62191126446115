import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import ContextEstados from '../context/Estados';
import { FaEye, FaPen, FaTrash } from 'react-icons/fa'
import { Url } from './Url';

const ListadoTipos = () => {
    const [tipos, setTipos] = useState([]);
    const { idTipo, tipoSelected, setTipoSelected, setOpenVista, setTipoModal } = useContext(ContextEstados);

    useEffect(() => {
        const consultaTipos = async () => {
            const url = `${Url}api/tEqList.php`;
            const resultado = await axios.get(url);
            setTipos(resultado.data.results);
        }
        consultaTipos()
    }, [idTipo])

    const asignarTipo = (id) => {
        tipos.forEach(element => {
            if (element.id === id) {
                setTipoSelected(element)
            }
        });
    }
    const editarTipo = (id) => {
        asignarTipo(id);
        setTipoModal(60);
        setOpenVista(true);
    }
    const eliminarTipo = (id) => {
        asignarTipo(id);
        setTipoModal(61);
        setOpenVista(true);
    }

    return (
        <div className='mx-8'>
            <table className='table-auto w-full border-collapse border-spacing-5 border-slate-400 text-gray-700 rounded-xl mx-auto' >
                <thead>
                    <tr className='uppercase bg-gray-300'>
                        <th className='border-gray-400 p-2 text-center'>Nombre</th>
                        <th className='border-gray-400 p-2 text-center'>Descripción</th>
                        <th className='border-gray-400 p-2 text-center'>Acciones</th>
                    </tr>
                </thead>
                <tbody >
                    {
                        typeof tipos !== 'undefined' && (tipos.map((item, index) => (
                            <tr
                                key={index}
                                className={`hover:cursor-pointer border-b border-gray-200  ${index % 2 === 0 ? 'bg-white hover:bg-slate-200' : 'bg-slate-100 hover:bg-slate-200'}`}
                            >
                                <td className='border-gray-400 p-2 text-center'>{item.nombre}</td>
                                <td className='border-gray-400 p-2 text-center'>{item.descripcion}</td>
                                <td className='p-2 flex justify-center'>
                                    <button
                                        className='bg-gray-400 p-3 rounded-md text-white ml-3'
                                        onClick={() => editarTipo(item.id)}
                                    >
                                        <FaPen />
                                    </button>
                                    <button
                                        className='bg-red-400 p-3 rounded-md text-white ml-3'
                                        onClick={() => eliminarTipo(item.id)}
                                    >
                                        <FaTrash />
                                    </button>
                                </td>
                            </tr>
                        )))
                    }
                </tbody>
            </table>
        </div>
    )
}

export default ListadoTipos