import {
    LIMPIAR_ALERTA,
    LOGIN_EXITOSO,
    LOGIN_ERROR,
    USUARIO_AUTENTICADO,
    CERRAR_SESION,
 } from "../../types";


export default (state, action) => {
    switch(action.type) {

        case LOGIN_ERROR:
            return {
                ...state,
                mensaje: action.payload
            }
        case LOGIN_EXITOSO:
            localStorage.setItem('sg_tk',action.payload.token)
            localStorage.setItem('sgpp_m',action.payload.mail)
            localStorage.setItem('sgpp_id',action.payload.id)
            localStorage.setItem('sgpp_n',action.payload.nombreusuario)
            localStorage.setItem('sg_t',action.payload.tipo)
            return {
                ...state,
                token: action.payload,
                autenticado: true,
                procesando: false
            }
        
        case LIMPIAR_ALERTA:
            return {
                ...state,
                mensaje: null
            }
        case USUARIO_AUTENTICADO:
            return {
                ...state,
                usuario:action.payload
            }

        case CERRAR_SESION:
            localStorage.removeItem('sg_tk');
            localStorage.removeItem('sgpp_m');
            localStorage.removeItem('sgpp_n');
            localStorage.removeItem('sgpp_id');
            localStorage.removeItem('sg_t');
            return {
                ...state,
                usuario: null,
                token: null,
                autenticado: null,
                procesando: false,
                nivel: null
            }
        default: 
            return state;
    }
}