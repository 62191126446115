import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getMessaging } from "firebase/messaging"
 
const firebaseConfig = {
    apiKey: "AIzaSyBTsxNBLBP0zR2S0c6fPwswZHQIaj6lEZM",
    authDomain: "appgind-3a188.firebaseapp.com",
    projectId: "appgind-3a188",
    storageBucket: "appgind-3a188.appspot.com",
    messagingSenderId: "987741244285",
    appId: "1:987741244285:web:bc69f305cb54cdb762da14"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const messaging = getMessaging(app);