import React, { useState, useEffect, useRef, useContext } from 'react'
import { useFormik } from "formik"
import axios from 'axios'
import * as Yup from "yup"
import ContextEstados from '../context/Estados'
import SubirImagenes from './SubirImagenes'
import Select from 'react-select';
import { Url } from './Url'

const FormSolicitud = () => {
    const [equipos, setEquipos] = useState([])
    const [sectores, setSectores] = useState([])
    const [sector, setSector] = useState(0)
    const [prioridades, setPrioridades] = useState([])
    const [prioridad, setPrioridad] = useState(0)
    const [tiposFallas, setTiposFallas] = useState([])
    const [tipoFalla, setTipoFalla] = useState(0)
    const [tipoSol, setTipoSol] = useState(0)
    const [equipo, setEquipo] = useState(0)
    const { idSolicitud, setMessageOut, setIdSolicitud, setOpenAlta, idUsuario } = useContext(ContextEstados);

    let tipoImagen = "solicitudes";
    let idHtml = "imagenesSolicitud";
    const childRef = useRef()

    useEffect(() => {
        const consultaEquipos = async () => {
            const formData = new FormData()
            formData.append('idusuario', idUsuario)
            formData.append('tipoList', 1)
            try {
                const response = await axios({
                    url: Url + 'api/equiposListado.php',
                    method: 'POST',
                    data: formData,
                })
                setEquipos(response.data.results);
                setEquipo(response.data.results[0].id);
            } catch (e) {
                console.log(e)
            }
        }
        consultaEquipos()
    }, [])

    useEffect(() => {
        const consultaSectores = async () => {
            const formData = new FormData()
            
            try {
                const response = await axios({
                    url: Url + 'api/sectoresList.php',
                    method: 'POST',
                    data: formData,
                })
                setSectores(response.data.results);
            } catch (e) {
                console.log(e)
            }
        }
        consultaSectores()
    }, [])

    useEffect(() => {
        const consultaPrioridades = async () => {
            const url = Url + '/api/lPrioridades.php';
            const resultado = await axios.get(url);
            setPrioridades(resultado.data.results);
            setPrioridad(resultado.data.results[0].id)
        }
        consultaPrioridades()
    }, [])

    useEffect(() => {
        const consultaTipoFalla = async () => {
            const url = Url + '/api/tipoFallaList.php';
            const resultado = await axios.get(url);
            setTiposFallas(resultado.data.results);
            setTipoFalla(resultado.data.results[0].id)
        }
        consultaTipoFalla()
    }, [])

    useEffect(() => {
        if (idSolicitud !== 0) {
            childRef.current.handleSubmit();

        }
    }, [idSolicitud])

    const formik = useFormik({
        initialValues: {
            descripcion: '',
            solicitante: ''
        },
        validationSchema: Yup.object({
            descripcion: Yup.string()
                .required("Debe ingresar una descripción"),
            solicitante: Yup.string()
                .required("Debe ingresar un solicitante")
        }),
        onSubmit: async (valores, { resetForm }) => {
            const formData = new FormData()
            formData.append('descripcion', valores.descripcion);
            formData.append('tipofalla', tipoFalla);
            formData.append('tiposolicitud', tipoSol);
            formData.append('solicitante', valores.solicitante);
            formData.append('equipo', equipo);
            formData.append('prioridad', prioridad);
            formData.append('idusuario', idUsuario);
            formData.append('sector', sector);
            try {
                const response = await axios({
                    url: Url + 'api/solicitudesAlta.php',
                    method: 'POST',
                    data: formData,
                })
                setIdSolicitud(response.data.results.idsolicitud);
                setMessageOut(response.data.results.notificacion)
                resetForm({ values: '' });
                setOpenAlta(false);
            } catch (e) {
                console.log(e)
            }
        }
    });
    const handleSelect = (val) => {
        setEquipo(val.id)
    }

    return (
        <form
            className="w-full mx-auto p-5 rounded-2xl"
            onSubmit={formik.handleSubmit}
        >
            <div className="transition-all w-full sm:p-3">
                <h2 className='text-3xl uppercase mb-3 text-center'>Nueva Solicitud de Trabajo</h2>
                <div className="sm:items-start mt-5">
                    <label htmlFor="sEquipos" className="block text-gray-600 uppercase font-bold">Equipo</label>
                    <Select
                        options={equipos}
                        placeholder="Seleccione Equipo"
                        onChange={(value) => handleSelect(value)}
                        name='sEquipos'
                    />
                </div>
                <div className="sm:items-start mt-5">
                    <label htmlFor="descripcion" className="block text-gray-600 uppercase font-bold">Descripción</label>
                    <textarea
                        className="py-2 px-3 w-full rounded bg-slate-300"
                        id="descripcion"
                        placeholder="Descripción de Solicitud"
                        type="text"
                        value={formik.values.descripcion}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    ></textarea>
                    {formik.touched.descripcion && formik.errors.descripcion ? (
                        <div className='w-full block mb-2 text-red-500'>
                            <p>{formik.errors.descripcion}</p>
                        </div>
                    ) : null}
                </div>
                <div className="sm:items-start mt-5">
                    <label htmlFor="sFalla" className="block text-gray-600 uppercase font-bold">Tipo de Falla</label>
                    <select className='py-2 px-3 w-full rounded bg-slate-300' id='sFalla' onChange={(e) => setTipoFalla(e.target.value)}>
                        {typeof tiposFallas !== "undefined" ? (
                            tiposFallas.map(item => (
                                <option key={item.id} value={item.id}>{item.nombre}</option>))
                        ) : null}
                    </select>
                </div>
                <div className="sm:items-start mt-5">
                    <label htmlFor="solicitante" className="block text-gray-600 uppercase font-bold">Solicitante</label>
                    <input
                        className="py-2 px-3 w-full rounded bg-slate-300"
                        id="solicitante"
                        placeholder="Solicitante del Trabajo"
                        type="text"
                        value={formik.values.solicitante}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.solicitante && formik.errors.solicitante ? (
                        <div className='w-full block mb-2 text-red-500'>
                            <p>{formik.errors.solicitante}</p>
                        </div>
                    ) : null}
                </div>
                <div className="sm:items-start mt-5">
                    <label htmlFor="sPrioridades" className="block text-gray-600 uppercase font-bold">Prioridad</label>
                    <select className='py-2 px-3 w-full rounded bg-slate-300' id='sPrioridades' onChange={(e) => setPrioridad(e.target.value)}>
                        {typeof prioridades !== "undefined" ? (
                            prioridades.map(item => (
                                <option key={item.id} value={item.id}>{item.nombre}</option>))
                        ) : null}
                    </select>
                </div>
                <div className="sm:items-start mt-5">
                    <label htmlFor="sSector" className="block text-gray-600 uppercase font-bold">Sector</label>
                    <select className='py-2 px-3 w-full rounded bg-slate-300' id='sSector' onChange={(e) => setSector(e.target.value)}>
                        {typeof sectores !== "undefined" ? (
                            sectores.map(item => (
                                <option key={item.id} value={item.id}>{item.nombre}</option>))
                        ) : null}
                    </select>
                </div>

            </div>
            <div className="pb-10">
                <SubirImagenes
                    id={idSolicitud}
                    tipo={tipoImagen}
                    ref={childRef}
                    idHtml={idHtml}
                />
            </div>
            <div className="pb-1">
                <div className="w-full rounded-lg px-3 flex justify-center">
                    <input
                        type="submit"
                        className="w-1/3 sm:w-1/4 shadow-md p-3 bg-indigo-600 text-gray-100 hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg"
                        value="Guardar Datos"
                    />
                    <button
                        type="button"
                        className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                        onClick={() => setOpenAlta(false)}
                    >
                        Salir
                    </button>
                </div>
            </div>
        </form>
    )
}

export default FormSolicitud