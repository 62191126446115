import React, { useContext } from 'react'
import axios from 'axios'
import ContextEstados from '../context/Estados'

import { Url } from './Url'

const FDeleteVariable = () => {
   
    const { setIdVariable,variableSelected, setOpenVista } = useContext(ContextEstados);
    
    const eliminarVariable = async () => {
        const formData=new FormData()
        formData.append('id',variableSelected.id);
        try{
            const response = await axios({
                url: Url+'api/varsDelete.php',
                method: 'POST',
                data: formData,
            })
        setIdVariable(response.data.results.idvariable);
        setOpenVista(false)
        } catch (e) {
            console.log(e)
        }
    }
 
    return (
    <div className='mt-10 mb-10 px-10'>
        <h3 className='text-2xl text-red-600 uppercase'>Eliminar Variable</h3>
        <p className='text-xl text-gray-600'>Equipo: {variableSelected.equipo}</p>
        <p className='text-xl mt-4 text-gray-600'>Nombre: {variableSelected.nombre}</p>
        <p className='text-xl mt-4 text-gray-600'>Unidad: {variableSelected.unidad}</p>
        <p className='text-xl mt-4 text-gray-600'>Ultimo Valor: {variableSelected.ultimafecha}</p>
        <p className='text-xl mt-4 text-gray-600'>Acumula: {variableSelected.acumula}</p>
        <p className='text-xl mt-4 text-gray-600'>Ultima Fecha: {variableSelected.ultimafecha}</p>
        <p className='text-xl mt-4 text-gray-600'>Incremento: {variableSelected.incremento}</p>       
        <p className='text-2xl mt-10 text-center text-red-600'>¿Confirma que desea eliminar la Variable?</p>
        <div className="w-full rounded-lg mt-3 px-3 flex justify-center">
            <button
                type="button"
                className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-red-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                onClick={() => eliminarVariable()}
            >
                Eliminar
            </button>
            <button
                type="button"
                className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                onClick={() => setOpenVista(false)}
            >
                Salir
            </button>
        </div>
    </div>
    )
}

export default FDeleteVariable