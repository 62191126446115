import React, { useState,useContext, useEffect } from 'react'
import { useFormik } from "formik"
import axios from 'axios'
import * as Yup from "yup"
import ContextEstados from '../context/Estados'
import { Url } from './Url'
import Select from 'react-select';

const FormSolRepuestos = () => {
    const [ repuestos, setRepuestos ] = useState([]);
    const [ repuesto, setRepuesto ] = useState(0)
    const { setIdSolRepuesto,solicitudSelected,idUsuario } = useContext(ContextEstados);
    
    useEffect (() => {
        const consultarRepuestos = async() => {
            const formData=new FormData()
            formData.append('idusuario',idUsuario)
            try{
                const result = await axios({
                    url: Url+'api/repuestosListado.php',
                    method: 'POST',
                    data: formData,
                })
                setRepuestos(result.data.results);
                setRepuesto(result.data.results[0].id);
            } catch (e) {
                console.log(e)
            }
        }
        consultarRepuestos();
    },[])

    const handleSelect = (val) => {
        setRepuesto(val.id)
    }

    const formik = useFormik({
        initialValues:{
            cantidad: ''
        },
        validationSchema: Yup.object({
            cantidad: Yup.number()
                .required("La cantidad es necesaria")
        }),
        onSubmit: async (valores,{resetForm}) => {
            const formData=new FormData()
            formData.append('solicitud',solicitudSelected.id);
            formData.append('repuesto',repuesto);
            formData.append('cantidad',valores.cantidad);
            try{
                const response = await axios({
                    url: Url+'api/solRepuestosAlta.php',
                    method: 'POST',
                    data: formData,
                })
                setIdSolRepuesto(response.data.results.idsolrepuesto);
                resetForm({ values: ''});
                //setOpenAlta(false)
            } catch (e) {
                console.log(e)
            }
        }
        
    });
  return (
    <form
        className="w-full mx-auto p-5 rounded-2xl"
        onSubmit={formik.handleSubmit}
    >
        <div className="transition-all w-full sm:p-3">
            <h2 className='text-2xl text-gray-600 mb-3'>Agregar Repuestos</h2>
            <div className='flex'>
                <div className='w-10/12 flex'>
                    <div className="sm:items-start w-full sm:w-1/2">
                        <label htmlFor="sRepuesto" className="block mt-5 text-gray-600 uppercase font-bold">Repuesto</label>
                        <Select
                            options={repuestos}
                            placeholder="Seleccione Repuesto"
                            onChange={(value)=>handleSelect(value)}
                            name='sRepuesto'
                        />
                    </div>
                    <div className="sm:items-start w-full sm:w-1/2 px-5">
                        <label htmlFor="cantidad" className="block mt-5 text-gray-600 uppercase font-bold">Cantidad</label>
                        <input 
                            className="py-2 px-3 w-full rounded bg-slate-300" 
                            id="cantidad" 
                            placeholder="Ingrese la cantidad" 
                            type="number" 
                            value={formik.values.cantidad}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoFocus
                        />
                        { formik.touched.cantidad && formik.errors.cantidad ? (
                            <div className='w-full block mb-2 text-red-500'>
                                <p>{formik.errors.cantidad}</p>
                            </div>
                        ) : null }
                    </div>
                </div>
                <div className='w-2/12'>
                    <input
                        type="submit"
                        className="w-full mt-10 shadow-md p-3 bg-indigo-600 text-gray-100 hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg"
                        value="Agregar"
                    />
                </div>
            </div>
        </div>
    </form>               
  )
}

export default FormSolRepuestos