import React, { useState, useEffect, useContext } from 'react'
import ContextEstados from '../context/Estados';
import { FaTrash } from 'react-icons/fa'
import axios from 'axios';
import { Url } from './Url';

const ListadoOTRecursos = (props) => {
    const [recursos, setRecursos] = useState([]);
    const { idOrdenRecurso, ordenSelected, setTipoModal, setOpenVista, setOtRecursoSelected, sucursales } = useContext(ContextEstados);

    useEffect(() => {
        const consultaRecursos = async () => {
            const formData = new FormData()
            formData.append('orden', ordenSelected.id)
            try {
                const response = await axios({
                    url: Url + 'api/otRecursosListado.php',
                    method: 'POST',
                    data: formData,
                })
                //console.log(response)
                setRecursos(response.data.results);
            } catch (e) {
                console.log(e)
            }
        }
        consultaRecursos()
    }, [idOrdenRecurso, sucursales])

    const eliminarOtRecurso = (element) => {
        setOtRecursoSelected(element)
        setTipoModal(92);
        setOpenVista(true);
    }


    return (
        <div className={`${props.show !== 0 && 'mx-8'}`}>
            <table className='table-auto w-full border-collapse border-spacing-5 border-slate-400 text-gray-700 rounded-xl mx-auto' >
                <thead>
                    <tr className='uppercase bg-gray-300'>
                        <th className='border-gray-400 p-2 text-center'>Descripción</th>
                        {props.show !== 0 && (
                            <th className='border-gray-400 p-2 text-center print:hidden'>Acciones</th>
                        )}
                    </tr>
                </thead>
                <tbody >
                    {
                        typeof recursos !== 'undefined' && (
                            recursos.map((recurso, index) => (
                                <tr
                                    key={index}
                                    className='bg-white hover:bg-slate-100 hover:cursor-pointer'
                                >
                                    <td className='border-gray-400 p-2 text-center'>{recurso.descripcion}</td>
                                    {props.show !== 0 && (
                                        <td className='p-2 flex justify-center'>
                                            <button
                                                className='bg-red-400 p-3 rounded-md text-white ml-3'
                                                onClick={() => eliminarOtRecurso(recurso)}
                                            >
                                                <FaTrash />
                                            </button>
                                        </td>)}
                                </tr>
                            )))
                    }
                </tbody>
            </table>
        </div>
    )
}

export default ListadoOTRecursos