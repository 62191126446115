import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import ContextEstados from '../context/Estados';
import { FaEye, FaPen, FaTrash } from 'react-icons/fa'
import { Url } from './Url';

const ListadoTareas = () => {
    const [ tareas, setTareas ] = useState([]);
    const { idTarea, actividadSelected, setTareaSelected, setOpenVista, setTipoModal } = useContext(ContextEstados);

    useEffect(()=>{
        const consultaTareas = async () => {
            const url = `${Url}api/tareasList.php?actividad=${actividadSelected.id}`;
            const resultado = await axios.get(url);
            setTareas(resultado.data.results);
        }
        consultaTareas()
    },[idTarea])

    const asignarTarea = (id) => {
        tareas.forEach(element => {
            if (element.id===id)
            {
                setTareaSelected(element)
            }
        });
    }
    const eliminarTarea = (id) => {
        asignarTarea(id);
        setTipoModal(21);
        setOpenVista(true);
    }

    return (
    <div className='mx-8'>
        <table className = 'table-auto w-full border-collapse border-spacing-5 border-slate-400 text-gray-700 rounded-xl mx-auto' >
            <thead>
                <tr className='uppercase bg-gray-300'>
                    <th className='border-gray-400 p-2 text-center'>Nombre</th>
                    <th className='border-gray-400 p-2 text-center'>Tiempo (Hs)</th>
                    <th className='border-gray-400 p-2 text-center'>Acciones</th>
                </tr>
            </thead>
            <tbody >
            {
                 typeof tareas !== 'undefined' && (
                    tareas.map((tarea, index) => (
                    
                    <tr 
                        key={index}
                        className={`hover:cursor-pointer border-b border-gray-200  ${index%2===0 ? 'bg-white hover:bg-slate-200':'bg-slate-100 hover:bg-slate-200'}`}
                    >
                        <td className='border-gray-400 p-2 text-center'>{tarea.nombre}</td>
                        <td className='border-gray-400 p-2 text-center'>{tarea.tiempo}</td>
                        <td className='p-2 flex justify-center'>
                            <button
                                className='bg-red-400 p-3 rounded-md text-white ml-3'
                                onClick={()=>eliminarTarea(tarea.id)}
                            >
                                <FaTrash/>
                            </button>
                        </td>
                    </tr>
                    
                )))
            }
            </tbody>
        </table>
    </div>
    )
}

export default ListadoTareas