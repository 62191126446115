import React, { useState, useEffect, useRef, useContext } from 'react'
import { useFormik } from "formik"
import axios from 'axios'
import * as Yup from "yup"
import ContextEstados from '../context/Estados'
import SubirImagenes from './SubirImagenes'
import { Url } from './Url'

const FormRecurso = () => {
    const { idRecursoMec, setIdRecursoMec, setOpenAlta, idUsuario } = useContext(ContextEstados);
    
    let tipoImagen = "recursos";
    let idHtml = "imagenesRecurso";
    const childRef = useRef()

    useEffect(()=>{
        if (idRecursoMec!==0)
        {
            childRef.current.handleSubmit();
        }
    },[idRecursoMec])


    const formik = useFormik({
        initialValues:{
            descripcion: ''
        },
        validationSchema: Yup.object({
            descripcion: Yup.string()
                    .required("Debe ingresar una descripción"),
        }),
        onSubmit: async (valores,{resetForm}) => {
            const formData=new FormData()
            formData.append('descripcion',valores.descripcion);
            formData.append('idusuario',idUsuario);
            try{
                const response = await axios({
                    url: Url+'api/recursosAlta.php',
                    method: 'POST',
                    data: formData,
                })
            setIdRecursoMec(response.data.results.idrecurso);
            resetForm({ values: ''});
        } catch (e) {
            console.log(e)
        }

        }
    });
  return (
    <form
        className="w-full mx-auto p-5 rounded-2xl"
        onSubmit={formik.handleSubmit}
    >
        <div className="transition-all w-full sm:p-3">
            <h2 className='text-3xl uppercase mb-3 text-center'>Nuevo Recurso Mecánico</h2>
            <div className="sm:items-start mt-5">
                <label htmlFor="descripcion" className="block text-gray-600 uppercase font-bold">Nombre o Descripción</label>
                <input 
                    className="py-2 px-3 w-full rounded bg-slate-300" 
                    id="descripcion" 
                    placeholder="Nombre o descripción del recurso" 
                    type="text" 
                    value={formik.values.descripcion}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                { formik.touched.descripcion && formik.errors.descripcion ? (
                    <div className='w-full block mb-2 text-red-500'>
                        <p>{formik.errors.descripcion}</p>
                    </div>
                ) : null }
            </div>
        </div>
        <div className="pb-10">
            <SubirImagenes 
                id={idRecursoMec} 
                tipo={tipoImagen} 
                ref={childRef}
                idHtml={idHtml}
            />
        </div>
        <div className="pb-1">
            <div className="w-full rounded-lg px-3 flex justify-center">
                <input
                    type="submit"
                    className="w-1/3 sm:w-1/4 shadow-md p-3 bg-indigo-600 text-gray-100 hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg"
                    value={"Guardar Datos"}
                />
                <button
                    type="button"
                    className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => setOpenAlta(false)}
                >
                    Salir
                </button>
            </div>
        </div>
    </form>               
  )
}

export default FormRecurso