import React, { useState, useContext } from 'react'
import { useFormik } from "formik"
import axios from 'axios'
import * as Yup from "yup"
import ContextEstados from '../context/Estados'
import { Url } from './Url'
import Recursos from './Recursos'
import { useEffect } from 'react'

const FEditActividad = () => {
    const [periodo, setPeriodo] = useState(0)
    const [unidades, setUnidades] = useState([])
    const [unidad, setUnidad] = useState(1) // Unidad de variable
    const [unidadesTiempos, setUnidadesTiempos] = useState([])
    const [unidadTiempo, setUnidadTiempo] = useState(1) // Unidad de Tiempo
    const [valorUnidad, setValorUnidad] = useState(0) // Guarda el valor de Unidad de Variable o Unidad de Tiempo 
    const [tipoPeriodo, setTipoPeriodo] = useState('1')
    const [guardar, setGuardar] = useState(true);
    const [file, setFile] = useState([]);
    const [selectedFile, setSelectedFile] = useState([]);
    const { vistaRecursos, setVistaRecursos, setIdActividad, actividadSelected, setOpenVista } = useContext(ContextEstados);


    let tipoArchivo = "actividades";

    function uploadSingleFile(e) {
        let ImagesArray = Object.entries(e.target.files).map((e) =>
            URL.createObjectURL(e[1])
        );
        setFile([...file, ...ImagesArray]);
        let rData = [...selectedFile];
        rData.push(e.target.files[0])
        setSelectedFile(rData)
    }


    useEffect(() => {
        console.log(actividadSelected)
        const consultaUnidades = async () => {
            const url = Url + 'api/lUnidades.php';
            const resultado = await axios.get(url);
            setUnidades(resultado.data.results);
            //setUnidad(resultado.data.results[0].id)
            //setValorUnidad(resultado.data.results[0].id)
        }
        setPeriodo(actividadSelected.valorperiodo)
        setValorUnidad(actividadSelected.idunidad)
        setUnidadTiempo(actividadSelected.idunidad)
        setTipoPeriodo(actividadSelected.tipounidad)
        consultaUnidades()
    }, [])


    useEffect(() => {
        const consultaTiempos = async () => {
            const url = Url + 'api/lTiempos.php';
            const resultado = await axios.get(url);
            setUnidadesTiempos(resultado.data.results);
            //setUnidadTiempo(resultado.data.results[0].id);
        }
        consultaTiempos()
    }, [])

    useEffect(() => {
        if (tipoPeriodo === '1')
            setValorUnidad(unidadTiempo)
        else
            setValorUnidad(unidad)

        if ((unidadTiempo === '1' || unidad === '1') && periodo<24) {
            setGuardar(false);
        }
        else {
            setGuardar(true);
        }
    }, [unidad, unidadTiempo, tipoPeriodo, periodo])


    const formik = useFormik({
        initialValues: {
            codigo: actividadSelected.codigo,
            descripcion: actividadSelected.descripcion
        },
        validationSchema: Yup.object({
            codigo: Yup.string()
                .required("El código es necesario"),
            descripcion: Yup.string()
                .required("La descripción es necesaria")
        }),
        onSubmit: async (valores, { resetForm }) => {
            if (guardar) {
                const formData = new FormData()
                formData.append('id', actividadSelected.id);
                formData.append('codigo', valores.codigo);
                formData.append('descripcion', valores.descripcion);
                formData.append('periodo', periodo);
                formData.append('valorUnidad', valorUnidad);
                formData.append('tipoPeriodo', tipoPeriodo);
                try {
                    const response = await axios({
                        url: Url + 'api/actividadesAlta.php',/* Mismo endpoint para alta y para modificacion */
                        method: 'POST',
                        data: formData,
                    })
                    console.log(response)
                    setIdActividad(response.data.results.idactividad);
                    resetForm({ values: '' });
                    setOpenVista(false)
                    if (file) {
                        subirArchivo()
                    }
                } catch (e) {
                    console.log(e)
                }
            }
        }
    });

    const subirArchivo = async () => {
        const formData = new FormData()
        formData.append('id', actividadSelected.id);
        formData.append('tipo', tipoArchivo);

        selectedFile.forEach(file => {
            formData.append('my_file[]', file);
        });

        try {
            const response = await axios({
                url: Url + 'api/uploadfiles.php',
                method: 'POST',
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            })
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <>
            {vistaRecursos === 0 ? (
                <form
                    className="w-full mx-auto p-5 rounded-2xl"
                    onSubmit={formik.handleSubmit}
                >
                    <div className="transition-all w-full sm:p-3">
                        <div className="sm:items-start">
                            <label htmlFor="codigo" className="block mt-5 text-gray-600 uppercase font-bold">Código</label>
                            <input
                                className="py-2 px-3 w-full rounded bg-slate-300"
                                id="codigo"
                                placeholder="Ingrese un código"
                                type="text"
                                value={formik.values.codigo}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoFocus
                            />
                            {formik.touched.codigo && formik.errors.codigo ? (
                                <div className='w-full block mb-2 text-red-500'>
                                    <p>{formik.errors.codigo}</p>
                                </div>
                            ) : null}
                        </div>
                        <div className="sm:items-start">
                            <label htmlFor="descripcion" className="block mt-5 text-gray-600 uppercase font-bold">Descripción</label>
                            <input
                                className="py-2 px-3 w-full rounded bg-slate-300"
                                id="descripcion"
                                placeholder="Ingrese una descripción"
                                type="text"
                                value={formik.values.descripcion}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoFocus
                            />
                            {formik.touched.descripcion && formik.errors.descripcion ? (
                                <div className='w-full block mb-2 text-red-500'>
                                    <p>{formik.errors.descripcion}</p>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className="pb-1 flex px-3">
                        <div className='w-1/2'>
                            <button
                                className='p-3 bg-green-500 rounded-lg text-white uppercase'
                                onClick={(() => setVistaRecursos(1))}
                            >
                                Agregar Recursos
                            </button>
                        </div>
                        <div className='w-1/2'>
                            <h4 className='text-gray-700 text-xl uppercase'>Procedimiento</h4>
                            <input
                                type="file"
                                id="archivoAdjunto"
                                disabled={file.length === 5}
                                onChange={uploadSingleFile}
                            />
                        </div>
                    </div>
                    <div className='mt-5 px-3'>
                        <h3 className='text-2xl text-gray-700 uppercase'>Periodicidad</h3>
                        <h4 className='text-xl text-gray-500 uppercase'>(Cada cuanto tiempo / medida se debe realizar)</h4>
                        <div className='w-full flex mt-2'>
                            <input
                                type="radio"
                                name="tipoPeriodo"
                                id="periodoTiempo"
                                checked={tipoPeriodo === '1'}
                                onChange={() => setTipoPeriodo('1')}
                            />
                            <label htmlFor="periodoTiempo" className="block ml-2 text-gray-600 uppercase font-bold">Tiempo</label>
                        </div>
                        <div className='w-full flex'>
                            <input
                                type="radio"
                                name="tipoPeriodo"
                                id="periodoVariable"
                                checked={tipoPeriodo === '2'}
                                onChange={() => setTipoPeriodo('2')}
                            />
                            <label htmlFor="periodoVariable" className="block ml-2 text-gray-600 uppercase font-bold">Variable de Control</label>
                        </div>
                        <div className='w-full mt-2 flex'>
                            <p className='mr-2 mt-1 text-lg text-gray-600'>Hacer cada</p>
                            <input
                                className='bg-slate-300 py-2 px-3 rounded mr-2'
                                type="text"
                                value={periodo}
                                onChange={(e) => setPeriodo(e.target.value)}
                            />
                            {
                                tipoPeriodo === '1' ? (
                                    <select className='py-2 px-3 rounded bg-slate-300' id='sTiempo' value={unidadTiempo} onChange={(e) => setUnidadTiempo(e.target.value)}>
                                        {unidadesTiempos.map((item) => (
                                            <option key={item.id} value={item.id}>{item.nombre}</option>
                                        ))}
                                    </select>
                                ) : (
                                    <select className='py-2 px-3 rounded bg-slate-300' value={unidad} id='sVariable' onChange={(e) => setUnidad(e.target.value)}>
                                        {unidades.map((item) => (
                                            <option key={item.id} value={item.id}>{item.nombre}</option>
                                        ))}
                                    </select>
                                )
                            }
                        </div>
                        {!guardar && (
                            <div className='w-full block mb-2 text-red-500'>
                                <p className='uppercase'>El periodo no puede ser menor a 24 Hs.</p>
                            </div>
                        )}
                    </div>
                    <div className="mt-10 pb-1">
                        <div className="w-full rounded-lg px-3 flex justify-center">
                            <input
                                type="submit"
                                className={`mx-3 w-1/2 shadow-md p-3 transition-all text-white uppercase font-bold rounded-lg ${guardar ? 'bg-indigo-600 hover:bg-indigo-400':'bg-indigo-300'}`}
                                value="Guardar Actividad"
                            />
                            <button
                                type="button"
                                className="mx-3 w-1/2 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                                onClick={() => setOpenVista(false)}
                            >
                                Salir
                            </button>
                        </div>
                    </div>
                </form>
            ) : (
                <Recursos />
            )}
        </>
    )
}

export default FEditActividad