import React, { useContext } from 'react'
import ContextEstados from '../context/Estados';
import { Url } from './Url'

const FViewEqAct = () => {
    const { setOpenVista, eqActSelected } = useContext(ContextEstados);
    return (
        <div className='mt-10 mb-10 px-10'>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Equipo: </span>{eqActSelected.equipo}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Actividad: </span>{eqActSelected.actividad}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Valorperiodo: </span>{eqActSelected.valorperiodo}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Fecha de inicio: </span>{eqActSelected.fechainicio}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Unidad: </span>{eqActSelected.unidad}</p>
            <div className="w-full rounded-lg mt-10 px-3 flex justify-center">
                <button
                type="button"
                className="w-1/4 sm:w-1/6 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                onClick={() => setOpenVista(false)}
                >
                Salir
                </button>
            </div>
        </div>
    )
}

export default FViewEqAct