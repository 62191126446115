import React, { useContext, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import authContext from '../context/auth/authContext';
import ContextEstados from '../context/Estados';
import SideBar from './SideBar';
import Login from '../paginas/Login';
import axios from 'axios';
import { Url } from './Url';
import Notifications from './Notifications';
import MessageOut from './MessageOut';

const Layout = ({ children, pagina }) => {
	const AuthContext = useContext(authContext);
	const { usuarioAutenticado } = AuthContext;
	const mail = localStorage.getItem('sgpp_m');
	const id = localStorage.getItem('sgpp_id');
	const token = localStorage.getItem('sg_tk');
	const { setRoles, setTipoUsrLogin, idUsuario, setIdUsuario, setSucursales } = useContext(ContextEstados);

	useEffect(() => {
		usuarioAutenticado()
		///console.log("idusuario ",localStorage.getItem('sgpp_id'))
		//if (idUsuario===0)
		setIdUsuario(localStorage.getItem('sgpp_id'))
	}, []);

	useEffect(() => {
		const getRoles = async () => {
			if (id) {
				const formData = new FormData()

				formData.append('idusuario', id);
				formData.append('token', token);

				try {
					const response = await axios({
						url: Url + 'api/rolesusrLayout.php',
						method: 'POST',
						data: formData,
					})
					setRoles(response.data.results.roles)
					setTipoUsrLogin(response.data.results.tipousuario)
					//setIdUsuario(response.data.results.idusuario)
					setSucursales(response.data.results.sucursales)
				} catch (e) {
					console.log(e)
				}
			}
		}
		getRoles()
	}, [id])

	return (
		<HelmetProvider>
			<Helmet>
				<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
				<link rel="manifest" href="/site.webmanifest" />
				<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
				<title>{pagina} - Mirx Software</title>
				<meta name="description" content="Empresa" />
				<meta http-equiv="Content-Security-Policy-Report-Only" />
				<meta property="og:title" content="Empresa" />
				<meta property="og:description" content="Empresa" />
				<meta property="og:image" content="https://mirxsoftware.com" />
				<meta property="og:url" content="https://mirxsoftware.com" />
				<meta property="og:site_name" content="Empresa" />
				<meta property="og:locale" content="es_AR" />
				<meta property="og:type" content="article" />
			</Helmet>
			{
				mail ? (
					<div className='flex'>
						<SideBar />
						<Notifications />
						<MessageOut />
						{children}
					</div>
				) : (
					<Login />
				)
			}
		</HelmetProvider>
	)
}

export default Layout