import React, { useState,useContext } from 'react'
import { useFormik } from "formik"
import axios from 'axios'
import * as Yup from "yup"
import ContextEstados from '../context/Estados'
import { Url } from './Url'

const FormActTareas = () => {
    const { actividadSelected,setVistaRecursos,setIdTarea } = useContext(ContextEstados);
    
  
    const formik = useFormik({
        initialValues:{
            nombre: '',
            tiempo: ''
        },
        validationSchema: Yup.object({
            nombre: Yup.string()
                .required("El nombre es necesario"),
            descripcion: Yup.number()
                .typeError("Por favor, ingrese números")
        }),
        onSubmit: async (valores,{resetForm}) => {
            const formData=new FormData()
            formData.append('actividad',actividadSelected.id);
            formData.append('nombre',valores.nombre);
            formData.append('tiempo',valores.tiempo);
            try{
                const response = await axios({
                    url: Url+'api/actTareasAlta.php',
                    method: 'POST',
                    data: formData,
                })
                setIdTarea(response.data.results.idtarea);
                resetForm({ values: ''});
                //setOpenAlta(false)
                } catch (e) {
                    console.log(e)
            }
        }
    });
  return (
    <form
        className="w-full mx-auto p-5 rounded-2xl"
        onSubmit={formik.handleSubmit}
    >
        <div className="transition-all w-full sm:p-3">
            <h2 className='text-2xl text-gray-600 mb-3'>Nueva Tarea</h2>
            <div className='flex'>
                <div className='w-10/12 flex'>
                    <div className="sm:items-start w-full sm:w-1/2">
                        <label htmlFor="nombre" className="block mt-5 text-gray-600 uppercase font-bold">Nombre de Tarea</label>
                        <input 
                            className="py-2 px-3 w-full rounded bg-slate-300" 
                            id="nombre" 
                            placeholder="Ingrese un nombre" 
                            type="text" 
                            value={formik.values.nombre}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoFocus
                        />
                        { formik.touched.nombre && formik.errors.nombre ? (
                            <div className='w-full block mb-2 text-red-500'>
                                <p>{formik.errors.nombre}</p>
                            </div>
                        ) : null }
                    </div>
                    <div className="sm:items-start w-full sm:w-1/2 px-5">
                        <label htmlFor="tiempo" className="block mt-5 text-gray-600 uppercase font-bold">Tiempo (hs)</label>
                        <input 
                            className="py-2 px-3 w-full rounded bg-slate-300" 
                            id="tiempo" 
                            placeholder="Ingrese el tiempo en hs." 
                            type="number" 
                            value={formik.values.tiempo}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoFocus
                        />
                        { formik.touched.tiempo && formik.errors.tiempo ? (
                            <div className='w-full block mb-2 text-red-500'>
                                <p>{formik.errors.tiempo}</p>
                            </div>
                        ) : null }
                    </div>
                </div>
                <div className='w-2/12'>
                    <input
                        type="submit"
                        className="w-full mt-10 shadow-md p-3 bg-indigo-600 text-gray-100 hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg"
                        value="Agregar"
                    />
                </div>
            </div>
        </div>
    </form>               
  )
}

export default FormActTareas