import React, { useContext } from 'react'
import ContextEstados from '../context/Estados';
import axios from 'axios'
import { Url } from './Url'

const FDeletePlan = () => {
    const { setOpenVista, planSelected,setIdPlan } = useContext(ContextEstados);

    const eliminarPlan = async () => {
        const formData=new FormData()
        formData.append('id',planSelected.id);
        try{
            const response = await axios({
                url: Url+'api/planesDelete.php',
                method: 'POST',
                data: formData,
            })
        setIdPlan(response.data.results.idplan);
        setOpenVista(false)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className='mt-10 mb-10 px-10'>
            <p className='text-xl mt-4 text-gray-600'>Nombre: {planSelected.nombre}</p>
            <p className='text-xl mt-4 text-gray-600'>Fecha de Inicio: {planSelected.fechainicio}</p>
            <p className='text-xl mt-4 text-gray-600'>Fecha de Fin: {planSelected.fechafin}</p>
            <p className='text-xl mt-4 text-gray-600'>Tiempo Total: {planSelected.tiempototal}</p>
            <p className='text-xl mt-4 text-gray-600'>Costo Total: {planSelected.costototal}</p>
            <div className="w-full rounded-lg mt-10 px-3 flex justify-center">
                <button
                    type="button"
                    className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-red-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => eliminarPlan()}
                >
                    Eliminar
                </button>
                <button
                    type="button"
                    className="w-1/4 sm:w-1/6 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => setOpenVista(false)}
                >
                    Salir
                </button>
            </div>
        </div>
    )
}

export default FDeletePlan