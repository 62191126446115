import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import ContextEstados from '../context/Estados';
import { FaEye, FaPen, FaTrash } from 'react-icons/fa'
import { GoGear } from 'react-icons/go'
import { Url } from './Url';
import Tippy from '@tippyjs/react';

const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
}

const ListadoPlanes = () => {
    const [planesFil, setPlanesFil] = useState([])
    const { idPlan,planSelected,setPlanSelected, setTipoModal, buscarPlan, planes, setPlanes, setOpenVista, setVistaPlanes, idUsuario,sucursales } = useContext(ContextEstados);

    useEffect(()=>{
        const consultaPlanes = async () => {
            const formData=new FormData()
            formData.append('idusuario',idUsuario);
            try{
                const resultado = await axios({
                    url: Url+'api/planesListado.php',
                    method: 'POST',
                    data: formData,
                })
                console.log(resultado)
                setPlanes(resultado.data.results);
            } catch (e) {
                console.log(e)
            }
        }
        consultaPlanes()
    },[idPlan,sucursales])

    useEffect(() => {
        const filtrar = () => {
            let rData = [];
            if (typeof planes!=='undefined') {
                planes.forEach(element => {
                    if (element.nombre.toLowerCase().includes(buscarPlan.toLowerCase())) {
                        rData.push(element)
                    }
                });
                setPlanesFil(rData)    
            } 
        }
        filtrar()
    }, [buscarPlan, planes])

    const asignarPlan = (id) => {
        planes.forEach(element => {
            if (element.id===id)
            {
                setPlanSelected(element)
                return
            }
        });
    }

    const configPlan = async (id) => {
        asignarPlan(id);
        setVistaPlanes(1);
    }
    const verPlan = async (id) => {
        asignarPlan(id);
        setTipoModal(18)
        setOpenVista(true);
    }
    const editarPlan = async (id) => {
        asignarPlan(id);
        setTipoModal(19)
        setOpenVista(true);
    }
    const eliminarPlan = async (id) => {
        asignarPlan(id);
        setTipoModal(20)
        setOpenVista(true);
    }

    return (
    <div>
        <table className = 'table-auto w-full border-collapse border-spacing-5 border-slate-400 text-gray-700 rounded-xl mx-auto' >
            <thead>
                <tr className='uppercase bg-blue-400 text-white'>
                    <th className='border-gray-400 p-2'>Nombre</th>
                    <th className='border-gray-400 p-2'>Fecha inicio</th>
                    <th className='border-gray-400 p-2'>Fecha fin</th>
                    <th className='border-gray-400 p-2'>Acciones</th>
                </tr>
            </thead>
            <tbody >
            {
                typeof planesFil !== 'undefined' ? (
                    planesFil.map((plan, index) => (
                    
                    <tr 
                        key={plan.id}
                        className={ classNames(planSelected.id===plan.id ? 'bg-blue-200' : index%2===0 ? 'bg-white hover:bg-slate-100' : 'bg-slate-100 hover:bg-slate-200', 'hover:cursor-pointer border-b border-gray-200') }
                        onClick={()=>asignarPlan(plan.id)}
                    >
                        <td className='border-gray-400 p-1 text-center'>{plan.nombre}</td>
                        <td className='border-gray-400 p-1 text-center'>{plan.fechainicio}</td>
                        <td className='border-gray-400 p-1 text-center'>{plan.fechafin}</td>
                        <td className='p-1 flex justify-center'>
                            <Tippy
                                content={ <span className='bg-gray-600 text-white p-2 rounded-md'>Configurar Plan</span> }
                            >
                                <button
                                    className='bg-orange-500 p-3 rounded-md text-white'
                                    onClick={() => configPlan(plan.id)}
                                >
                                    <GoGear/>
                                </button>
                            </Tippy>
                            <Tippy
                                content={ <span className='bg-gray-600 text-white p-2 rounded-md'>Ver Plan de Mantenimiento</span> }
                            >
                                <button
                                    className='bg-indigo-500 p-3 rounded-md text-white ml-3'
                                    onClick={() => verPlan(plan.id)}
                                >
                                    <FaEye/>
                                </button>
                            </Tippy>
                            <Tippy
                                content={ <span className='bg-gray-600 text-white p-2 rounded-md'>Editar Plan de Mantenimiento</span> }
                            >
                                <button
                                    className='bg-gray-400 p-3 rounded-md text-white ml-3'
                                    onClick={() => editarPlan(plan.id)}
                                >
                                    <FaPen/>
                                </button>
                                </Tippy>
                            <Tippy
                                content={ <span className='bg-gray-600 text-white p-2 rounded-md'>Eliminar Plan de Mantenimiento</span> }
                            >
                                <button
                                    className='bg-red-400 p-3 rounded-md text-white ml-3'
                                    onClick={() => eliminarPlan(plan.id)}
                                >
                                    <FaTrash/>
                                </button>
                            </Tippy>
                        </td>
                    </tr>
                    
                ))
                ) : ( null )
            }
            </tbody>
        </table>
    </div>
    )
}

export default ListadoPlanes