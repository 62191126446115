import { useContext, useRef } from "react";
import QRCode from "react-qr-code";
import ContextEstados from "../../context/Estados";
import ReactToPrint from 'react-to-print';
import { FaPrint } from 'react-icons/fa'


const TablaEquiposQR = () => {
    const { 
        equipos, 
        equipoSelected,
        setEquipoSelected, 
    } = useContext(ContextEstados);
    const componentRef = useRef();

  
    const asignarEquipo = (id) => {
        equipos.forEach(element => {
            if (element.id===id)
            {
                setEquipoSelected({
                    id:element.id,
                    codigo:element.codigo,
                    descripcion: element.descripcion,
                    ubicacion:element.nombreubicacion,
                    idubicacion:element.idubicacion,
                    tipo:element.nombretipo,
                    idtipo:element.idtipo,
                    imagen:element.imagen,
                    estadoequipo:element.estadoequipo,
                    idestado:element.idestado,
                    idarea:element.idarea
                })
                return
            }
        });
    }


    return (
    <div>
        <ReactToPrint
            trigger={() => 
                <button
                    type="button"
                    className="flex shadow-md p-3 mt-2 bg-indigo-500 text-white hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg"
                >
                    <FaPrint className='mx-auto mt-1'/>
                    <p className='mx-auto text-center'>
                    Imprimir
                    </p>
                </button>}
            content={() => componentRef.current}  
        />
        <div className='print:p-5' ref={componentRef}>
            <table className = 'table-auto w-full border-collapse border-spacing-5 border-slate-400 text-gray-700 rounded-xl mx-auto mt-1'>
                <thead>
                    <tr className='uppercase bg-blue-400 text-white'>
                        <th className='border-gray-400 p-2'>
                            Código
                        </th>
                        <th className='border-gray-400 p-2'>
                            Nombre
                        </th>
                        <th className='border-gray-400 p-2'>
                            QR
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {equipos.map((item,index) => (
                        <tr 
                            key={item.id}
                            className={ `bg-white hover:bg-slate-200  hover:cursor-pointer border-b border-gray-200` }
                            onClick={()=>asignarEquipo(item.id)}
                        >
                            <td className='border-gray-400 p-1 text-center'>{item.codigo}</td>
                            <td className='border-gray-400 p-1 text-center'>{item.descripcion}</td>
                            <td className='border-gray-400 p-1 text-center'>
                                <QRCode
                                    size={80}
                                    style={{ height: "auto", maxWidth: "100%", width: "auto" }}
                                    value={item.id}
                                    viewBox={`0 0 256 256`}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </div>
  )
}

export default TablaEquiposQR