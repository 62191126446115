import React, { useState, useContext } from 'react'
import axios from 'axios';
import ContextEstados from '../context/Estados';
import { Url } from './Url';

const ListadoPlRepuestos = () => {
    const [ plRepuestos, SetPlRepuestos ] = useState([]);
    const [ fechaInicio, setFechaInicio ] = useState("");
    const [ fechaFin, setFechaFin ] = useState("");
    const { idUsuario } = useContext(ContextEstados)


    const consultaPlRepuestos = async () => {
        const formData=new FormData()
        formData.append('fechainicio',fechaInicio);
        formData.append('fechafin',fechaFin);
        formData.append('idusuario',idUsuario);
        try{
            const response = await axios({
                url: Url+'api/planeamientoRepuestos.php',
                method: 'POST',
                data: formData,
            })
            SetPlRepuestos(response.data.results);
        } catch (e) {
            console.log(e)
        }
    }

    const validar = () => {
        if (fechaInicio!=="" && fechaFin!=="")
        {
            consultaPlRepuestos()
        }
    }    

    return (
    <div>
        <div className='flex mb-5'>
            <div className='w-full sm:w-1/3 flex'>
                <label htmlFor="fechaInicio" className="block mr-2 text-gray-600 uppercase font-bold">Desde</label>
                <input 
                    className="py-2 px-3 mr-5 w-full rounded bg-slate-300" 
                    id="fechaInicio" 
                    placeholder="Ingrese una fecha inicial" 
                    type="date" 
                    value={fechaInicio}
                    onChange={(e)=>setFechaInicio(e.target.value)}
                    autoFocus
                />
            </div>
            <div className='w-full sm:w-1/3 flex'>
                <label htmlFor="fechaFin" className="block mr-2 text-gray-600 uppercase font-bold">Hasta</label>
                <input 
                    className="py-2 px-3 w-full rounded bg-slate-300" 
                    id="fechaFin" 
                    placeholder="Ingrese una fecha final" 
                    type="date" 
                    value={fechaFin}
                    onChange={(e)=>setFechaFin(e.target.value)}
                />
            </div>
            <button
                type="button"
                className="shadow-md p-3 ml-3 bg-indigo-600 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                onClick={() => validar()}
            >
                Calcular cantidad
            </button>
        </div>
        <table className = 'table-auto w-full border-collapse border-spacing-5 border-slate-400 text-gray-700 rounded-xl mx-auto' >
            <thead>
                <tr className='uppercase bg-blue-400 text-white'>
                    <th className='border-gray-400 p-2'>Código</th>
                    <th className='border-gray-400 p-2'>Descripción</th>
                    <th className='border-gray-400 p-2'>Cantidad</th>
                    <th className='border-gray-400 p-2'>costo</th>
                </tr>
            </thead>
            <tbody >
            {
                typeof plRepuestos !== 'undefined' ? (
                    plRepuestos.map((item,index) => (
                    <tr 
                        key={item.id}
                        className={`hover:bg-slate-200 hover:cursor-pointer ${item.fueradetermino===1 ? 'bg-white text-red-700' : index%2===0 ? 'bg-white':'bg-slate-100'}`}
                    >
                        <td className='border-gray-400 p-2 text-center'>{item.codigo}</td>
                        <td className='border-gray-400 p-2 text-center'>{item.descripcion}</td>
                        <td className='border-gray-400 p-2 text-center'>{item.cantidad}</td>
                        <td className='border-gray-400 p-2 text-center'>{item.costo}</td>
                    </tr>
                    
                ))
                ) : ( null )
            }
            </tbody>
        </table>
    </div>
    )
}

export default ListadoPlRepuestos