import React, { useState, useEffect, useRef, useContext } from 'react'
import { useFormik } from "formik"
import axios from 'axios'
import * as Yup from "yup"
import ContextEstados from '../context/Estados'
import SubirImagenes from './SubirImagenes'
import { Url } from './Url'

const FEditRepuesto = () => {
    const [ unidades, setUnidades ] = useState([]);
    const [ unidad, setUnidad ] = useState(0)
    const [ marcas, setMarcas ] = useState([]);
    const [ marca, setMarca ] = useState(0);
    const { idRepuesto, setIdRepuesto, repuestoSelected ,setRepuestoSelected, setOpenVista } = useContext(ContextEstados);
    

    let tipoImagen = "repuestos";
    let idHtml = "imagenesRepuesto";
    const childRef = useRef()

    useEffect(()=>{
        const consultaUnidades = async () => {
            const url = Url+'api/lUnidades.php';
            const resultado = await axios.get(url);
            setUnidades(resultado.data.results);
            setUnidad(repuestoSelected.idunidad)
        }
        consultaUnidades()
    },[])


    useEffect(()=>{
        const consultaMarcas = async () => {
            const url = Url+'api/lMarcas.php';
            const resultado = await axios.get(url);
            setMarcas(resultado.data.results);
            setMarca(repuestoSelected.idmarca)
        }
        consultaMarcas()
    },[])


    useEffect(()=>{
        if (idRepuesto!==0)
        {
            if (typeof childRef.current!=="undefined")
            {
                childRef.current.handleSubmit();
            }
        }
    },[idRepuesto])

    const eliminarImagen = async () => {
        const formData=new FormData()
        formData.append('t',"repuestos");
        formData.append('id',repuestoSelected.id);
        
        let oData={...repuestoSelected};
        oData.imagen=null;
        setRepuestoSelected(oData);
        try{
            const response = await axios({
                url: Url+'api/deleteimages.php',
                method: 'POST',
                data: formData,
            })
            setIdRepuesto(response.data.results.id);
            //setIdRepuesto(0);
        } catch (e) {
            console.log(e)
        }
    }

    const formik = useFormik({
        initialValues:{
            codigo: repuestoSelected.codigo,
            descripcion: repuestoSelected.descripcion,
            costo: repuestoSelected.costo
        },
        validationSchema: Yup.object({
            codigo: Yup.string()
                    .required("El código es necesario"),
            descripcion: Yup.string()
                    .required("Debe ingresar una descripción"),
            costo: Yup.string()
                    .required("Debe ingresar un costo")
        }),
        onSubmit: async (valores,{resetForm}) => {
            const formData=new FormData()
            formData.append('id',repuestoSelected.id);
            formData.append('codigo',valores.codigo);
            formData.append('descripcion',valores.descripcion);
            formData.append('costo',valores.costo)
            formData.append('marca',marca)
            formData.append('unidad',unidad)
            try{
                const response = await axios({
                    url: Url+'api/repuestosSet.php',
                    method: 'POST',
                    data: formData,
                })
                setIdRepuesto(response.data.results.idrepuesto);
                setOpenVista(false);
                //resetForm({ values: ''});
            } catch (e) {
                console.log(e)
            }
        }
    });
  return (
    <form
        className="w-full mx-auto p-5 rounded-2xl"
        onSubmit={formik.handleSubmit}
    >
        <div className="transition-all w-full sm:p-3">
            <div className="sm:items-start">
                <label htmlFor="codigo" className="block mt-5 text-gray-600 uppercase font-bold">Código</label>
                <input 
                    className="py-2 px-3 w-full rounded bg-slate-300" 
                    id="codigo" 
                    placeholder="Ingrese un código" 
                    type="text" 
                    value={formik.values.codigo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoFocus
                />
                { formik.touched.codigo && formik.errors.codigo ? (
                    <div className='w-full block mb-2 text-red-500'>
                        <p>{formik.errors.codigo}</p>
                    </div>
                ) : null }
            </div>
            <div className="sm:items-start mt-5">
                <label htmlFor="descripcion" className="block text-gray-600 uppercase font-bold">Descripción</label>
                <input 
                    className="py-2 px-3 w-full rounded bg-slate-300" 
                    id="descripcion" 
                    placeholder="Descripción de Repuesto" 
                    type="text" 
                    value={formik.values.descripcion}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                { formik.touched.descripcion && formik.errors.descripcion ? (
                    <div className='w-full block mb-2 text-red-500'>
                        <p>{formik.errors.descripcion}</p>
                    </div>
                ) : null }
            </div>
            <div className="sm:items-start mt-5">
                <label htmlFor="costo" className="block text-gray-600 uppercase font-bold">Costo</label>
                <input 
                    className="py-2 px-3 w-full rounded bg-slate-300" 
                    id="costo" 
                    placeholder="Costo de Repuesto" 
                    type="text" 
                    value={formik.values.costo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                { formik.touched.costo && formik.errors.costo ? (
                    <div className='w-full block mb-2 text-red-500'>
                        <p>{formik.errors.costo}</p>
                    </div>
                ) : null }
            </div>
            <div className="sm:items-start mt-5">
                <label htmlFor="sMarcas" className="block text-gray-600 uppercase font-bold">Marca</label>
                <select 
                    className='py-2 px-3 w-full rounded bg-slate-300'
                    id='sMarcas' 
                    onChange={(e)=>setMarca(e.target.value)}
                    value={marca}
                >
                    {
                        marcas.map(item => (
                            <option key={item.id} value={item.id}>{item.descripcion}</option>
                        ))
                    }
                </select>
            </div>
            <div className="sm:items-start mt-5">
                <label htmlFor="sUnidades" className="block text-gray-600 uppercase font-bold">Unidad de medida</label>
                <select 
                    className='py-2 px-3 w-full rounded bg-slate-300' 
                    id='sUnidades' 
                    onChange={(e)=>setUnidad(e.target.value)}
                    value={unidad}
                >
                    {
                        unidades.map(item => (
                            <option key={item.id} value={item.id}>{item.nombre}</option>
                        ))
                    }
                </select>
            </div>
        </div>
        <div className="pb-10">
            { repuestoSelected.imagen ? (
                <div className='lg:w-1/6 md:w-1/4 w-1/2 p-2'>
                    <img src={`${Url}${repuestoSelected.imagen}`}/>
                    <button
                        type='button'
                        className='w-full bg-red-500 text-white rounded-b-md'
                        onClick={()=>eliminarImagen()}
                    >Eliminar</button>
                </div>
            ): (
                <SubirImagenes 
                    id={repuestoSelected.id} 
                    tipo={tipoImagen} 
                    ref={childRef}
                    idHtml={idHtml}
                />
            )}
        </div>
        <div className="pb-1">
            <div className="w-full rounded-lg px-3 flex justify-center">
                <input
                    type="submit"
                    className="w-1/3 sm:w-1/4 shadow-md p-3 bg-indigo-600 text-gray-100 hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg"
                    value="Guardar Datos"
                />
                <button
                    type="button"
                    className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => setOpenVista(false)}
                >
                    Salir
                </button>
            </div>
        </div>
    </form>               
  )
}

export default FEditRepuesto