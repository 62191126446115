import React, { useContext } from 'react'
import axios from 'axios'
import ContextEstados from '../context/Estados'
//import ContextEquipos from '../context/Equipo'

import { Url } from './Url'

const FDeleteRecurso = () => {
   
    const { setIdRecursoMec, recMSelected, setOpenVista } = useContext(ContextEstados);
    
    const eliminarRecurso = async () => {
        const formData=new FormData()
        formData.append('id',recMSelected.id);
        try{
            const response = await axios({
                url: Url+'api/recursosDelete.php',
                method: 'POST',
                data: formData,
            })
        setIdRecursoMec(response.data.results.idrecurso);
        setOpenVista(false)
        } catch (e) {
            console.log(e)
        }
    }
 
    return (
    <div className='mt-10 mb-10 px-10'>
        <h3 className='text-2xl text-red-600 uppercase'>Eliminar Recurso</h3>
        <p className='text-xl mt-4 text-gray-600'>Descripción: {recMSelected.descripcion}</p>
        <p className='text-2xl mt-10 text-center text-red-600'>¿Confirma que desea eliminar el Recurso?</p>
        <div className="w-full rounded-lg mt-3 px-3 flex justify-center">
            <button
                type="button"
                className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-red-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                onClick={() => eliminarRecurso()}
            >
                Eliminar
            </button>
            <button
                type="button"
                className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                onClick={() => setOpenVista(false)}
            >
                Salir
            </button>
        </div>
    </div>
    )
}

export default FDeleteRecurso