import React, { useState, useEffect, useRef, useContext } from 'react'
import { useFormik } from "formik"
import axios from 'axios'
import * as Yup from "yup"
import ContextEstados from '../context/Estados'
import { Url } from './Url'
import Select from 'react-select';

const FEditEqAct = () => {
    const [equipos, setEquipos] = useState([]);
    const [equipo, setEquipo] = useState(0);
    const [eqSelected, setEqSelected] = useState({})
    const [actividades, setActividades] = useState([]);
    const [actividad, setActividad] = useState(0);
    const { setIdEqAct, eqActSelected, setOpenVista, idUsuario } = useContext(ContextEstados);

    useEffect(() => {
        const consultaEquipos = async () => {
            const formData = new FormData()
            formData.append('idusuario', idUsuario)
            try {
                const response = await axios({
                    url: Url + 'api/equiposListado.php',
                    method: 'POST',
                    data: formData,
                })
                setEquipos(response.data.results);
                setEquipo(eqActSelected.idequipo);
            } catch (e) {
                console.log(e)
            }
        }
        consultaEquipos()
    }, [])

    useEffect(()=> {
        equipos.forEach(element => {
            if (element.id===equipo) {
                setEqSelected(element)
            }
        });
    },[equipos, equipo])

    useEffect(() => {
        const consultaActividades = async () => {
            const url = Url + 'api/actListado.php';
            const resultado = await axios.get(url);
            setActividades(resultado.data.results)
            setActividad(eqActSelected.idactividad)
        }
        consultaActividades()
    }, [])

    const formik = useFormik({
        initialValues: {
            fechaInicio: eqActSelected.fechainicio,
            valorInicio: eqActSelected.valorinicio
        },
        validationSchema: Yup.object({
            fechaInicio: Yup.date()
                .required("La fecha de inicio es necesaria"),
            valorInicio: Yup.string()
                .required("El valor inicial es necesario")
        }),
        onSubmit: async (valores, { resetForm }) => {
            const formData = new FormData()
            formData.append('id', eqActSelected.id);
            formData.append('equipo', equipo);
            formData.append('actividad', actividad);
            formData.append('fechainicio', valores.fechaInicio);
            formData.append('valorinicio', valores.valorInicio);
            try {
                const response = await axios({
                    url: Url + 'api/eqsActsSet.php',
                    method: 'POST',
                    data: formData,
                })
                setIdEqAct(response.data.results.ideqact);
                resetForm({ values: '' });
                setOpenVista(false);
            } catch (e) {
                console.log(e)
            }

        }
    });
    const handleSelect = (val) => {
        setEquipo(val.id)
    }

    return (
        <form
            className="w-full mx-auto p-5 rounded-2xl"
            onSubmit={formik.handleSubmit}
        >
            <div className="transition-all w-full sm:p-3">
                <h2 className='text-3xl uppercase mb-3 text-center'>Editar Equipo - Actividad</h2>
                <div className="sm:items-start mt-5">
                    <label htmlFor="sEquipos" className="block text-gray-600 uppercase font-bold">Equipo</label>
                    <Select
                        options={equipos}
                        placeholder="Seleccione Equipo"
                        onChange={(value) => handleSelect(value)}
                        name='sEquipo'
                        value={eqSelected}
                    />
                </div>
                <div className="sm:items-start mt-5">
                    <label htmlFor="sActividad" className="block text-gray-600 uppercase font-bold">Actividad</label>
                    <select
                        className='py-2 px-3 w-full rounded bg-slate-300'
                        id='sActividad'
                        onChange={(e) => setActividad(e.target.value)}
                        value={actividad}
                    >
                        {typeof actividades !== "undefined" ? (
                            actividades.map(item => (
                                <option key={item.id} value={item.id}>{item.descripcion}</option>))
                        ) : null}
                    </select>
                </div>
                <div className="sm:items-start">
                    <label htmlFor="valorInicio" className="block mt-5 text-gray-600 uppercase font-bold">Valor Inicial</label>
                    <input
                        className="py-2 px-3 w-full rounded bg-slate-300"
                        id="valorInicio"
                        placeholder="Ingrese un valor inicial"
                        type="text"
                        value={formik.values.valorInicio}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoFocus
                    />
                    {formik.touched.valorInicio && formik.errors.valorInicio ? (
                        <div className='w-full block mb-2 text-red-500'>
                            <p>{formik.errors.valorInicio}</p>
                        </div>
                    ) : null}
                </div>
                <div className="sm:items-start">
                    <label htmlFor="fechaInicio" className="block mt-5 text-gray-600 uppercase font-bold">Fecha de Inicio</label>
                    <input
                        className="py-2 px-3 w-full rounded bg-slate-300"
                        id="fechaInicio"
                        placeholder="Ingrese una fecha inicial"
                        type="date"
                        value={formik.values.fechaInicio}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoFocus
                    />
                    {formik.touched.fechaInicio && formik.errors.fechaInicio ? (
                        <div className='w-full block mb-2 text-red-500'>
                            <p>{formik.errors.fechaInicio}</p>
                        </div>
                    ) : null}
                </div>
            </div>
            <div className="pb-1">
                <div className="w-full rounded-lg px-3 flex justify-center">
                    <input
                        type="submit"
                        className="w-1/3 sm:w-1/4 shadow-md p-3 bg-indigo-600 text-gray-100 hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg"
                        value="Guardar Datos"
                    />
                    <button
                        type="button"
                        className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                        onClick={() => setOpenVista(false)}
                    >
                        Salir
                    </button>
                </div>
            </div>
        </form>
    )
}

export default FEditEqAct