import React, { useContext } from 'react'
import ContextEstados from '../context/Estados';
import { Url } from './Url'

const FViewUbicacion = () => {
    const { setOpenVista, ubicacionSelected } = useContext(ContextEstados);
    return (
        <div className='mt-10 mb-10 px-10'>
            <h3 className='text-2xl text-gray-600 uppercase text-center'>Datos de Ubicación</h3>
            <p className='text-xl text-gray-600'><span className='font-semibold'>Nombre: </span>{ubicacionSelected.nombre}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Descripción: </span>{ubicacionSelected.descripcion}</p>
            <div className='w-1/2 mx-auto'>
                { ubicacionSelected.imagen && (
                <img className='w-full object-cover' src={`${Url}${ubicacionSelected.imagen}`} />
                )}
            </div>
            <div className="w-full rounded-lg mt-10 px-3 flex justify-center">
                <button
                type="button"
                className="w-1/4 sm:w-1/6 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                onClick={() => setOpenVista(false)}
                >
                Salir
                </button>
            </div>
        </div>
    )
}

export default FViewUbicacion