import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import ContextEstados from '../context/Estados';
import { FaTrash } from 'react-icons/fa'
import { Url } from './Url';


const ListadoOtSupers = (props) => {
    const [supervisores, setSupervisores] = useState([])
    const { idOrden, ordenSelected, setSupervisorSelected, setTipoModal, setOpenVista } = useContext(ContextEstados);

    useEffect(() => {
        const consultaSupers = async () => {
            const formData = new FormData()
            formData.append('idot', ordenSelected.id)
            try {
                const response = await axios({
                    url: Url + 'api/supersOtList.php',
                    method: 'POST',
                    data: formData,
                })
                setSupervisores(response.data.results);
            } catch (e) {
                console.log(e)
            }
        }
        consultaSupers()
    }, [idOrden])

    const eliminarSupervisor = (element) => {
        setSupervisorSelected(element);
        setTipoModal(71);
        setOpenVista(true);
    }

    return (
        <div>
            <table className='table-auto w-full border-collapse border-spacing-5 border-slate-400 text-gray-700 rounded-xl mx-auto' >
                <thead>
                    {props.vista === "1" && (
                        <tr className='uppercase bg-gray-300'>
                            <th className='border-gray-400 p-2 text-center'>Supervisor</th>
                            <th className='border-gray-400 p-2 text-center'>Acciones</th>
                        </tr>
                    )}
                </thead>
                <tbody >
                    {
                        typeof supervisores !== 'undefined' && (
                            supervisores.map((item) => (

                                <tr
                                    key={item.id}
                                    className={('bg-white hover:bg-slate-100 border-b border-gray-200')}
                                >
                                    <td className='border-gray-400 p-2 text-center'>{`${item.apellido}, ${item.nombre}`}</td>
                                    {props.vista === "1" && (
                                        <td className='p-2 flex justify-center'>
                                            <button
                                                type='button'
                                                className='bg-red-400 p-3 rounded-md text-white ml-3'
                                                onClick={() => eliminarSupervisor(item)}
                                            >
                                                <FaTrash />
                                            </button>
                                        </td>
                                    )}
                                </tr>

                            ))
                        )
                    }
                </tbody>
            </table>
        </div>
    )
}

export default ListadoOtSupers