import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import ContextEstados from '../context/Estados';
import { Url } from './Url';

const SumOTRepuestos = () => {
    const [ total, setTotal ] = useState(0);
    const { idOrdenRepuesto,ordenSelected,setCostoRepOT } = useContext(ContextEstados);

    useEffect(()=>{
        const consultaRepuestos = async () => {
            const url = `${Url}api/otRepuestosSum.php?orden=${ordenSelected.id}`;
            const resultado = await axios.get(url);
            console.log(resultado)
            const valTotal = resultado.data.results.total.toFixed(2);
            setTotal(valTotal);
            setCostoRepOT(valTotal)
        }
        consultaRepuestos()
    },[idOrdenRepuesto])



    return (
    <div>{total}</div>
    )
}

export default SumOTRepuestos