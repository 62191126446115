import { useState, useEffect, useContext } from 'react'
import ContextEstados from '../context/Estados';
import axios from 'axios';
import { Url } from './Url';

import { sendMessage } from '../utils/commonUtils';

const MessageOut = () => {
    const { idUsuario, messageOut, setMessageOut,tokens, setTokens } = useContext(ContextEstados)


	useEffect(()=>{
		const notif = async() => {
			if (idUsuario!==0)
			{
				const formData=new FormData()
				formData.append('idusuario',idUsuario);
				try{
					const response = await axios({
						url: Url+'api/userTokenFire.php',
						method: 'POST',
						data: formData,
					})
					setTokens(response.data.results.tokens)
				} catch (e) {
					console.log(e)
				}
	
			}
		}
		notif()
	},[idUsuario])

	useEffect(()=>{
		const sendNotification = async() => {
			if (messageOut!=='')
			{
				const formData=new FormData()
				formData.append('idusuario',idUsuario);
				try{
					const response = await axios({
						url: Url+'api/userTokenFire.php',
						method: 'POST',
						data: formData,
					})
					setTokens(response.data.results.tokens)
					const message = {
						"sKey": "AAAA5fn3S30:APA91bH3FcDpiujufRXDSNCsx7Zqn8-VUoIFSi_stWCNuptlzhCxvPE4z5qzEpVdYWZcitPECn9Go_KH3Ntf8Yvd-rndOyodYGCVxz1Rlt82k2GJfRuZrBKSlN4mKkcmdiK6lyiuaYRZ",
						"dest": response.data.results.tokens,
						"title": "CMMS President",
						"message": messageOut,
					}
					sendMessage(message)
					} catch (e) {
					console.log(e)
				}
            }
		}
		sendNotification()
	},[messageOut])

    return (
        <></>
    )
}

export default MessageOut