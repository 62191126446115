import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import ContextEstados from '../context/Estados';
import { FaPlay, FaEye, FaPen, FaTrash } from 'react-icons/fa'
import { Url } from './Url';
import Tippy from '@tippyjs/react';


const ListadoOrdenes = () => {
    const [ordenes, setOrdenes] = useState([]);
    const { idOrden,
        estadoOrden,
        notification,
        setOrdenSelected,
        setActividadSelected,
        setSolicitudSelected,
        setTipoModal,
        setVistaOrdenes,
        setOpenVista,
        idUsuario,
        roles,
        tipoUsrLogin,
        sucursales, 
        idViewOt, setIdViewOt } = useContext(ContextEstados);

    useEffect(() => {
        const consultaOrdenes = async () => {
            const formData = new FormData()
            formData.append('estado', estadoOrden);
            formData.append('tipousuario', tipoUsrLogin);
            formData.append('idusuario', idUsuario);
            try {
                const resultado = await axios({
                    url: Url + 'api/ordenesList.php',
                    method: 'POST',
                    data: formData,
                })
                //console.log(resultado)
                setOrdenes(resultado.data.results);
            } catch (e) {
                console.log(e)
            }
        }
        consultaOrdenes()
    }, [idOrden, estadoOrden, idUsuario, notification])

    const asignarOrden = (id) => {
        ordenes.forEach(element => {
            if (element.id === id) {
                if (element.idactividad) {
                    setActividadSelected({
                        id: element.idactividad
                    })
                }
                else {
                    setSolicitudSelected({
                        id: element.idsolicitud
                    })
                }
                setOrdenSelected(element)
                return
            }
        });
    }

    const verOrden = async (id) => {
        asignarOrden(id);
        setTipoModal(30)
        setOpenVista(true);
    }
    const editarOrden = async (id) => {
        asignarOrden(id);
        setVistaOrdenes(1)
    }
    const eliminarOrden = async (id) => {
        asignarOrden(id);
        setTipoModal(31)
        setOpenVista(true);
    }

    useEffect(() => {
        if (idViewOt!==0)
            verOrden(idViewOt)
    }, [idViewOt])

    return (
        <div>
            <table className='table-auto w-full border-collapse border-spacing-5 border-slate-400 text-gray-700 rounded-xl mx-auto' >
                <thead>
                    <tr className='uppercase bg-blue-400 text-white'>
                        <th className='border-gray-400 p-2'>Nº OT</th>
                        <th className='border-gray-400 p-2'>Equipo</th>
                        <th className='border-gray-400 p-2'>Solicitud / Planeamiento</th>
                        <th className='border-gray-400 p-2'>Trabajo a Realizar</th>
                        <th className='border-gray-400 p-2'>Fecha prog.</th>
                        <th className='border-gray-400 p-2'>Estado</th>
                        <th className='border-gray-400 p-2'>Unidad</th>
                        <th className='border-gray-400 p-2'>Tipo</th>
                        <th className='border-gray-400 p-2'>Prioridad</th>
                        <th className='border-gray-400 p-2'>Acciones</th>
                    </tr>
                </thead>
                <tbody >
                    {
                        typeof ordenes !== 'undefined' && (
                            ordenes.map((item, index) => (
                                <tr
                                    key={item.id}
                                    className={`hover:bg-slate-200 border-gray-200 hover:cursor-pointer ${index % 2 === 0 ? 'bg-white' : 'bg-slate-100'}`}
                                    onClick={() => asignarOrden(item.id)}
                                >
                                    <td className='border-gray-200 p-1 text-center'>{item.numero}</td>
                                    <td className='border-gray-200 p-1 text-center'>{item.equipoact ? (item.equipoact) : (item.equiposol)}</td>
                                    <td className='border-gray-200 p-1 text-left'>{item.actividad ? (item.actividad) : (item.solicitud)}</td>
                                    <td className='border-gray-200 p-1 text-left'>{item.detalletrabajo}</td>
                                    <td className='border-gray-200 p-1 text-center'>{item.fechaprog}</td>
                                    <td className={`border-gray-400 p-1 text-center ${item.idestado === '1' ? 'text-white bg-orange-300 ' : item.idestado === '2' ? ' bg-yellow-300 ' : item.idestado === '3' ? 'text-white bg-red-300 ' : item.idestado === '6' ? 'bg-cyan-300' : ' bg-green-300 '}`}>{item.estado}</td>
                                    <td className='border-gray-400 p-1 text-center'>{item.unidad}</td>
                                    <td className='border-gray-400 p-1 text-center'>{item.tipo}</td>
                                    <td className='border-gray-400 p-1 text-center'>{item.prioridad}</td>
                                    <td className='p-1 flex justify-center'>
                                        <Tippy
                                            content={<span className='bg-gray-600 text-white p-2 rounded-md'>Ver Detalle de OT</span>}
                                        >
                                            <button
                                                className='bg-indigo-500 p-3 rounded-md text-white'
                                                onClick={() => verOrden(item.id)}
                                            >
                                                <FaEye />
                                            </button>
                                        </Tippy>
                                        {(estadoOrden === 1 && (roles.includes("1") || roles.includes("26"))) && (
                                            <>
                                                {
                                                    (tipoUsrLogin === '2' || tipoUsrLogin === '4') ? (
                                                        <Tippy
                                                            content={<span className='bg-gray-600 text-white p-2 rounded-md'>Procesar OT</span>}
                                                        >
                                                            <button
                                                                className='bg-green-400 p-3 rounded-md text-white ml-3'
                                                                onClick={() => editarOrden(item.id)}
                                                            >
                                                                <FaPlay />
                                                            </button>
                                                        </Tippy>

                                                    ) : (
                                                        <Tippy
                                                            content={<span className='bg-gray-600 text-white p-2 rounded-md'>Editar OT</span>}
                                                        >
                                                            <button
                                                                className='bg-gray-400 p-3 rounded-md text-white ml-3'
                                                                onClick={() => editarOrden(item.id)}
                                                            >
                                                                <FaPen />
                                                            </button>
                                                        </Tippy>
                                                    )
                                                }
                                                {(tipoUsrLogin !== '2') && (
                                                    <Tippy
                                                        content={<span className='bg-gray-600 text-white p-2 rounded-md'>Eliminar OT</span>}
                                                    >
                                                        <button
                                                            className='bg-red-500 p-3 rounded-md text-white ml-3'
                                                            onClick={() => eliminarOrden(item.id)}
                                                        >
                                                            <FaTrash />
                                                        </button>
                                                    </Tippy>

                                                )}
                                            </>
                                        )}
                                    </td>
                                </tr>

                            ))
                        )
                    }
                </tbody>
            </table>
        </div>
    )
}

export default ListadoOrdenes