import React, { useEffect, useContext } from 'react'
import ContextEstados from '../context/Estados';
import { useFormik } from "formik"
import * as Yup from "yup"
import axios from 'axios'
import { Url } from './Url'
import Alerta from '../components/Alerta';

const FAddValueVariable = () => {
    const { setIdVariable, setOpenVista , variableSelected, setMensaje } = useContext(ContextEstados);
    

    const formik = useFormik({
        initialValues:{
            valor: '',
            fecha: ''
        },
        validationSchema: Yup.object({
            valor: Yup.number()
                    .typeError("Debe ingresar solo números")
                    .required("El valor es necesario"),
            fecha: Yup.date()
                    .required("Debe ingresar una fecha")
        }),
        onSubmit: async (valores,{resetForm}) => {
            const formData=new FormData()
            formData.append('id',variableSelected.id);
            formData.append('valor',valores.valor);
            formData.append('fecha',valores.fecha);
            try{
                const response = await axios({
                    url: Url+'api/varAddValue.php',
                    method: 'POST',
                    data: formData,
                })
            setIdVariable(response.data.results.idvariable);
            if (response.data.results.alerta===1)
                setMensaje("Se ha generado un planeamiento")
            resetForm({ values: ''});
        } catch (e) {
            console.log(e)
        }

        }
    });

    
    return (
        <div className='mt-10 mb-10 px-10'>
            <p className='text-xl text-gray-600'>Equipo: {variableSelected.equipo}</p>
            <p className='text-xl mt-4 text-gray-600'>Nombre: {variableSelected.nombre}</p>
            <p className='text-xl mt-4 text-gray-600'>Unidad: {variableSelected.unidad}</p>
            <p className='text-xl mt-4 text-gray-600'>Ultimo Valor: {variableSelected.ultimafecha}</p>
            <p className='text-xl mt-4 text-gray-600'>Acumula: {variableSelected.acumula}</p>
            <p className='text-xl mt-4 text-gray-600'>Ultima Fecha: {variableSelected.ultimafecha}</p>
            <p className='text-xl mt-4 text-gray-600'>Incremento: {variableSelected.incremento}</p>
            <h4 className='mt-5 text-xl uppercase text-gray-600 text-center'>Agregar nuevo valor de variable</h4>
            <Alerta tipo={'warning'} />
            <form
                className="w-full mx-auto p-5 rounded-2xl"
                onSubmit={formik.handleSubmit}
            >
            <div className='flex'>
                <div className="w-1/2 p-5">
                    <label htmlFor="valor" className="block text-gray-600 uppercase font-bold">Nuevo Valor</label>
                    <input 
                        className="py-2 px-3 w-full rounded bg-slate-300" 
                        id="valor" 
                        placeholder="Valor" 
                        type="text" 
                        value={formik.values.valor}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    { formik.touched.valor && formik.errors.valor ? (
                        <div className='w-full block mb-2 text-red-500'>
                            <p>{formik.errors.valor}</p>
                        </div>
                    ) : null }
                </div>                
                <div className="w-1/2 p-5">
                    <label htmlFor="fecha" className="block text-gray-600 uppercase font-bold">Fecha</label>
                    <input 
                        className="py-2 px-3 w-full rounded bg-slate-300" 
                        id="fecha" 
                        placeholder="Ingrese una fecha" 
                        type="date" 
                        value={formik.values.fecha}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoFocus
                    />
                    { formik.touched.fecha && formik.errors.fecha ? (
                        <div className='w-full block mb-2 text-red-500'>
                            <p>{formik.errors.fecha}</p>
                        </div>
                    ) : null }
                </div>
            </div>
            <div className="w-full rounded-lg mt-10 px-3 flex justify-center">
                <input
                    type="submit"
                    className="w-1/3 sm:w-1/4 shadow-md p-3 bg-indigo-600 text-gray-100 hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg"
                    value="Guardar Datos"
                />
                <button
                    type="button"
                    className="w-1/4 sm:w-1/6 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => setOpenVista(false)}
                >
                Salir
                </button>
            </div>
            </form>
        </div>
    )
}

export default FAddValueVariable