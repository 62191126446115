import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import ContextEstados from '../context/Estados';
import { FaTrash } from 'react-icons/fa'
import SumOTRepuestos from './SumOTRepuestos';
import { Url } from './Url';

const ListadoOTRepuestos = (props) => {
    const [ repuestos, setRepuestos ] = useState([]);
    const { idOrdenRepuesto, ordenSelected, setTipoModal, setOpenVista, setOtRepuestoSelected, sucursales } = useContext(ContextEstados);

    useEffect(()=>{
        const consultaRepuestos = async () => {
            const url = `${Url}api/otRepuestosListado.php?orden=${ordenSelected.id}`;
            const resultado = await axios.get(url);
            console.log(resultado)
            setRepuestos(resultado.data.results);
        }
        consultaRepuestos()
    },[idOrdenRepuesto,sucursales])

    const eliminarOtRepuesto = (element) => {
        setOtRepuestoSelected(element)
        setTipoModal(29);
        setOpenVista(true);
    }

    return (
    <div className={`${props.show!==0 && 'mx-8'}`}>
        <table className = 'table-auto w-full border-collapse border-spacing-5 border-slate-400 text-gray-700 rounded-xl mx-auto' >
            <thead>
                <tr className='uppercase bg-gray-300'>
                    <th className='border-gray-400 p-2 text-center'>Código</th>
                    <th className='border-gray-400 p-2 text-center'>Descripción</th>
                    <th className='border-gray-400 p-2 text-center'>Cantidad</th>
                    <th className='border-gray-400 p-2 text-center'>Costo</th>
                    { props.show!==0 && (
                        <th className='border-gray-400 p-2 text-center print:hidden'>Acciones</th>
                    ) }
                </tr>
            </thead>
            <tbody >
            {
                typeof repuestos !== 'undefined' ? (
                    repuestos.map((repuesto) => (
                    
                        <tr 
                            key={repuesto.id}
                            className='bg-white hover:bg-slate-100 hover:cursor-pointer'
                        >
                            <td className='border-gray-400 p-2 text-center'>{repuesto.codigo}</td>
                            <td className='border-gray-400 p-2 text-center'>{repuesto.descripcion}</td>
                            <td className='border-gray-400 p-2 text-center'>{repuesto.cantidad}</td>
                            <td className='border-gray-400 p-2 text-center'>{repuesto.costo}</td>
                            { props.show!==0 && (
                            <td className='p-2 flex justify-center'>
                                <button
                                    className='bg-red-400 p-3 rounded-md text-white ml-3'
                                    onClick={()=>eliminarOtRepuesto(repuesto)}
                                >
                                    <FaTrash/>
                                </button>
                            </td>)}
                        </tr>
    
                )
                    
                )) : null
            }
            </tbody>
        </table>
        { props.show!==0 && (
        <div className='bg-orange-100 p-3 rounded-b-lg print:hidden'>
            <div className='flex font-bold justify-around text-xl'>
                <p>Total:</p>
                <SumOTRepuestos/>
            </div>
        </div>)}
    </div>
    )
}

export default ListadoOTRepuestos