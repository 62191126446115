import React, { useState, useEffect, useRef, useContext } from 'react'
import { useFormik } from "formik"
import axios from 'axios'
import * as Yup from "yup"
import ContextEstados from '../context/Estados'
import SubirImagenes from './SubirImagenes'
import { Url } from './Url'

const FormEquipo = () => {
    const [tiposEquipos, setTiposEquipos] = useState([]);
    const [tipo, setTipo] = useState(0)
    const [equiposCentrales, setEquiposCentrales] = useState([])
    const [equipoCentral, setEquipoCentral] = useState(0)
    const [ubicaciones, setUbicaciones] = useState([]);
    const [ubicacion, setUbicacion] = useState(0)
    const [areas, setAreas] = useState([]);
    const [area, setArea] = useState(0)
    const { idEquipo, idTipo, setIdEquipo, setOpenAlta, idUsuario } = useContext(ContextEstados);
    const [errorCodigo, setErrorCodigo] = useState(false);


    let tipoImagen = "equipos";
    let idHtml = "imagenesEquipo";
    const childRef = useRef()

    useEffect(() => {
        const consultaTipos = async () => {
            const url = Url + 'api/tEqList.php';
            const resultado = await axios.get(url);
            setTiposEquipos(resultado.data.results);
            setTipo(resultado.data.results[0].id)
        }
        consultaTipos()
    }, [idTipo])

    useEffect(() => {
        const consultaArea = async () => {
            const formData = new FormData()
            formData.append('idusuario', idUsuario)
            try {
                const response = await axios({
                    url: Url + 'api/areas.php',
                    method: 'POST',
                    data: formData,
                })
                setAreas(response.data.results)
                setArea(response.data.results[0].id)
            } catch (e) {
                console.log(e)
            }
        }
        consultaArea()
    }, [])

    useEffect(() => {
        const getEquiposCentrales = async () => {
            const formData = new FormData()
            formData.append('idusuario', idUsuario)
            formData.append('combo', 1)
            try {
                const response = await axios({
                    url: Url + 'api/eqCentralList.php',
                    method: 'POST',
                    data: formData,
                })
                //console.log(response)
                setEquiposCentrales(response.data.results)
            } catch (e) {
                console.log(e)
            }
        }
        getEquiposCentrales()
    }, [])

    useEffect(() => {
        const consultaUbica = async () => {
            if (area !== 0) {
                const formData = new FormData()
                formData.append('idarea', area)
                try {
                    const response = await axios({
                        url: Url + 'api/ubicaciones.php',
                        method: 'POST',
                        data: formData,
                    })
                    setUbicaciones(response.data.results)
                    setUbicacion(response.data.results[0].id)
                } catch (e) {
                    console.log(e)
                }
            }
        }
        consultaUbica()
    }, [area])

    useEffect(() => {
        if (idEquipo !== 0) {
            childRef.current.handleSubmit();
        }
    }, [idEquipo])

    const checkEquipo = async (valor) => {
        const formData = new FormData()
        formData.append('codigo', valor);
        try {
            const response = await axios({
                url: Url + 'api/checkEquipo.php',
                method: 'POST',
                data: formData,
            })
            if (response.data.results.id !== 0) {
                setErrorCodigo(true)
            }
            else {
                if (errorCodigo)
                    setErrorCodigo(false)
            }
        } catch (e) {
        }
    }

    const formik = useFormik({
        initialValues: {
            codigo: '',
            descripcion: '',
            observaciones: ''
        },
        validationSchema: Yup.object({
            codigo: Yup.string()
                .required("El código es necesario"),
            descripcion: Yup.string()
                .required("Debe ingresar un nombre de equipo")
        }),
        onSubmit: async (valores, { resetForm }) => {
            const formData = new FormData()
            if (!errorCodigo) {
                formData.append('codigo', valores.codigo);
                formData.append('descripcion', valores.descripcion);
                formData.append('tipo', tipo)
                formData.append('ubicacion', ubicacion)
                formData.append('area', area)
                formData.append('equipoCentral', equipoCentral)
                formData.append('observaciones', valores.observaciones)
                try {
                    const response = await axios({
                        url: Url + 'api/equiposAlta.php',
                        method: 'POST',
                        data: formData,
                    })
                    setIdEquipo(response.data.results.idequipo);
                    resetForm({ values: '' });
                } catch (e) {
                    console.log(e)
                }
            }
        }
    });
    return (
        <form
            className="w-full mx-auto p-5 rounded-2xl"
            onSubmit={formik.handleSubmit}
        >
            <div className="transition-all w-full sm:p-3">
                <h2 className='text-3xl uppercase mb-3 text-center'>Nuevo Equipo o Componente</h2>
                <div className="sm:items-start">
                    <label htmlFor="codigo" className="block mt-5 text-gray-600 uppercase font-bold">Código</label>
                    <input
                        className="py-2 px-3 w-full rounded bg-slate-300"
                        id="codigo"
                        placeholder="Ingrese un código"
                        type="text"
                        value={formik.values.codigo}
                        onChange={(e) => {
                            formik.handleChange(e);
                            checkEquipo(e.target.value)
                        }}
                        onKeyUp={(e) => checkEquipo(e.target.value)}
                        onBlur={formik.handleBlur}
                        autoFocus
                    />
                    {errorCodigo && (
                        <div className='w-full block mb-2 text-red-500'>
                            <p>El código corresponde a otro equipo</p>
                        </div>
                    )}
                    {formik.touched.codigo && formik.errors.codigo ? (
                        <div className='w-full block mb-2 text-red-500'>
                            <p>{formik.errors.codigo}</p>
                        </div>
                    ) : null}
                </div>
                <div className="sm:items-start mt-5">
                    <label htmlFor="descripcion" className="block text-gray-600 uppercase font-bold">Nombre</label>
                    <input
                        className="py-2 px-3 w-full rounded bg-slate-300"
                        id="descripcion"
                        placeholder="Nombre de Equipo"
                        type="text"
                        value={formik.values.descripcion}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.descripcion && formik.errors.descripcion ? (
                        <div className='w-full block mb-2 text-red-500'>
                            <p>{formik.errors.descripcion}</p>
                        </div>
                    ) : null}
                </div>
                <div className="sm:items-start mt-5">
                    <label htmlFor="sTipos" className="block text-gray-600 uppercase font-bold">Tipo de Equipo / Activo</label>
                    <select className='py-2 px-3 w-full rounded bg-slate-300' id='sTipos' onChange={(e) => setTipo(e.target.value)}>
                        {typeof tiposEquipos !== "undefined" ? (
                            tiposEquipos.map(item => (
                                <option key={item.id} value={item.id}>{item.nombre}</option>))
                        ) : null}
                    </select>
                </div>
                <div className="sm:items-start mt-5">
                    <label htmlFor="sEquipoCentral" className="block text-gray-600 uppercase font-bold">Equipo General (En caso de ser componente de otro equipo)</label>
                    <select
                        className='py-2 px-3 w-full rounded bg-slate-300'
                        id='sEquipoCentral'
                        onChange={(e) => setEquipoCentral(e.target.value)}
                    >
                        {
                            typeof equiposCentrales !== 'undefined' &&
                            equiposCentrales.map(item => (
                                <option key={item.id} value={item.id}>{`${item.codigo} - ${item.descripcion}`}</option>
                            ))
                        }
                    </select>
                </div>
                <div className="sm:items-start mt-5">
                    <label htmlFor="sArea" className="block text-gray-600 uppercase font-bold">Yacimiento</label>
                    <select className='py-2 px-3 w-full rounded bg-slate-300' id='sArea' onChange={(e) => setArea(e.target.value)}>
                        {typeof areas !== "undefined" ? (
                            areas.map(item => (
                                <option key={item.id} value={item.id}>{item.nombre}</option>))
                        ) : null}
                    </select>
                </div>
                <div className="sm:items-start mt-5">
                    <label htmlFor="sUbicacion" className="block text-gray-600 uppercase font-bold">Ubicación</label>
                    <select className='py-2 px-3 w-full rounded bg-slate-300' id='sUbicacion' onChange={(e) => setUbicacion(e.target.value)}>
                        {typeof ubicaciones !== "undefined" ? (
                            ubicaciones.map(item => (
                                <option key={item.id} value={item.id}>{`${item.nombre} - ${item.descripcion}`}</option>))
                        ) : null}
                    </select>
                </div>
                <div className="sm:items-start mt-5">
                    <label htmlFor="observaciones" className="block text-gray-600 uppercase font-bold">Observaciones</label>
                    <input
                        className="py-2 px-3 w-full rounded bg-slate-300"
                        id="observaciones"
                        placeholder="Observaciones"
                        type="text"
                        value={formik.values.observaciones}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </div>
            </div>
            <div className="pb-10">
                <SubirImagenes
                    id={idEquipo}
                    tipo={tipoImagen}
                    ref={childRef}
                    idHtml={idHtml}
                />
            </div>
            <div className="pb-1">
                <div className="w-full rounded-lg px-3 flex justify-center">
                    <input
                        type="submit"
                        className="w-1/3 sm:w-1/4 shadow-md p-3 bg-indigo-600 text-gray-100 hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg"
                        value="Guardar Datos"
                    />
                    <button
                        type="button"
                        className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                        onClick={() => setOpenAlta(false)}
                    >
                        Salir
                    </button>
                </div>
            </div>
        </form>
    )
}

export default FormEquipo