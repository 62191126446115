import React, { useContext } from 'react'
import { useFormik } from "formik"
import axios from 'axios'
import * as Yup from "yup"
import ContextEstados from '../context/Estados'
import { Url } from './Url'

const FEditRecurso = () => {
    const { setIdRecursoMec, recMSelected, setOpenVista } = useContext(ContextEstados);
    
    const formik = useFormik({
        initialValues:{
            descripcion: recMSelected.descripcion,
        },
        validationSchema: Yup.object({
            descripcion: Yup.string()
                    .required("Debe ingresar una descripción"),
        }),
        onSubmit: async (valores,{resetForm}) => {
            const formData=new FormData()
            formData.append('id',recMSelected.id);
            formData.append('descripcion',valores.descripcion);
            try{
                const response = await axios({
                    url: Url+'api/recursosSet.php',
                    method: 'POST',
                    data: formData,
                })
                //console.log(response.data.results)
                setIdRecursoMec(response.data.results.idrecurso);
                setOpenVista(false);
                //resetForm({ values: ''});
            } catch (e) {
                console.log(e)
            }
        }
    });
  return (
    <form
        className="w-full mx-auto p-5 rounded-2xl"
        onSubmit={formik.handleSubmit}
    >
        <div className="transition-all w-full sm:p-3">
            <div className="sm:items-start mt-5">
                <label htmlFor="descripcion" className="block text-gray-600 uppercase font-bold">Descripción</label>
                <input 
                    className="py-2 px-3 w-full rounded bg-slate-300" 
                    id="descripcion" 
                    placeholder="Descripción de Recursos" 
                    type="text" 
                    value={formik.values.descripcion}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                { formik.touched.descripcion && formik.errors.descripcion ? (
                    <div className='w-full block mb-2 text-red-500'>
                        <p>{formik.errors.descripcion}</p>
                    </div>
                ) : null }
            </div>
        </div>
        <div className="pb-1">
            <div className="w-full rounded-lg px-3 flex justify-center">
                <input
                    type="submit"
                    className="w-1/3 sm:w-1/4 shadow-md p-3 bg-indigo-600 text-gray-100 hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg"
                    value="Guardar Datos"
                />
                <button
                    type="button"
                    className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => setOpenVista(false)}
                >
                    Salir
                </button>
            </div>
        </div>
    </form>               
  )
}

export default FEditRecurso