import { useState, useEffect, useContext } from 'react'
import ContextEstados from '../context/Estados';
import axios from 'axios';
import { Url } from './Url'

const VEquipoActividades = () => {
    const [actividades, setActividades] = useState([])
    const { equipoSelected } = useContext(ContextEstados);

    useEffect(() => {
        const consultaActividades = async () => {
            const formData = new FormData()
            formData.append('idequipo', equipoSelected.id)
            try {
                const response = await axios({
                    url: Url + 'api/gActividades.php',
                    method: 'POST',
                    data: formData,
                })
                console.log(response)
                setActividades(response.data.results);
            } catch (e) {
                console.log(e)
            }
        }
        consultaActividades()
    }, [])


    return (
        <div className='w-full mt-5 mb-5'>
            {(typeof actividades !== 'undefined' && actividades.length > 0) && (
                <div className='w-full bg-gray-100 p-2 rounded-xl'>
                    <h3 className='text-center uppercase text-xl'>Actividades programadas al Equipo / Activo</h3>
                    <table className='table-auto w-full border-collapse border-spacing-5 border-slate-400 text-gray-700 rounded-xl mx-auto' >
                        <thead>
                            <tr className='uppercase bg-gray-300'>
                                <th className='border-gray-400 p-2 text-center'>Plan de Mantenimiento</th>
                                <th className='border-gray-400 p-2 text-center'>Actividad</th>
                                <th className='border-gray-400 p-2 text-center'>Frecuencia</th>
                            </tr>
                        </thead>
                        <tbody >
                            {
                                typeof actividades !== 'undefined' && (
                                    actividades.map((item, index) => (
                                        <tr
                                            key={index}
                                            className={('bg-white hover:bg-slate-100 border-b border-gray-200')}
                                        >
                                            <td className='border-gray-400 p-2 text-center'>{`${item.plan}`}</td>
                                            <td className='border-gray-400 p-2 text-center'>{`${item.actividad}`}</td>
                                            <td className='border-gray-400 p-2 text-center'>{`${item.valorperiodo} ${item.unidad}`}</td>
                                        </tr>
                                    ))
                                )
                            }
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    )
}

export default VEquipoActividades