import { useState, useContext } from 'react'
import { RiFileExcel2Line } from 'react-icons/ri'
import ContextEstados from '../context/Estados';
import axios from 'axios';
import { Url } from '../components/Url';
import Spinner from '../assets/circles-menu-1.gif'

const FormImportUsuarios = () => {
    const [file, setFile] = useState([]);
    const [uploading, setUploading] = useState(false)
    const [selectedFile, setSelectedFile] = useState([]);
    const { setUsuarios, idUsuario, setOpenAlta } = useContext(ContextEstados);


    function uploadSingleFile(e) {
        let ImagesArray = Object.entries(e.target.files).map((e) =>
            URL.createObjectURL(e[1])
        );
        setFile([...file, ...ImagesArray]);
        let rData = [...selectedFile];
        rData.push(e.target.files[0])
        setSelectedFile(rData)
    }

    const subirArchivo = async () => {
        setUploading(true)
        const formData = new FormData()
        formData.append('idusuario', idUsuario);
        selectedFile.forEach(file => {
            formData.append('my_file[]', file);
        });

        try {
            const response = await axios({
                url: Url + 'api/uploadUsuarios.php',
                method: 'POST',
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            })
            setUploading(false)
            setOpenAlta(false)
        } catch (e) {
            console.log(e)
        }
    }


    return (
        <div className='w-full p-10'>
            <h3 className='text-gray-600 text-center text-2xl'>Importar usuarios desde archivo</h3>
            {uploading ? (
                <div>
                    <img className='mx-auto mb-10' src={Spinner} />
                    <p className='text-center text-2xl mt-20 text-white bg-orange-500 p-4 rounded-3xl'>
                        Los Datos están siendo procesados
                    </p>
                </div>
            ) : (
                <>
                    <div className='w-1/3 mt-10 mx-auto'>
                        <label
                            className='bg-green-600 hover:bg-green-400 hover:cursor-pointer p-3 ml-2 rounded-md text-white uppercase flex'
                            htmlFor='archivoAdjunto'
                        >
                            <p className='text-xl mr-2'>
                                <RiFileExcel2Line />
                            </p>
                            Seleccionar archivo
                        </label>
                        <input
                            className='hidden'
                            type="file"
                            id="archivoAdjunto"
                            disabled={file.length === 5}
                            onChange={uploadSingleFile}
                        />
                    </div>
                    <div className='w-10/12 mt-10 mx-auto'>
                        <div className="w-full rounded-lg px-3 flex justify-center">
                            <input
                                type="button"
                                className="w-1/3 sm:w-1/4 shadow-md p-3 bg-indigo-600 text-gray-100 hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg"
                                value="Importar Datos"
                                onClick={() => subirArchivo()}
                            />
                            <button
                                type="button"
                                className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                                onClick={() => setOpenAlta(false)}
                            >
                                Salir
                            </button>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default FormImportUsuarios