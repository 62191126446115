import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import ContextEstados from '../context/Estados'
import { Url } from './Url'

const FormTipos = () => {
    const [nombre, setNombre] = useState('')
    const [descripcion, setDescripcion] = useState('')
    const [error, setError] = useState(false)
    const [guardar, setGuardar] = useState(false)
    const { setIdTipo, setOpenAlta, idUsuario } = useContext(ContextEstados);

    useEffect(() => {
        const checkTipo = async () => {
            if (nombre!=='') {
                const formData = new FormData()
                formData.append('nombre', nombre)
                try {
                    const response = await axios({
                        url: Url + 'api/checkTipo.php',
                        method: 'POST',
                        data: formData,
                    })
                    //console.log(response)
                    if (response.data.results.encontrado===1) {
                        setError(true)
                        setGuardar(false)
                    }
                    else {
                        setError(false)
                        if (nombre!=='')
                            setGuardar(true)
                    }
                } catch (e) {
                    console.log(e)
                }    
            }
        }
        checkTipo()
    }, [nombre])

    const guardarDatos = async (event) => {
        event.preventDefault();
        console.log("llega")
        if (!error && nombre!=='') {
            const formData = new FormData()
            formData.append('nombre', nombre);
            formData.append('descripcion', descripcion);
            try {
                const response = await axios({
                    url: Url + 'api/tiposAlta.php',
                    method: 'POST',
                    data: formData,
                })
                console.log(response)
                setIdTipo(response.data.results.idtipo);
                setNombre('')
                setDescripcion('')
                setGuardar(false)
            } catch (e) {
                console.log(e)
            }    
        } 
    }

    return (
        <form
            className="w-full mx-auto p-5 rounded-2xl"
            onSubmit={guardarDatos}>
            <div className="transition-all w-full sm:p-3">
                <h2 className='text-3xl uppercase mb-3 text-center'>Nuevo Tipo de Equipo</h2>
                <div className="sm:items-start">
                    <label htmlFor="nombre" className="block mt-5 text-gray-600 uppercase font-bold">Tipo</label>
                    <input
                        className="py-2 px-3 w-full rounded bg-slate-300"
                        id="nombre"
                        placeholder="Tipo de Equipo"
                        type="text"
                        value={nombre}
                        onChange={(e)=>setNombre(e.target.value)}
                        autoFocus
                    />
                    {error && (
                        <div className='w-full block mb-2 text-red-500'>
                            <p>Existe un tipo de equipo con ese nombre</p>
                        </div>
                    )}
                </div>
                <div className="sm:items-start mt-5">
                    <label htmlFor="descripcion" className="block text-gray-600 uppercase font-bold">Descripción</label>
                    <input
                        className="py-2 px-3 w-full rounded bg-slate-300"
                        id="descripcion"
                        placeholder="Descripción del Tipo de Equipo"
                        type="text"
                        value={descripcion}
                        onChange={(e)=>setDescripcion(e.target.value)}
                    />
                </div>
            </div>
            <div className="pb-1">
                <div className="w-full rounded-lg px-3 flex justify-center">
                    <input
                        type="submit"
                        className={`w-1/3 sm:w-1/4 shadow-md p-3 text-gray-100 transition-all uppercase font-bold rounded-lg ${guardar ? 'bg-indigo-600 hover:bg-indigo-400':'bg-indigo-300'}`}
                        value="Guardar Datos"
                        disabled={!guardar}
                    />
                    <button
                        type="button"
                        className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                        onClick={() => setOpenAlta(false)}
                    >
                        Salir
                    </button>
                </div>
            </div>
        </form>
    )
}

export default FormTipos