import React, { useState, useEffect, useContext } from 'react'
import { useFormik } from "formik"
import axios from 'axios'
import * as Yup from "yup"
import ContextEstados from '../context/Estados';
import FormActRepuestos from './FormActRepuestos';
import FormSolRepuestos from './FormSolRepuestos';
import ListadoActRepuestos from './ListadoActRepuestos';
import ListadoSolRepuestos from './ListadoSolRepuestos';
import Recursos from './Recursos';
import { Url } from './Url';


const FormOrden = () => {
    const [ estados, setEstados ] = useState([]);
    const [ estado, setEstado ] = useState(0);
    const [ tipos, setTipos ] = useState([]);
    const [ tipo, setTipo ] = useState(0);
    const [ prioridades, setPrioridades ] = useState([]);
    const [ prioridad, setPrioridad ] = useState(0);
    const [ supervisores, setSupervisores ] = useState([]);
    const [ supervisor, setSupervisor ] = useState(0);
    const [ vistaRecursos, setVistaRecursos ] = useState(0);
    const { setIdOrden, 
        setIdSolicitud,
        setMessageOut, 
        ordenSelected, 
        setVistaOrdenes, 
        solicitudSelected, 
        setOpenVista, 
        tipoModal, 
        idUsuario } = useContext(ContextEstados);

    useEffect(()=>{
        const consultaEstados = async () => {
            const url = Url+'/api/lEstadosOrdenes.php';
            const resultado = await axios.get(url);
            setEstados(resultado.data.results);
            setEstado(resultado.data.results[0].id);
        }
        consultaEstados()
    },[])
    
    useEffect(()=>{
        const consultaTipos = async () => {
            const url = Url+'/api/lTiposOrdenes.php';
            const resultado = await axios.get(url);
            setTipos(resultado.data.results);
            setTipo(resultado.data.results[0].id)
        }
        consultaTipos()
    },[])
    
    useEffect(()=>{
        const consultaPrioridades = async () => {
            const url = Url+'/api/lPrioridades.php';
            const resultado = await axios.get(url);
            setPrioridades(resultado.data.results);
            setPrioridad(resultado.data.results[0].id)
        }
        consultaPrioridades()
    },[])

    useEffect(()=>{
        const consultaUsuarios = async () => {
            const formData=new FormData()
            formData.append('tipo',4);
            formData.append('idusuario',idUsuario)
            try{
                const resultado = await axios({
                    url: Url+'api/usuariosListado.php',
                    method: 'POST',
                    data: formData,
                })
                setSupervisores(resultado.data.results);
                setSupervisor(resultado.data.results[0].id);
                } catch (e) {
                console.log(e)
            }
        }
        consultaUsuarios()
    },[])
    
    const formik = useFormik({
        initialValues:{
            fechaInicio: '',
            horaInicio: '',
            detalleTrabajo: ''
        },
        validationSchema: Yup.object({
            fechaInicio: Yup.date()
                .required("La fecha de inicio es necesaria"),

            detalleTrabajo: Yup.string()
        }),
        onSubmit: async (valores,{resetForm}) => {
            const formData=new FormData()
            formData.append('id',solicitudSelected.id);
            formData.append('fechainicio',valores.fechaInicio);
            formData.append('horainicio',valores.horaInicio);
            formData.append('estado',estado);
            formData.append('tipo',tipo);
            formData.append('prioridad',prioridad);
            formData.append('supervisor',supervisor);
            formData.append('descripciontarea',valores.detalleTrabajo);
            formData.append('idusuario',idUsuario);
            try{
                const response = await axios({
                    url: Url+'api/otAlta.php',
                    method: 'POST',
                    data: formData,
                })
            setIdOrden(response.data.results[0].idot);
            setMessageOut(response.data.results[0].notificacion);
            setVistaOrdenes(0)
            setIdSolicitud(response.data.results[0].idot);
            setOpenVista(false);
            } catch (e) {
                console.log(e)
            }
        }
    });

    return (
    <>
    { vistaRecursos===0 ? (
    <form
        className={"w-full mx-auto p-10 bg-white"}
        onSubmit={formik.handleSubmit}
    >
        <h1 className='text-center text-gray-500 text-3xl mb-2'>
            Nueva Orden de Trabajo
        </h1>
        <h2 className='text-gray-700 text-xl text-center uppercase mb-5'>
            Fecha de programación {ordenSelected.fechacreacion}
        </h2>
        <p className='text-xl text-gray-700'>Equipo: { ordenSelected.equipoact ? (ordenSelected.equipoact): solicitudSelected.equipo }</p>
        <div className='w-full mx-auto'>
            <div className='flex'>
                <div className='w-1/2 p-3 '>
                    <div className='w-full sm:items-start'>
                        <label htmlFor="fechaInicio" className="block text-gray-600 uppercase font-bold">Fecha de Inicio</label>
                        <input 
                            className="py-2 px-3 w-full rounded bg-slate-100" 
                            id="fechaInicio" 
                            placeholder="Ingrese una fecha de inicio" 
                            type="date" 
                            value={formik.values.fechaInicio}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoFocus
                        />
                        { formik.touched.fechaInicio && formik.errors.fechaInicio ? (
                            <div className='w-full block mb-2 text-red-500'>
                                <p>{formik.errors.fechaInicio}</p>
                            </div>
                        ) : null }
                    </div>
                    <div className='w-full'>
                        <label htmlFor="horaInicio" className="block mt-5 text-gray-600 uppercase font-bold">Hora de Inicio</label>
                        <input 
                            className="py-2 px-3 w-full rounded bg-slate-100" 
                            id="horaInicio" 
                            placeholder="Ingrese una hora de inicio" 
                            type="time" 
                            value={formik.values.horaInicio}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />


                    </div>

                </div>
                <div className='w-1/2 p-3'>
                    <div className="w-full sm:items-start">
                        <label htmlFor="sEstados" className="block text-gray-600 uppercase font-bold">Estado</label>
                        <select 
                            className='py-2 px-3 w-full rounded bg-slate-100' 
                            id='sEstados' 
                            onChange={(e)=>setEstado(e.target.value)}
                            disabled={ solicitudSelected.id ? true : false}
                            value={estado}
                        >
                            { typeof estados !== "undefined" ? (
                                estados.map(item => (
                                    <option key={item.id} value={item.id}>{item.nombre}</option>))
                            ) : null }
                        </select>
                    </div>
                    <div className="w-full sm:items-start">
                        <label htmlFor="sTipos" className="block mt-5 text-gray-600 uppercase font-bold">Tipo</label>
                            <select 
                            className='py-2 px-3 w-full rounded bg-slate-100' 
                            id='sTipos' 
                            onChange={(e)=>setTipo(e.target.value)}
                            disabled={ solicitudSelected.id ? true : false}
                            value={ solicitudSelected.id ? 2 : tipo}
                        >
                            { typeof tipos !== "undefined" ? (
                                tipos.map(item => (
                                    <option key={item.id} value={item.id}>{item.nombre}</option>))
                            ) : null }
                        </select>
                    </div>
                    <div className="w-full sm:items-start">
                        <label htmlFor="sPrioridades" className="block mt-5 text-gray-600 uppercase font-bold">Prioridad</label>
                        <select 
                            className='py-2 px-3 w-full rounded bg-slate-100' 
                            id='sPrioridades' 
                            onChange={(e)=>setPrioridad(e.target.value)}
                            value={prioridad}
                        >
                            { typeof prioridades !== "undefined" ? (
                                prioridades.map(item => (
                                    <option key={item.id} value={item.id}>{item.nombre}</option>))
                            ) : null }
                        </select>
                    </div>
                </div>
            </div>
            <hr/>
            <div className="flex mt-2 mb-2">
                <div className='w-1/2'>
                    <button 
                        type="button"
                        className='p-3 bg-green-500 rounded-lg text-white uppercase'
                        onClick={(()=>setVistaRecursos(1))}
                    >
                        Agregar Recursos
                    </button>
                </div>
            </div>
            <hr/>
            <div className='flex'>
                <div className='w-1/2 p-3 '>
                    <div className="w-full sm:items-start">
                        <label htmlFor="sSupervisores" className="block text-gray-600 uppercase font-bold">Supervisor</label>
                        <select 
                            className='py-2 px-3 w-full rounded bg-slate-100' 
                            id='sSupervisores' 
                            onChange={(e)=>setSupervisor(e.target.value)}
                            value={supervisor}
                        >
                            { typeof supervisores !== "undefined" ? (
                                supervisores.map(item => (
                                    <option key={item.id} value={item.id}>{item.apellido}</option>))
                            ) : null }
                        </select>
                    </div>
                </div>
                
            </div>
            <hr/>
            <div className='flex'>
                <div className='w-full p-3 '>
                    <div className="w-full sm:items-start">
                        <label htmlFor="detalleTrabajo" className="block text-gray-600 uppercase font-bold">Descripción o detalle de trabajo</label>
                        <textarea
                            id="detalleTrabajo"
                            className='w-full rounded p-2 bg-slate-100'
                            value={formik.values.detalleTrabajo}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        ></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div className="pb-1 mt-5">
            <div className="w-full rounded-lg px-3 flex justify-center">
                <input
                    type="submit"
                    className="w-1/3 sm:w-1/4 shadow-md p-3 bg-indigo-600 text-gray-100 hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg"
                    value="Guardar Datos"
                />
                <button
                    type="button"
                    className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => setOpenVista(false)}
                >
                    Salir
                </button>
            </div>
        </div>
    </form>
    ):(
    <div className='w-full h-full p-10 bg-slate-200'>
        { tipoModal!==33 ? (
            <>
            <Recursos/>
            </>
        ):(
            <>
            <FormSolRepuestos/>
            <ListadoSolRepuestos/>
            </>
        )}
        <div className='w-1/3 sm:w-1/4 mx-auto'>
        <button
            type="button"
            className="w-full shadow-md p-3 mt-5 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
            onClick={() => setVistaRecursos(0)}
        >
            Salir
        </button>

        </div>
    </div>
    )}
    </>
    )
}

export default FormOrden