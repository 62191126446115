import { useContext, useEffect } from 'react'
import ContextEstados from '../context/Estados';

const Notifications = () => {
    const AuthContext = useContext(ContextEstados);
    const { notification, setNotification } = AuthContext;

    useEffect(()=>{

        const showMensaje = () => {
            if (notification!==""){
                
                setTimeout(() => { 
                    setNotification("");
                }, 5000)
            }
        }
        showMensaje()
    },[notification])
    return (
        <>
        { notification!=="" && (
        <div className='absolute w-full mt-20'>
            <div className='mt-20 bg-orange-200 text-black uppercase text-bold text-center p-3 w-1/2 mx-auto rounded-xl z-50 '>
               { notification }
            </div>
        </div>
        )}
        </>
    );
}
 
export default Notifications;