import React, { useContext } from 'react'
import axios from 'axios'
import ContextEstados from '../context/Estados'
import { Url } from './Url'

const FDeleteTipo = () => {
    const { setIdTipo, tipoSelected, setOpenVista } = useContext(ContextEstados);

    const eliminarRegistro = async () => {
        const formData = new FormData()
        formData.append('id', tipoSelected.id);
        try {
            const response = await axios({
                url: Url + 'api/tiposDel.php',
                method: 'POST',
                data: formData,
            })
            console.log(response)
            setIdTipo(response.data.results.id);
            setOpenVista(false);
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div
            className="w-full mx-auto p-5 rounded-2xl"
        >
            <div className="transition-all w-full sm:p-3">
                <div className="sm:items-start">
                    <label htmlFor="nombre" className="block mt-5 text-gray-600 uppercase font-bold">Tipo</label>
                    <p className='text-2xl text-gray-600'>{tipoSelected.nombre}</p>
                </div>
                <div className="sm:items-start mt-5">
                    <label htmlFor="descripcion" className="block text-gray-600 uppercase font-bold">Descripción</label>
                    <p className='text-2xl text-gray-600'>{tipoSelected.descripcion}</p>
                </div>
            </div>
            <div className="pb-1">
                <div className="w-full rounded-lg px-3 flex justify-center">
                    <button
                        type="button"
                        className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-red-500 text-white hover:bg-red-400 transition-all uppercase font-bold rounded-lg"
                        onClick={() => eliminarRegistro()}
                    >
                        Eliminar Tipo de Equipo
                    </button>
                    <button
                        type="button"
                        className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                        onClick={() => setOpenVista(false)}
                    >
                        Salir
                    </button>
                </div>
            </div>
        </div>
    )
}

export default FDeleteTipo