import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import ContextEstados from '../context/Estados';
import { Url } from './Url';

const SumActRepuestos = () => {
    const [ total, setTotal ] = useState(0);
    const { idActRepuesto,actividadSelected, setCostoRepOT } = useContext(ContextEstados);

    useEffect(()=>{
        const consultaRepuestos = async () => {
            const url = `${Url}api/actRepuestosSum.php?actividad=${actividadSelected.id}`;
            const resultado = await axios.get(url);
            const valTotal=resultado.data.results.total.toFixed(2);
            setTotal(valTotal);
            setCostoRepOT(valTotal)
        }
        consultaRepuestos()
    },[idActRepuesto])

  

    return (
    <div>
        {total}
    </div>
    )
}

export default SumActRepuestos