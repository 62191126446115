import { useState, useContext } from 'react'
import ContextEstados from '../context/Estados';
import jsPDF from 'jspdf';
import ListadoOtTecnicos from './ListadoOtTecnicos';
import { FaPrint } from 'react-icons/fa'
import Logo from '../assets/Logo.png'
import axios from 'axios';
import { Url } from './Url';

const FViewPermiso = () => {
    const [fechaPermiso, setFechaPermiso] = useState()
    const [view, setView] = useState(1)
    const [permiso, setPermiso] = useState({})
    const { ordenSelected, setOpenVista, setTipoModal } = useContext(ContextEstados);

    const getPermiso = async () => {
        const formData = new FormData()
        formData.append('orden', ordenSelected.id)
        formData.append('fechaprog', fechaPermiso)
        try {
            const response = await axios({
                url: Url + 'api/getPermiso.php',
                method: 'POST',
                data: formData,
            })
            console.log(response)
            setPermiso(response.data.results);
            setView(1)
        } catch (e) {
            console.log(e)
        }
    }


    const genPdf = () => {
        const doc = new jsPDF();
        doc.addImage(Logo, 'png', 10, 8, 40, 12)
        doc.text('PERMISO DE TRABAJO', 100, 18, 'center')
        doc.text(`PERMISO Nº: `, 200, 14, 'right');
        doc.text(`S-${ordenSelected.numero}-${permiso.numero}`, 190, 21, 'right');
        doc.setFontSize(9)
        doc.setFont(undefined, 'bold')
        doc.text("(A) Fecha de emisión:", 10, 30, 'left')
        doc.setFont(undefined, 'normal')
        doc.text(`${permiso.fecha}`, 45, 30, 'left')

        doc.setFont(undefined, 'bold')
        doc.text("Hora:", 70, 30, 'left')
        doc.setFont(undefined, 'normal')
        doc.text(`${ordenSelected.horaprog}`, 81, 30, 'left')
        doc.setFont(undefined, 'bold')
        doc.text("Yacimiento:", 120, 30, 'left')
        doc.setFont(undefined, 'normal')
        doc.text(`${ordenSelected.actividad ? (ordenSelected.areaact) : (ordenSelected.areasol)}`, 141, 30, 'left')
        doc.setFont(undefined, 'bold')
        doc.text(`Equipo / Instalación / Lugar:`, 10, 35, 'left')
        doc.setFont(undefined, 'normal')

        doc.text(`${ordenSelected.equipoact ? (ordenSelected.equipocodact) : (ordenSelected.equipocodsol)} - ${ordenSelected.equipoact ? (ordenSelected.equipoact) : (ordenSelected.equiposol)}`, 55, 35, 'left')

        doc.setFont(undefined, 'bold')
        doc.text(`Trabajo a Ejecutar:`, 10, 40, 'left')
        doc.setFont(undefined, 'normal')
        let desc = ordenSelected.detalletrabajo;
        doc.text(50, 40, desc, { maxWidth: 150, align: "justify" })

        doc.setFont(undefined, 'bold')
        doc.text(`Equipo/Heram. a utilizar:`, 10, 51, 'left')

        let recursosMecanicos = '';
        if (ordenSelected.recursos !== 'undefined') {
            ordenSelected.recursos.forEach(element => {
                recursosMecanicos = recursosMecanicos + element.descripcion + ' - ';
            });
        }
        doc.setFont(undefined, 'normal')
        doc.text(recursosMecanicos, 51, 51, 'left')

        doc.setFont(undefined, 'bold')
        doc.text(`(B) Tipo de Trabajo:`, 10, 58, 'left')
        doc.setFont(undefined, 'normal')
        doc.text(`Tarea ${ordenSelected.tipo}`, 45, 58, 'left')
        doc.text("General", 10, 63, 'left')
        doc.text("Excavación", 40, 63, 'left')
        doc.text("Caliente", 70, 63, 'left')
        doc.text("Cañerias", 100, 63, 'left')
        doc.text("Confinado", 130, 63, 'left')
        doc.text("En altura", 160, 63, 'left')
        doc.text("Electrico", 10, 68, 'left')
        doc.text("Civil", 40, 68, 'left')
        doc.text("Otro", 70, 68, 'left')
        doc.text("Detallar Otro:..........................................................................................................................................................................................", 10, 78, 'left')
        doc.text("(C) Completar todos los casilleros con: Si / No / N/A", 10, 85, 'left')
        doc.text("Las condiciones climáticas permiten realizar el trabajo", 10, 95, 'left');
        doc.text("Las mediciones de gases permiten iniciar la tarea", 10, 100, 'left');
        doc.text("Equipo consignado y aislado eléctricamente", 10, 105, 'left');
        doc.text("Confección de ATS", 10, 110, 'left');
        doc.text("El equipo está despresurizado", 10, 115, 'left');
        doc.text("El equipo está plaqueado o desvinculado", 10, 120, 'left');
        doc.text("El equipo está aislado solo por válvulas", 10, 125, 'left');
        doc.text("Existen equipos de luchas contra el fuego", 10, 130, 'left');

        doc.text("El área está libre de líquidos y/o sólidos combustibles", 110, 95, 'left');
        doc.text("La instalación y equipos eléctricos son adecuados", 110, 100, 'left');
        doc.text("Herramientas: están en condiciones y acordes a la tarea", 110, 105, 'left');
        doc.text("Están colocadas las Barreras físicas y/o Señalización", 110, 110, 'left');
        doc.text("La tarea requiere mediciones sucesivas de gases", 110, 115, 'left');
        doc.text("Existe superposición de tareas.", 110, 120, 'left');
        doc.text("Charla previa con todos los involucrados", 110, 125, 'left');
        doc.text("Otros detectados", 110, 130, 'left');
        doc.text("Detallar Otros:...................................................................................................................................................................................................", 10, 140, 'left')
        doc.text("Precauciones Especiales:.................................................................................................................................................................................", 10, 148, 'left')

        doc.text("(D) APERTURA", 10, 155, 'left');
        doc.text("PERMISO DE TRABAJO", 10, 160, 'left');
        doc.text("AUTORIZANTE", 58, 158, 'left');
        doc.text("EJECUTANTE", 99, 158, 'left');
        doc.text("RESP. DE LA", 138, 155, 'left');
        doc.text("INSTALACION", 138, 160, 'left');
        doc.text("PERSONAL DE", 175, 155, 'left');
        doc.text("SEGURIDAD", 178, 160, 'left');
        doc.text("FIRMA", 10, 167, 'left');
        doc.text("NOMBRE Y APELLIDO", 10, 176, 'left');

        ///INTEGRANTES EQUIPO
        doc.text("(E) INTEGRANTES DEL EQUIPO DE TRABAJO", 10, 185, 'left')


        ///CIERRE
        doc.text("(F) CIERRE PERMISO DE TRABAJO", 10, 215, 'left')
        doc.text("Hora de Finalización:", 70, 215, 'left')

        ///DETALLE TAREA
        doc.text("Verificar la tarea:", 10, 225, 'left')

        doc.text("Ha sido completada", 60, 225, 'left')
        doc.text("No ha sido iniciada", 100, 225, 'left')
        doc.text("Ha comenzado, pero no ha terminado", 140, 225, 'left')
        doc.text("Suspendida o no iniciada por haberse realizado observaciones de seguridad.", 60, 232, 'left')
        doc.text("El lugar de trabajo a quedado en condiciones de seguridad, orden y limpieza.", 60, 239, 'left')
        doc.text("Observaciones:...................................................................................................................................................................................................", 10, 247, 'left')
        doc.text(10, 252, "Este permiso debe permanecer exhibido en el lugar de trabajo, al vencerse o finalizar la tarea debe entregarse al autorizante o al responsable de la instalación. Su vigencia es hasta finalizar el trabajo o la jornada.", { maxWidth: 180, align: "justify" })

        doc.text("FIRMA", 98, 265, 'left');
        doc.text("NOMBRE Y APELLIDO", 152, 265, 'left');
        doc.text("EJECUTANTE", 10, 277, 'left');
        doc.text("RESP. DE LA INSTALACION", 10, 287, 'left');

        doc.setLineWidth(0, 1);
        ///ENCABEZADO
        doc.rect(5, 5, 200, 287);
        doc.rect(5, 5, 200, 20);
        doc.rect(5, 5, 50, 20);
        doc.rect(55, 5, 100, 20);

        ///DESCRIPCION
        doc.rect(5, 25, 200, 28);

        ///TIPO DE TRABAJO
        doc.rect(5, 53, 200, 27);

        doc.rect(23, 60, 4, 4);
        doc.rect(58, 60, 4, 4);
        doc.rect(83, 60, 4, 4);
        doc.rect(115, 60, 4, 4);
        doc.rect(146, 60, 4, 4);
        doc.rect(175, 60, 4, 4);

        doc.rect(25, 65, 4, 4);
        doc.rect(48, 65, 4, 4);
        doc.rect(78, 65, 4, 4);


        ///CASILLEROS
        doc.rect(5, 80, 200, 70);

        doc.rect(90, 92, 5, 5);
        doc.rect(90, 97, 5, 5);
        doc.rect(90, 102, 5, 5);
        doc.rect(90, 107, 5, 5);
        doc.rect(90, 112, 5, 5);
        doc.rect(90, 117, 5, 5);
        doc.rect(90, 122, 5, 5);
        doc.rect(90, 127, 5, 5);

        doc.rect(195, 92, 5, 5);
        doc.rect(195, 97, 5, 5);
        doc.rect(195, 102, 5, 5);
        doc.rect(195, 107, 5, 5);
        doc.rect(195, 112, 5, 5);
        doc.rect(195, 117, 5, 5);
        doc.rect(195, 122, 5, 5);
        doc.rect(195, 127, 5, 5);


        ///GRILLA
        doc.rect(5, 150, 200, 12);
        doc.rect(5, 162, 200, 9);
        doc.rect(5, 171, 200, 9);

        doc.rect(50, 150, 40, 30);
        doc.rect(130, 150, 40, 30);

        ///INTEGRANTES
        doc.rect(5, 180, 200, 30);

        ///CIERRE
        doc.rect(5, 210, 200, 10);

        ///DETALLE TAREA
        doc.rect(5, 220, 200, 40);

        doc.rect(54, 222, 4, 4);
        doc.rect(94, 222, 4, 4);
        doc.rect(134, 222, 4, 4);
        doc.rect(54, 229, 4, 4);
        doc.rect(54, 236, 4, 4);


        ///GRILLA FINAL
        doc.rect(5, 260, 200, 10);
        doc.rect(5, 270, 200, 10);
        doc.rect(70, 260, 65, 32);
        doc.save(`Permiso_OT_${ordenSelected.numero}.pdf`)

    }




    return (
        <div className="w-full mx-auto rounded-2xl bg-white">
            {view === 1 ? (
                <>
                    <div
                        id='pagePdf'
                        className="w-full mx-auto rounded-2xl bg-white"
                    >
                        <div className='flex w-full'>
                            <div className='w-1/4 h-24 border p-1 border-black'>
                                <img className='object-cover' src={Logo} />
                            </div>
                            <div className='w-2/4 py-5 border border-black'>
                                <h3 className='text-center text-black text-xl mb-2 tracking-normal'>
                                    PERMISO   DE   TRABAJO
                                </h3>
                            </div>
                            <div className='w-1/4 py-5 border border-black'>
                                <p className='uppercase text-center'>Permiso Nº</p>
                                <p className='uppercase text-center'>S-{`${ordenSelected.numero}-${permiso.numero}`}</p>
                            </div>
                        </div>
                        <div className='w-full'>
                            <div className='w-full p-2 border border-black'>
                                <p className='uppercase'><span className='font-bold'>(A) Fecha de emisión: </span><span>{permiso.fecha}</span><span className='ml-5 font-bold'>Hora: </span><span>{ordenSelected.horaprog}</span><span className='ml-5 font-bold'>Yacimiento: </span><span>{ordenSelected.actividad ? ordenSelected.areaact : ordenSelected.areasol}</span></p>
                                <p className='uppercase'><span className='font-bold'>Equipo / Instalación / Lugar: </span>{`${ordenSelected.equipoact ? (ordenSelected.equipocodact) : (ordenSelected.equipocodsol)} - ${ordenSelected.equipoact ? (ordenSelected.equipoact) : (ordenSelected.equiposol)}`}</p>
                                <p className='uppercase'><span className='font-bold'>Trabajo a Ejecutar: </span>{`${ordenSelected.detalletrabajo}`}</p>
                                <p className='uppercase font-bold'>Equipo/Heram. a utilizar: {
                                    typeof ordenSelected.recursos !== 'undefined' && (
                                        ordenSelected.recursos.map((recurso, index) => (<span key={index} className='font-normal'>{recurso.descripcion} - </span>)))
                                }</p>
                            </div>
                            <div className='w-full p-2 border border-black'>
                                <p className=''>(B) Tipo de Trabajo: Tarea {ordenSelected.tipo}</p>
                                <p className='uppercase mt-4'>General <span className='mr-6 ml-1 px-2 border border-black'></span>Excavación <span className='mr-6 ml-1 px-2 border border-black'></span>Caliente <span className='mr-6 ml-1 px-2 border border-black'></span>Cañerias <span className='mr-6 ml-1 px-2 border border-black'></span>Confinado <span className='mr-6 ml-1 px-2 border border-black'></span>En altura <span className='ml-2 px-2 border border-black'></span></p>
                                <p className='uppercase'>Electrico <span className='mr-6 ml-1 px-2 border border-black'></span>Civil <span className='mr-6 ml-1 px-2 border border-black'></span>Otro <span className='ml-2 px-2 border border-black'></span></p>
                                <p className='uppercase mt-2'>Detallar Otro:...............................................................................................................................................................................................................</p>
                            </div>
                            <div className='w-full p-2 border border-black'>
                                <p className='uppercase'>(C) Completar todos los casilleros con: Si / No / N/A</p>
                                <div className='w-full mt-3 flex'>
                                    <div className='w-1/2 flex'>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>Las condiciones climáticas permiten realizar el trabajo</td>
                                                    <td className='px-2 mt-1 border border-black'> </td>
                                                </tr>
                                                <tr>
                                                    <td>Las mediciones de gases permiten iniciar la tarea</td>
                                                    <td className='px-2 mt-1 border border-black'> </td>
                                                </tr>
                                                <tr>
                                                    <td>Equipo consignado y aislado eléctricamente</td>
                                                    <td className='px-2 mt-1 border border-black'> </td>
                                                </tr>
                                                <tr>
                                                    <td>Confección de ATS</td>
                                                    <td className='px-2 mt-1 border border-black'> </td>
                                                </tr>
                                                <tr>
                                                    <td>El equipo está despresurizado</td>
                                                    <td className='px-2 mt-1 border border-black'> </td>
                                                </tr>
                                                <tr>
                                                    <td>El equipo está plaqueado o desvinculado</td>
                                                    <td className='px-2 mt-1 border border-black'> </td>
                                                </tr>
                                                <tr>
                                                    <td>El equipo está aislado solo por válvulas</td>
                                                    <td className='px-2 mt-1 border border-black'> </td>
                                                </tr>
                                                <tr>
                                                    <td>Existen equipos de luchas contra el fuego</td>
                                                    <td className='px-2 mt-1 border border-black'> </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='w-1/2 flex'>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>El área está libre de líquidos y/o sólidos combustibles</td>
                                                    <td className='p-2 mt-1 border border-black'> </td>
                                                </tr>
                                                <tr>
                                                    <td>La instalación y equipos eléctricos son adecuados</td>
                                                    <td className='p-2 mt-1 border border-black'> </td>
                                                </tr>
                                                <tr>
                                                    <td>Herramientas: están en condiciones y acordes a la tarea</td>
                                                    <td className='p-2 mt-1 border border-black'> </td>
                                                </tr>
                                                <tr>
                                                    <td>Están colocadas las Barreras físicas y/o Señalización</td>
                                                    <td className='p-2 mt-1 border border-black'> </td>
                                                </tr>
                                                <tr>
                                                    <td>La tarea requiere mediciones sucesivas de gases</td>
                                                    <td className='p-2 mt-1 border border-black'> </td>
                                                </tr>
                                                <tr>
                                                    <td>Existe superposición de tareas.</td>
                                                    <td className='p-2 mt-1 border border-black'> </td>
                                                </tr>
                                                <tr>
                                                    <td>Charla previa con todos los involucrados</td>
                                                    <td className='p-2 mt-1 border border-black'> </td>
                                                </tr>
                                                <tr>
                                                    <td>Otros detectados</td>
                                                    <td className='p-2 mt-1 border border-black'> </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>


                                </div>
                                <div className='w-full mt-5'>
                                    <p>Detallar Otros: ..............................................................................................................................................................................................................</p>
                                    <p>Precauciones especiales: ..........................................................................................................................................................................................</p>
                                </div>
                            </div>
                            <div className='w-full'>
                                <div className='w-full flex'>
                                    <div className='w-1/5 p-2 border border-black'>
                                        <p className='uppercase'>(D) Apertura Permiso de Trabajo</p>
                                    </div>
                                    <div className='w-1/5 p-2 border border-black'>
                                        <p className='uppercase text-center'>Autorizante</p>
                                    </div>
                                    <div className='w-1/5 p-2 border border-black'>
                                        <p className='uppercase text-center'>Ejecutante</p>
                                    </div>
                                    <div className='w-1/5 p-2 border border-black'>
                                        <p className='uppercase text-center'>Resp. de la instalación</p>
                                    </div>
                                    <div className='w-1/5 p-2 border border-black'>
                                        <p className='uppercase text-center'>Personal de seguridad</p>
                                    </div>
                                </div>
                                <div className='w-full flex'>
                                    <div className='w-1/5 p-2 border border-black'>
                                        <p className='uppercase'>Firma</p>
                                    </div>
                                    <div className='w-1/5 p-2 border border-black'></div>
                                    <div className='w-1/5 p-2 border border-black'></div>
                                    <div className='w-1/5 p-2 border border-black'></div>
                                    <div className='w-1/5 p-2 border border-black'></div>
                                </div>
                                <div className='w-full flex'>
                                    <div className='w-1/5 p-2 border border-black'>
                                        <p className='uppercase'>Nombre y Apellido</p>
                                    </div>
                                    <div className='w-1/5 p-2 border border-black'></div>
                                    <div className='w-1/5 p-2 border border-black'></div>
                                    <div className='w-1/5 p-2 border border-black'></div>
                                    <div className='w-1/5 p-2 border border-black'></div>
                                </div>
                            </div>
                            <div className='w-full p-2 border border-black'>
                                <p className='uppercase'>(E) Integrantes del equipo de trabajo</p>
                                <ListadoOtTecnicos />
                            </div>
                            <div className='w-full p-2 border border-black'>
                                <p ><span className='uppercase'>(F) Cierre permiso de trabajo       </span>Hora de Finalización:</p>
                            </div>
                            <div className='w-full p-2 border border-black'>
                                <div className='w-full flex'>
                                    <div className='w-1/5'>
                                        <p>Verificar la tarea:</p>
                                    </div>
                                    <div className='w-4/5'>
                                        <p><span className='mr-1 px-2 border border-black'></span>Ha sido completada <span className='ml-6 mr-1 px-2 border border-black'></span>No ha sido iniciada <span className='ml-6 mr-1 px-2 border border-black'></span>Ha comenzado, pero no ha terminado</p>
                                        <p><span className='mr-1 px-2 border border-black'></span>Suspendida o no iniciada por haberse realizado observaciones de seguridad.</p>
                                        <p><span className='mr-1 px-2 border border-black'></span>El lugar de trabajo ha quedado en condiciones de seguridad, orden y limpieza.</p>
                                    </div>
                                </div>
                                <div className='w-full mt-5'>
                                    <p>Observaciones: ........................................................................................................................................................................................................... .........................................................................................................................................................................................................................................</p>
                                    <p>Este permiso debe permanecer exhibido en el lugar de trabajo, al vencerse o finalizar la tarea debe entregarse al autorizante o al responsable de la instalación. Su vigencia es hasta finalizar el trabajo o la jornada.</p>
                                </div>
                            </div>
                            <div className='w-full'>
                                <div className='w-full flex'>
                                    <div className='w-1/3 p-2 border border-black'>

                                    </div>
                                    <div className='w-1/3 p-2 border border-black'>
                                        <p className='uppercase text-center'>Firma</p>
                                    </div>
                                    <div className='w-1/3 p-2 border border-black'>
                                        <p className='uppercase text-center'>Nombre y Apellido</p>
                                    </div>
                                </div>
                                <div className='w-full flex'>
                                    <div className='w-1/3 p-2 border border-black'>
                                        <p className='uppercase'>Ejecutante</p>
                                    </div>
                                    <div className='w-1/3 p-2 border border-black'>
                                    </div>
                                    <div className='w-1/3 p-2 border border-black'>
                                    </div>
                                </div>
                                <div className='w-full flex'>
                                    <div className='w-1/3 p-2 border border-black'>
                                        <p className='uppercase'>Resp. de la instalación</p>
                                    </div>
                                    <div className='w-1/3 p-2 border border-black'>
                                    </div>
                                    <div className='w-1/3 p-2 border border-black'>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pb-1 mt-5">
                        <div className="w-full rounded-lg px-3 flex justify-center">
                            <button
                                type="button"
                                className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-blue-500 text-white hover:bg-blue-400 transition-all uppercase font-bold rounded-lg"
                                onClick={() => setTipoModal(30)}
                            >
                                Ver O.T.
                            </button>
                            <button
                                type="button"
                                className="w-1/3 sm:w-1/4 flex shadow-md p-3 ml-3 bg-orange-500 text-white hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg"
                                onClick={() => setView(2)}
                            >
                                <p className='mx-auto text-center'>
                                    Fecha de Permiso
                                </p>
                            </button>
                            <button
                                type="button"
                                className="w-1/3 sm:w-1/4 flex shadow-md p-3 ml-3 bg-indigo-500 text-white hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg"
                                onClick={() => genPdf()}
                            >
                                <FaPrint className='mx-auto mt-1' />
                                <p className='mx-auto text-center'>
                                    Imprimir Permiso
                                </p>
                            </button>
                            <button
                                type="button"
                                className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                                onClick={() => setOpenVista(false)}
                            >
                                Salir
                            </button>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className='w-full flex'>
                        <label htmlFor="fechaPermiso" className="block mr-2 text-gray-600 uppercase font-bold">Fecha de Permiso de Trabajo</label>
                        <input
                            className="py-2 px-3 w-full rounded bg-slate-300"
                            id="fechaPermiso"
                            placeholder="Ingrese una fecha para el permiso de trabajo"
                            type="date"
                            value={fechaPermiso}
                            onChange={(e) => setFechaPermiso(e.target.value)}
                        />
                    </div>
                    <div className="pb-1 mt-5">
                        <div className="w-full rounded-lg px-3 flex justify-center">
                            <button
                                type="button"
                                className="w-1/3 sm:w-1/4 flex shadow-md p-3 ml-3 bg-indigo-500 text-white hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg"
                                onClick={() => getPermiso()}
                            >
                                Confirmar Fecha   
                            </button>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default FViewPermiso