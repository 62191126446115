import React, { useContext } from 'react'
import ContextEstados from '../context/Estados';
import { Url } from './Url'

const FViewVariable = () => {
    //const { equipo } = useContext(ContextEquipos);
    const { setOpenVista, variableSelected } = useContext(ContextEstados);
    return (
        <div className='mt-10 mb-10 px-10'>
            <p className='text-xl text-gray-600'><span className='font-semibold'>Equipo: </span>{variableSelected.equipo}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Nombre: </span>{variableSelected.nombre}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Unidad: </span>{variableSelected.unidad}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Ultimo Valor: </span>{variableSelected.ultimafecha}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Acumula: </span>{variableSelected.acumula}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Ultima Fecha: </span>{variableSelected.ultimafecha}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Incremento: </span>{variableSelected.incremento}</p>
            <div className="w-full rounded-lg mt-10 px-3 flex justify-center">
                <button
                type="button"
                className="w-1/4 sm:w-1/6 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                onClick={() => setOpenVista(false)}
                >
                Salir
                </button>
            </div>
        </div>
    )
}

export default FViewVariable