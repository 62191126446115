import React, { useContext } from 'react'
import ContextEstados from '../context/Estados';
import { Url } from './Url'

const FViewRepuesto = () => {
    //const { equipo } = useContext(ContextEquipos);
    const { setOpenVista, repuestoSelected } = useContext(ContextEstados);
    return (
        <div className='mt-10 mb-10 px-10'>
            <p className='text-xl text-gray-600'><span className='font-semibold'>Código: </span>{repuestoSelected.codigo}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Descripción: </span>{repuestoSelected.descripcion}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Marca: </span>{repuestoSelected.nombremarca}</p>
            <p className='text-xl mt-4 mb-8 text-gray-600'><span className='font-semibold'>Unidad: </span>{repuestoSelected.nombreunidad}</p>
            <div className='w-1/2 mx-auto'>
                { repuestoSelected.imagen && (
                <img className='w-full object-cover rounded-xl' src={`${Url}${repuestoSelected.imagen}`} />
                )}
            </div>
            <div className="w-full rounded-lg mt-10 px-3 flex justify-center">
                <button
                type="button"
                className="w-1/4 sm:w-1/6 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                onClick={() => setOpenVista(false)}
                >
                Salir
                </button>
            </div>
        </div>
    )
}

export default FViewRepuesto