//import RNReactNativeHapticFeedback from "react-native-haptic-feedback";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import { initializeApp } from "firebase/app";
//import { getMessaging, getToken, onMessage } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

const firebaseConfig = {

    apiKey: "AIzaSyBTsxNBLBP0zR2S0c6fPwswZHQIaj6lEZM",

    authDomain: "appgind-3a188.firebaseapp.com",

    projectId: "appgind-3a188",

    storageBucket: "appgind-3a188.appspot.com",

    messagingSenderId: "987741244285",

    appId: "1:987741244285:web:bc69f305cb54cdb762da14"

};


// Initialize Firebase

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

//import messaging from "firebase/messaging";

export const requestUserPermission = async () => {
    const authStatus = await messaging().requestPermission();
    const enabled = authStatus === messaging.AuthorizationStatus.AUTHORIZED || authStatus === messaging.AuthorizationStatus.PROVISIONAL;

    if (enabled) {
        console.log('Autorization status: ', authStatus)
    }
}

export const getTk = async () => {
    await messaging().registerDeviceForRemoteMessages();
    const token = await messaging().getToken();
    return token
}


export const sendMessage = async (message) => {
    const response = await fetch(`http://186.158.210.127:4000/message`, {
        method: "POST",
        headers: {
            "Content-Type": "Application/json",
        },
        body: JSON.stringify(message),
    });
    console.log("response fetch backend: ",response)
    const content = await response.json();
    console.log(content)
    /*setMsg(
        content.msg === 1
            ? {
                message: "Se ha creado el producto correctamente",
                color: "success",
                visible: "si",
            }
            : {
                message: "No se ha podido crear el producto",
                color: "danger",
                visible: "si",
            }
    );*/
}