import React, { useState, useEffect, useContext } from 'react'
import { useFormik } from "formik"
import axios from 'axios'
import * as Yup from "yup"
import ContextEstados from '../context/Estados'
import { Url } from './Url'

const FormUbicacion = () => {
    const [ areas, setAreas ] = useState([]);
    const [ area, setArea ] = useState(0)
    const { setIdUbicacion, setOpenAlta, idUsuario } = useContext(ContextEstados);
    
    useEffect(()=>{
        const consultaArea = async () => {
            const formData=new FormData()
            formData.append('idusuario',idUsuario)
            try{
                const response = await axios({
                    url: Url+'api/areas.php',
                    method: 'POST',
                    data: formData,
                })
                setAreas(response.data.results)
            setArea(response.data.results[0].id)
            } catch (e) {
                console.log(e)
            }        
        }
        consultaArea()
    },[])

    const formik = useFormik({
        initialValues:{
            nombre: '',
            descripcion: ''
        },
        validationSchema: Yup.object({
            nombre: Yup.string()
                    .required("El nombre es necesario"),
            descripcion: Yup.string()
                    .required("Debe ingresar una descripción")
        }),
        onSubmit: async (valores,{resetForm}) => {
            if (area!==0)
            {
                const formData=new FormData()
                formData.append('nombre',valores.nombre);
                formData.append('descripcion',valores.descripcion);
                formData.append('idarea',area);
                try{
                    const response = await axios({
                        url: Url+'api/ubicacionesAlta.php',
                        method: 'POST',
                        data: formData,
                    })
                    setIdUbicacion(response.data.results.idubicacion);
                    resetForm({ values: ''});
                } catch (e) {
                    console.log(e)
                }            
            }
        }
    });
  return (
    <form
        className="w-full mx-auto p-5 rounded-2xl"
        onSubmit={formik.handleSubmit}
    >
        <div className="transition-all w-full sm:p-3">
            <h2 className='text-3xl uppercase mb-3 text-center'>Nueva Ubicación</h2>
            <div className="sm:items-start">
                <label htmlFor="nombre" className="block mt-5 text-gray-600 uppercase font-bold">Nombre</label>
                <input 
                    className="py-2 px-3 w-full rounded bg-slate-300" 
                    id="nombre" 
                    placeholder="Ingrese el nombre de la Ubicación" 
                    type="text" 
                    value={formik.values.nombre}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoFocus
                />
                { formik.touched.nombre && formik.errors.nombre ? (
                    <div className='w-full block mb-2 text-red-500'>
                        <p>{formik.errors.nombre}</p>
                    </div>
                ) : null }
            </div>
            <div className="sm:items-start mt-5">
                <label htmlFor="descripcion" className="block text-gray-600 uppercase font-bold">Descripción</label>
                <input 
                    className="py-2 px-3 w-full rounded bg-slate-300" 
                    id="descripcion" 
                    placeholder="Descripción de Ubicación" 
                    type="text" 
                    value={formik.values.descripcion}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                { formik.touched.descripcion && formik.errors.descripcion ? (
                    <div className='w-full block mb-2 text-red-500'>
                        <p>{formik.errors.descripcion}</p>
                    </div>
                ) : null }
            </div>
            <div className="sm:items-start mt-5">
                <label htmlFor="sArea" className="block text-gray-600 uppercase font-bold">Yacimiento</label>
                <select className='py-2 px-3 w-full rounded bg-slate-300' id='sArea' onChange={(e)=>setArea(e.target.value)}>
                    { typeof areas !== "undefined" ? (
                        areas.map(item => (
                            <option key={item.id} value={item.id}>{item.nombre}</option>))
                    ) : null }
                </select>
                { area===0 ? (
                    <div className='w-full block mb-2 text-red-500'>
                        <p>Debe seleccionar un yacimiento</p>
                    </div>
                ) : null }
            </div>
        </div>
        <div className="pb-1">
            <div className="w-full rounded-lg px-3 flex justify-center">
                <input
                    type="submit"
                    className="w-1/3 sm:w-1/4 shadow-md p-3 bg-indigo-600 text-gray-100 hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg"
                    value="Guardar Datos"
                />
                <button
                    type="button"
                    className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => setOpenAlta(false)}
                >
                    Salir
                </button>
            </div>
        </div>
    </form>               
  )
}

export default FormUbicacion