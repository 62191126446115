import React, { useContext } from 'react'
import ContextEstados from '../context/Estados';
import { Url } from './Url'

const FViewPlan = () => {
    const { setOpenVista, planSelected } = useContext(ContextEstados);
    return (
        <div className='mt-10 mb-10 px-10'>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Nombre: </span>{planSelected.nombre}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Fecha de Inicio: </span>{planSelected.fechainicio}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Fecha de Fin: </span>{planSelected.fechafin}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Tiempo Total: </span>{planSelected.tiempototal} Hs</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Costo Total: </span>$ {planSelected.costototal}</p>
            <h3 className='text-3xl mt-4 text-gray-700 font-bold text-center'>Actividades</h3>
            <table className='table-auto w-full border-collapse border-spacing-5 border-slate-400 text-gray-700 rounded-xl mx-auto' >
                <thead>
                    <tr className='uppercase bg-blue-400 text-white'>
                        <th className='border-gray-400 p-2 text-center'>Código</th>
                        <th className='border-gray-400 p-2 text-center'>Descripción</th>
                        <th className='border-gray-400 p-2 text-center'>Tiempo</th>
                        <th className='border-gray-400 p-2 text-center'>Costo</th>
                    </tr>
                </thead>
                <tbody >
                    {
                        typeof planSelected.actividades !== 'undefined' && (
                            planSelected.actividades.map((item, index) => (
                                <tr
                                    key={index}
                                    className={`${index % 2 === 0 ? 'bg-white hover:bg-slate-100' : 'bg-slate-100 hover:bg-slate-200', 'hover:cursor-pointer border-b border-gray-200'}`}
                                >
                                    <td className='border-gray-400 p-1 text-center'>{item.codigo}</td>
                                    <td className='border-gray-400 p-1 text-center'>{item.descripcion}</td>
                                    <td className='border-gray-400 p-1 text-center'>{item.tiempo} Hs.</td>
                                    <td className='border-gray-400 p-1 text-center'>${item.costo}</td>
                                </tr>
                            ))
                        )
                    }
                </tbody>
            </table>

            <div className="w-full rounded-lg mt-10 px-3 flex justify-center">
                <button
                    type="button"
                    className="w-1/4 sm:w-1/6 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => setOpenVista(false)}
                >
                    Salir
                </button>
            </div>
        </div>
    )
}

export default FViewPlan