import React, { useContext } from 'react'
import axios from 'axios'
import ContextEstados from '../context/Estados';
import { Url } from './Url';

const FDeleteOrden = () => {
    const { setIdOrden,ordenSelected, setOpenVista } = useContext(ContextEstados);

    const eliminarOrden = async () => {
        const formData=new FormData()
        formData.append('id',ordenSelected.id);
        try{
            const response = await axios({
                url: Url+'api/ordenesDelete.php',
                method: 'POST',
                data: formData,
            })
            setIdOrden(response.data.results.idot);
            setOpenVista(false)
        } catch (e) {
            console.log(e)
        }
    }
   
    return (
    <div
        className="w-full mx-auto rounded-2xl bg-white"
    >
        <h1 className='text-center text-gray-500 text-3xl mb-2'>
            Eliminar Orden de Trabajo Nº {ordenSelected.numero}
        </h1>
        <h2 className='text-gray-700 text-xl text-center uppercase mb-5'>
            Fecha de programación {ordenSelected.fechacreacion}
        </h2>
        <p className='text-xl text-gray-700'>Equipo: { ordenSelected.equipoact ? (ordenSelected.equipoact):(ordenSelected.equiposol) }</p>
        <div className='w-full mx-auto'>
            <div className='flex'>
                <div className='w-1/2 p-3 '>
                    <div className='w-full sm:items-start'>
                        <p className="block text-gray-600 uppercase font-bold">Fecha de Inicio: {ordenSelected.fechainicio}</p>
                    </div>
                    <div className='w-full'>
                        <p className="block mt-5 text-gray-600 uppercase font-bold">Hora de Inicio: {ordenSelected.horainicio}</p>
                    </div>

                </div>
                <div className='w-1/2 p-3'>
                    <div className="w-full sm:items-start">
                        <p className="block text-gray-600 uppercase font-bold">Estado: {ordenSelected.estado}</p>
                    </div>
                    <div className="w-full sm:items-start">
                        <p className="block mt-5 text-gray-600 uppercase font-bold">Tipo: {ordenSelected.tipo}</p>
                    </div>
                    <div className="w-full sm:items-start">
                        <p className="block mt-5 text-gray-600 uppercase font-bold">Prioridad: {ordenSelected.prioridad}</p>
                    </div>
                </div>
            </div>
            <hr/>
            <div className='flex'>
                <div className='w-1/2 p-3 '>
                    <div className="w-full sm:items-start">
                        <p className="block text-gray-600 uppercase font-bold">Supervisor: {ordenSelected.supervisor}</p>
                    </div>
                </div>
                <div className='w-1/2 p-3 '>
                    <div className="w-full sm:items-start">
                        <p className="block text-gray-600 uppercase font-bold">Responsable: {ordenSelected.responsable}</p>
                    </div>
                </div>
            </div>
            <hr/>
            <div className='flex'>
                <div className='w-full p-3 '>
                    <div className="w-full sm:items-start">
                        <p className="block text-gray-600 uppercase font-bold">Descripción o detalle de trabajo: {ordenSelected.detalletrabajo}</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="pb-1 mt-5">
            <div className="w-full rounded-lg px-3 flex justify-center">
                <button
                    type="button"
                    className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-red-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => eliminarOrden()}
                >
                    Eliminar
                </button>
                <button
                    type="button"
                    className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => setOpenVista(false)}
                >
                    Salir
                </button>
            </div>
        </div>
    </div>
    )
}

export default FDeleteOrden