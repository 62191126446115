import React, { useContext } from 'react'
import ContextEstados from '../context/Estados';
import { Url } from './Url'
import { Link } from 'react-router-dom';

const FViewSolicitud = () => {
    const { setOpenVista, setOpenModalImg, setFormImgView, setImageSelected, solicitudSelected, setIdViewOt } = useContext(ContextEstados);

    const handleImageSelected = (imagen) => {
        setImageSelected(imagen)
        setFormImgView(2)
        setOpenModalImg(true)
    }

    /*const handleOt = () => {
        console.log(solicitudSelected.ot)
    }*/

    return (
        <div className='mt-10 mb-10 px-10'>
            <p className='text-xl text-gray-600'><span className='font-semibold'>Nº de Solicitud: </span>{solicitudSelected.numero}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Equipo: </span>{solicitudSelected.equipo}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Descripción: </span>{solicitudSelected.descripcion}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Solicitante: </span>{solicitudSelected.solicitante}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Nº de OT: </span>{solicitudSelected.numeroot}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Prioridad: </span>{solicitudSelected.prioridad}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Fecha de Creación: </span>{solicitudSelected.fechacreacion}</p>
            <div className='my-10 p-4 bg-slate-50 rounded-xl'>
                <h3 className='text-center text-xl mb-4'>Observaciones</h3>
                {solicitudSelected.observaciones && solicitudSelected.observaciones.map((item, index) => (
                    <p key={index} className='mb-2'><span className='font-bold'>{`${item.fecha} ${item.hora} -  ${item.usuario}:`}</span> {item.observaciones}</p>
                ))}
            </div>
            {solicitudSelected.numeroot && (
                <div>
                    <Link
                        to={"/ordenes"}
                    >
                        <button
                            className='bg-blue-500 p-3 rounded-lg text-white uppercase'
                            onClick={()=>setIdViewOt(solicitudSelected.idot)}
                        >Ver Órden de Trabajo Correspondiente</button>
                    </Link>
                </div>
            )}
            <div className='w-full mx-auto'>
                {solicitudSelected.imagenes && (solicitudSelected.imagenes.map((item, index) => (
                    <div className='w-1/3 p-3'>
                        <button
                            key={index}
                            onClick={() => handleImageSelected(item.imagen)}
                        >
                            <img
                                className='w-full object-cover'
                                src={Url + item.imagen}
                            />
                        </button>
                    </div>
                ))
                )}
            </div>
            <div className="w-full rounded-lg mt-10 px-3 flex justify-center">
                <button
                    type="button"
                    className="w-1/4 sm:w-1/6 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => setOpenVista(false)}
                >
                    Salir
                </button>
            </div>
        </div>
    )
}

export default FViewSolicitud