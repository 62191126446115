import React, { useContext } from 'react'
import ContextEstados from '../context/Estados';
import { Url } from './Url'

const FViewActividad = () => {
    const { setOpenVista, actividadSelected } = useContext(ContextEstados);
    return (
        <div className='mt-10 mb-10 px-10'>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Código: </span>{actividadSelected.codigo}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Descripción: </span>{actividadSelected.descripcion}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Duración (Hs): </span>{actividadSelected.tiempototal}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Costo: </span>{actividadSelected.costototal}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Frecuencia: </span>{actividadSelected.valorperiodo}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Unidad: </span>{actividadSelected.unidad}</p>
            <div className="w-full rounded-lg mt-10 px-3 flex justify-center">
                <button
                type="button"
                className="w-1/4 sm:w-1/6 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                onClick={() => setOpenVista(false)}
                >
                Salir
                </button>
            </div>
        </div>
    )
}

export default FViewActividad