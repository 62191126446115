import React, { useState, useContext, useEffect } from 'react'
import axios from 'axios'
import ContextEstados from '../context/Estados'
import { Url } from './Url'
import Select from 'react-select';

const FormOTRecursos = () => {
    const [recursos, setRecursos] = useState([]);
    const [recurso, setRecurso] = useState(0)
    const { setIdOrdenRecurso, ordenSelected, idUsuario } = useContext(ContextEstados);

    useEffect(() => {
        const consultarRecursos = async () => {
            const formData = new FormData()
            formData.append('idusuario', idUsuario)
            try {
                const result = await axios({
                    url: Url + 'api/recursosListado.php',
                    method: 'POST',
                    data: formData,
                })
                setRecursos(result.data.results);
                setRecurso(result.data.results[0].id);
            } catch (e) {
                console.log(e)
            }
        }
        consultarRecursos();
    }, [])

    const handleSelect = (val) => {
        setRecurso(val.id)
    }

    const agregar = async () => {
        const formData = new FormData()
        formData.append('orden', ordenSelected.id);
        formData.append('recurso', recurso);
        formData.append('idusuario', idUsuario)
        try {
            const response = await axios({
                url: Url + 'api/otRecursosAlta.php',
                method: 'POST',
                data: formData,
            })
            //console.log(response)
            setIdOrdenRecurso(response.data.results.idordenrecurso);
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className="transition-all w-full sm:p-3">
            <h2 className='text-2xl text-gray-600 mb-3'>Agregar Recursos Mecánicos</h2>
            <div className='flex'>
                <div className='w-10/12 flex'>
                    <div className="sm:items-start w-full sm:w-1/2">
                        <label htmlFor="sRecurso" className="block mt-5 text-gray-600 uppercase font-bold">Recurso</label>
                        <Select
                            options={recursos}
                            placeholder="Seleccione Recurso"
                            onChange={(value) => handleSelect(value)}
                            name='sRecurso'
                        />
                    </div>
                </div>
                <div className='w-2/12'>
                    <button
                        type="button"
                        onClick={()=>agregar()}
                        className="w-full mt-10 shadow-md p-3 bg-indigo-600 text-gray-100 hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg"
                    >Agregar</button>
                </div>
            </div>
        </div>
    )
}

export default FormOTRecursos