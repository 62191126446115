import React, { useState, useEffect, useContext } from 'react'
//import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import axios from 'axios';
import { RiFileExcel2Line } from 'react-icons/ri'
import { BsDownload } from "react-icons/bs"
import ContextEstados from '../context/Estados';
import { Url } from './Url';
import TablaRepuestos from './tables/TablaRepuestos';

const ListadoRepuestos = () => {
    const [repuestosSearch, setRepuestosSearch] = useState([])
    const { idRepuesto,
        repuestos,
        buscarRepuesto,
        setRepuestos,
        verStock,
        idUsuario,
        sucursales } = useContext(ContextEstados);

    useEffect(() => {
        const consultaRepuestos = async () => {
            const formData = new FormData()
            formData.append('verStock', verStock)
            formData.append('busqueda', buscarRepuesto)
            formData.append('idusuario', idUsuario)
            try {
                const response = await axios({
                    url: Url + 'api/repuestosListado.php',
                    method: 'POST',
                    data: formData,
                })
                setRepuestos(response.data.results);
            } catch (e) {
                console.log(e)
            }
        }
        consultaRepuestos()
    }, [idRepuesto, verStock])

    useEffect(() => {
        if (repuestos) {
            if (buscarRepuesto !== '') {
                let rData = []
                repuestos.forEach(element => {
                    if (element.codigo.search(new RegExp(buscarRepuesto, 'i')) !== -1 || element.descripcion.search(new RegExp(buscarRepuesto, 'i')) !== -1) {
                        rData.push(element);
                    }
                });
                setRepuestosSearch(rData)
            }
            else {
                setRepuestosSearch(repuestos)
            }
        }
    }, [repuestos, buscarRepuesto])

    const handleDownload = (data) => {
        const libro = XLSX.utils.book_new()
        const hoja = XLSX.utils.json_to_sheet(data)
        XLSX.utils.book_append_sheet(libro, hoja, "Repuestos");
        setTimeout(() => {
            XLSX.writeFile(libro, "Repuestos.xlsx");
        }, 1000);
    }

    return (
        <div>
            {typeof repuestos !== 'undefined' && (
                <>
                    <button
                        type='button'
                        className='mb-3 bg-green-600 hover:bg-green-400 shadow-lg p-3 rounded-md text-white uppercase flex'
                        onClick={() => handleDownload(repuestos)}
                    >
                        <p className='text-xl mr-2'>
                            <RiFileExcel2Line />
                        </p>
                        Exportar a Excel
                    </button>
                    <TablaRepuestos repuestos={repuestosSearch} />
                </>)}
        </div>
    )
}

export default ListadoRepuestos