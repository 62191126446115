import React, { useState, useEffect, useRef, useContext } from 'react'
import { useFormik } from "formik"
import axios from 'axios'
import * as Yup from "yup"
import ContextEstados from '../context/Estados'
import SubirImagenes from './SubirImagenes'
import { Url } from './Url'

const FormUsuario = () => {
    const [ tiposUsuarios, setTiposUsuarios ] = useState([])
    const [ especialidades, setEspecialidades ] = useState([])
    const [ especialidad, setEspecialidad ] = useState(0)
    const [ tipoForm, setTipoForm ] = useState(0)
    const [ costo, setCosto ] = useState(0)
    const { idUsuarioNuevo, setIdUsuarioNuevo, setOpenAlta, tipoUsuario, setTipoUsuario,idUsuario} = useContext(ContextEstados);
    const [ errorMail, setErrorMail ] = useState(false);
    const [ errorNombreUsuario, setErrorNombreUsuario ] = useState(false);

    let tipoImagen = "usuarios";
    let idHtml = "imagenesUsuario";
    const childRef = useRef()

    useEffect(()=>{
        const consultaTipos = async () => {
            const url = Url+'api/tUserList.php';
            const resultado = await axios.get(url);
            setTiposUsuarios(resultado.data.results);
            if (tipoUsuario!==2)
                setTipoUsuario(resultado.data.results[0].id)
        }
        consultaTipos()
    },[])
    useEffect(()=>{
        const consultaEsp = async () => {
            const url = Url+'api/espList.php';
            const resultado = await axios.get(url);
            setEspecialidades(resultado.data.results);
            setEspecialidad(resultado.data.results[0].id)

        }
        consultaEsp()
    },[])

    useEffect(()=>{
        if (tipoUsuario==='2' || tipoUsuario===2)
        {
            
            setTipoForm(2)
        }
        else
        {
            setTipoForm(0)

        }
    },[tipoUsuario])

    useEffect(()=>{
        if (idUsuarioNuevo!==0)
        {
            childRef.current.handleSubmit();            
        }
    },[idUsuarioNuevo])

    const formik = useFormik({
        initialValues:{
            apellido: '',
            nombre: '',
            documento: '',
            mail: '',
            nombreusuario: '',
            password: ''
        },
        validationSchema: Yup.object({
            apellido: Yup.string()
                    .required("El apellido es necesario"),
            nombre: Yup.string()
                    .required("El nombre es necesario"),
            documento: Yup.string(),
            mail: Yup.string()
                    .required("El mail es necesario"),
            nombreusuario: Yup.string()
                    .required("El Nombre de usuario es necesario"),
            
            password: Yup.string(),
            
        }),
        onSubmit: async (valores,{resetForm}) => {
            const formData=new FormData()
            if (!errorMail && !errorNombreUsuario)
            {
                formData.append('apellido',valores.apellido);
                formData.append('nombre',valores.nombre);
                formData.append('documento',valores.documento);
                formData.append('mail',valores.mail);
                formData.append('nombreusuario',valores.nombreusuario);
                formData.append('password',valores.password);
                formData.append('tipo', tipoUsuario);
                formData.append('especialidad',especialidad);
                formData.append('costo',costo);
                formData.append('idusuario',idUsuario);
                try{
                    const response = await axios({
                        url: Url+'api/usuariosAlta.php',
                        method: 'POST',
                        data: formData,
                    })
                    setIdUsuarioNuevo(response.data.results.idusuario);
                    resetForm({ values: ''});
                } catch (e) {
                    console.log(e)
                }
            }
        }
    });
    useEffect(() => {
        const checkUsuario = async () => {
            const formData=new FormData()
            formData.append('mail',formik.values.mail);
            formData.append('nombreusuario',formik.values.nombreusuario);
            try{
                const response = await axios({
                    url: Url+'api/checkUsuario.php',
                    method: 'POST',
                    data: formData,
                    })
                if (response.data.results.id!==0)
                {
                    switch (response.data.results.error) {
                        case '1':
                            setErrorMail(true)
                            break;
                        case '2':
                            setErrorNombreUsuario(true)
                            break;
                        case '3':
                            setErrorMail(true)
                            setErrorNombreUsuario(true)
                            break;
                    
                        default:
                            setErrorMail(false)
                            setErrorNombreUsuario(false)
                            break;
                    }
                }
            } catch (e) {
            }
        }
        checkUsuario()
    },[formik.values.mail,formik.values.nombreusuario])
  return (
    <form
        className="w-full mx-auto p-5 rounded-2xl"
        onSubmit={formik.handleSubmit}
    >
        <div className="transition-all w-full sm:p-3">
            <h2 className='text-3xl uppercase mb-3 text-center'>{ tipoForm===2 ? ('Nuevo Técnico') : ('Nuevo Usuario') }</h2>
            <div className='flex'>
            <div className="sm:items-start sm:w-1/2 w-full mr-2">
                <label htmlFor="apellido" className="block mt-5 text-gray-600 uppercase font-bold">Apellido</label>
                <input 
                    className="py-2 px-3 w-full rounded bg-slate-300" 
                    id="apellido" 
                    placeholder="Ingrese el apellido" 
                    type="text" 
                    value={formik.values.apellido}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoFocus
                />
                { formik.touched.apellido && formik.errors.apellido ? (
                    <div className='w-full block mb-2 text-red-500'>
                        <p>{formik.errors.apellido}</p>
                    </div>
                ) : null }
            </div>
            <div className="sm:items-start sm:w-1/2 w-full ml-2">
                <label htmlFor="nombre" className="block mt-5 text-gray-600 uppercase font-bold">Nombre</label>
                <input 
                    className="py-2 px-3 w-full rounded bg-slate-300" 
                    id="nombre" 
                    placeholder="Ingrese el nombre" 
                    type="text" 
                    value={formik.values.nombre}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                { formik.touched.nombre && formik.errors.nombre ? (
                    <div className='w-full block mb-2 text-red-500'>
                        <p>{formik.errors.nombre}</p>
                    </div>
                ) : null }
            </div>
            </div>
            <div className="sm:items-start">
                <label htmlFor="documento" className="block mt-5 text-gray-600 uppercase font-bold">Documento</label>
                <input 
                    className="py-2 px-3 w-full rounded bg-slate-300" 
                    id="documento" 
                    placeholder="Ingrese el Nº de documento" 
                    type="text" 
                    value={formik.values.documento}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                { formik.touched.documento && formik.errors.documento ? (
                    <div className='w-full block mb-2 text-red-500'>
                        <p>{formik.errors.documento}</p>
                    </div>
                ) : null }
            </div>
            <div className="sm:items-start mt-5">
                <label htmlFor="mail" className="block text-gray-600 uppercase font-bold">Email</label>
                <input 
                    className="py-2 px-3 w-full rounded bg-slate-300" 
                    id="mail" 
                    placeholder="Ingrese el email" 
                    type="email" 
                    value={formik.values.mail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                { errorMail && (
                    <div className='w-full block mb-2 text-red-500'>
                        <p>El Email corresponde a un Usuario</p>
                    </div>
                )}
                { formik.touched.mail && formik.errors.mail ? (
                    <div className='w-full block mb-2 text-red-500'>
                        <p>{formik.errors.mail}</p>
                    </div>
                ) : null }
            </div>
            <div className="sm:items-start">
                <label htmlFor="nombreusuario" className="block mt-5 text-gray-600 uppercase font-bold">Nombre de Usuario</label>
                <input 
                    className="py-2 px-3 w-full rounded bg-slate-300" 
                    id="nombreusuario" 
                    placeholder="Ingrese un nombre de usuario" 
                    type="text" 
                    value={formik.values.nombreusuario}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                { errorNombreUsuario && (
                    <div className='w-full block mb-2 text-red-500'>
                        <p>El Nombre de usuario ya se encuentra en uso</p>
                    </div>
                )}
                { formik.touched.nombreusuario && formik.errors.nombreusuario ? (
                    <div className='w-full block mb-2 text-red-500'>
                        <p>{formik.errors.nombreusuario}</p>
                    </div>
                ) : null }
            </div>
            <div className="sm:items-start">
                <label htmlFor="password" className="block mt-5 text-gray-600 uppercase font-bold">Contraseña</label>
                <input 
                    className="py-2 px-3 w-full rounded bg-slate-300" 
                    id="password" 
                    placeholder="Ingrese una contraseña" 
                    type="password" 
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                { formik.touched.password && formik.errors.password ? (
                    <div className='w-full block mb-2 text-red-500'>
                        <p>{formik.errors.password}</p>
                    </div>
                ) : null }
            </div>
           
            <div className="sm:items-start mt-5">
                <label htmlFor="sTipo" className="block text-gray-600 uppercase font-bold">Tipo de Usuario</label>
                <select className='py-2 px-3 rounded bg-slate-300' value={tipoUsuario} id='sTipo' onChange={(e)=>setTipoUsuario(e.target.value)}>
                    { typeof tiposUsuarios !== "undefined" ? (
                        tiposUsuarios.map(item => (
                            <option key={item.id} value={item.id}>{item.nombre}</option>))
                    ) : null }
                </select>
            </div>
            {tipoForm===2 && (
            <>
            <div className="sm:items-start mt-5">
                <label htmlFor="sEspecialidad" className="block text-gray-600 uppercase font-bold">Especialidad</label>
                <select className='py-2 px-3 rounded bg-slate-300' id='sEspecialidad' onChange={(e)=>setEspecialidad(e.target.value)}>
                    { typeof especialidades !== "undefined" ? (
                        especialidades.map(item => (
                            <option key={item.id} value={item.id}>{item.nombre}</option>))
                    ) : null }
                </select>
            </div>
            <div className="sm:items-start mt-5">
                <label htmlFor="costo" className="block text-gray-600 uppercase font-bold">Costo por hora</label>
                <input 
                    className="py-2 px-3 w-full rounded bg-slate-300" 
                    id="costo" 
                    placeholder="Costo por hora" 
                    type="number" 
                    value={costo}
                    onChange={(e)=>setCosto(e.target.value)}
                />
            </div>
            </>
            )}
            <div className="mt-5 pb-10">
                <SubirImagenes 
                    id={idUsuarioNuevo} 
                    tipo={tipoImagen} 
                    ref={childRef}
                    idHtml={idHtml}
                />
            </div> 
        </div>
        <div className="pb-1">
            <div className="w-full rounded-lg px-3 flex justify-center">
                <input
                    type="submit"
                    className="w-1/4 sm:w-1/6 shadow-md p-3 bg-indigo-600 text-gray-100 hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg"
                    value="Guardar Datos"
                />
                <button
                    type="button"
                    className="w-1/4 sm:w-1/6 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => setOpenAlta(false)}
                >
                    Salir
                </button>
            </div>
        </div>
    </form>               
  )
}

export default FormUsuario