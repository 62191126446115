import React, { useState, useContext } from 'react'
import Layout from "../components/Layout";
import ListadoEquipos from '../components/ListadoEquipos';
import ListadoVariables from '../components/ListadoVariables';
import ListadoMedidores from '../components/ListadoMedidores';
import ContextEstados from '../context/Estados';
import { AiOutlinePlus, AiOutlineSearch } from 'react-icons/ai'

import { RiFileExcel2Line } from 'react-icons/ri'
import { IoQrCodeOutline } from 'react-icons/io5'


const Equipos = () => {
	const [tab, setTab] = useState(1);
	const { setOpenAlta, setFormAlta, equipoSelected, buscarEquipo, setBuscarEquipo, viewQR, setViewQR } = useContext(ContextEstados);

	const Alta = () => {
		switch (tab) {
			case 1:
				setFormAlta(1);
				break;
			case 2:
				setFormAlta(4);
				break;
			default:
				setFormAlta(1);
				break;
		}
		setOpenAlta(true)
	}
	const Import = () => {
		setFormAlta(10);
		setOpenAlta(true);
	}

	return (
		<Layout
			pagina="Equipos / Activos"
		>
			<div className="w-full flex-1 bg-slate-500">
				<h1 className="mt-10 mb-5 text-white text-3xl text-center">Equipos / Activos</h1>
				<div className='px-10 py-1 bg-black/5'>
					<button
						className={`${tab === 1 && "bg-slate-600 shadow-lg "} " text-white text-xl p-3 mr-10 hover:bg-white/10 rounded-lg "`}
						onClick={() => setTab(1)}
					>Equipos / Activos</button>
					{
						equipoSelected.id !== undefined ? (
							<>
								<button
									className={`${tab === 2 && "bg-slate-600 shadow-lg "} " text-white text-xl p-3 mr-10 hover:bg-white/10 rounded-lg "`}
									onClick={() => setTab(2)}
								>Variables de Control</button>
								<button
									className={`${tab === 3 && "bg-slate-600 shadow-lg "} " text-white text-xl p-3 mr-10 hover:bg-white/10 rounded-lg "`}
									onClick={() => setTab(3)}
								>Medidores</button>
							</>
						) : null
					}
				</div>

				<div className='w-full mt-2 bg-white'>
					{
						tab === 1 ? (
							<>
								<div className='w-full h-16 py-2 px-10 bg-slate-400 flex'>
									<button
										className='bg-indigo-600 hover:bg-indigo-400 shadow-lg p-3 rounded-md text-white uppercase flex'
										onClick={() => Alta(true)}
									>
										<AiOutlinePlus className='text-2xl mr-2' />
										Agregar Equipo / Activo
									</button>
									<button
										className='bg-green-600 hover:bg-green-400 shadow-lg ml-5 p-3 rounded-md text-white uppercase flex'
										onClick={() => Import()}
									>
										<p className='text-xl mr-2'>
											<RiFileExcel2Line />
										</p>
										Importar
									</button>
									<button
										className={`shadow-xl ml-5 p-3 hover:bg-slate-300 rounded-md text-white uppercase flex ${!viewQR ? 'bg-slate-400' : 'bg-slate-600'}`}
										onClick={() => setViewQR(!viewQR)}
									>
										<IoQrCodeOutline className='text-2xl mr-2' />
										Ver QR
									</button>
									<div className='py-3 rounded-3xl bg-white w-1/3 mx-auto px-3 flex'>
										<input
											type="text"
											className='w-11/12 outline-none'
											value={buscarEquipo}
											placeholder="Buscar Equipo / Activo"
											onChange={(e) => setBuscarEquipo(e.target.value)}
										/>
										<AiOutlineSearch className='ml-auto text-2xl' />
									</div>
								</div>
								<div className='w-full h-screen p-10 bg-slate-200 overflow-scroll'>
									<ListadoEquipos />
								</div>
							</>
						) : tab === 2 ? (
							<>
								<div className='w-full h-24 py-6 px-10 bg-slate-400'>
									<button
										className='bg-indigo-600 hover:bg-indigo-400 p-3 rounded-md text-white uppercase flex'
										onClick={() => Alta(true)}
									>
										<AiOutlinePlus className='text-2xl mr-2' />
										Agregar Variable
									</button>
								</div>
								<div className='w-full h-full p-10 bg-slate-200'>
									<ListadoVariables />
								</div>
							</>
						) : tab === 3 ? (
							<>
								<div className='w-full h-24 py-6 px-10 bg-slate-400'>
								</div>
								<div className='w-full h-full p-10 bg-slate-200'>
									<ListadoMedidores />
								</div>
							</>

						) : null
					}
				</div>
			</div>
		</Layout>
	)
}

export default Equipos