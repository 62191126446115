import React, { useState, useEffect, useRef, useContext } from 'react'
import { useFormik } from "formik"
import axios from 'axios'
import * as Yup from "yup"
import ContextEstados from '../context/Estados'
import SubirImagenes from './SubirImagenes'
import { Url } from './Url'

const FEditVariable = () => {
    const [ unidades, setUnidades ] = useState([]);
    const [ unidad, setUnidad ] = useState(0)
    const { idVariable, variableSelected,setIdVariable, setVariableSelected, setOpenVista } = useContext(ContextEstados);
    

 
    useEffect(()=>{
        const consultaUnidades = async () => {
            const url = Url+'api/lUnidades.php';
            const resultado = await axios.get(url);
            setUnidades(resultado.data.results);
            setUnidad(variableSelected.idunidad)
        }
        consultaUnidades()
    },[])

    const formik = useFormik({
        initialValues:{
            nombre: variableSelected.nombre
        },
        validationSchema: Yup.object({
            nombre: Yup.string()
                    .required("El nombre es necesario")
        }),
        onSubmit: async (valores,{resetForm}) => {
            const formData=new FormData()
            formData.append('id',variableSelected.id);
            formData.append('nombre',valores.nombre);
            formData.append('unidad',unidad);
            try{
                const response = await axios({
                    url: Url+'api/varsSet.php',
                    method: 'POST',
                    data: formData,
                })
            setIdVariable(response.data.results.idvariable);
            //setIdVariable(0);
            setOpenVista(false);
            } catch (e) {
                console.log(e)
            }
        }
    });
  return (
    <form
        className="w-full mx-auto p-5 rounded-2xl"
        onSubmit={formik.handleSubmit}
    >
        <div className="transition-all w-full sm:p-3">
            <div className="sm:items-start">
            <label htmlFor="nombre" className="block mt-5 text-gray-600 uppercase font-bold">Nombre de Variable</label>
                <input 
                    className="py-2 px-3 w-full rounded bg-slate-300" 
                    id="nombre" 
                    placeholder="Ingrese un nombre de Variable" 
                    type="text" 
                    value={formik.values.nombre}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoFocus
                />
                { formik.touched.nombre && formik.errors.nombre ? (
                    <div className='w-full block mb-2 text-red-500'>
                        <p>{formik.errors.nombre}</p>
                    </div>
                ) : null }
            </div>
        </div>
        <div className="sm:items-start sm:p-3 mt-5">
                <label htmlFor="sUnidades" className="block text-gray-600 uppercase font-bold">Unidad de medida</label>
                <select 
                    className='py-2 px-3 w-full rounded bg-slate-300' 
                    id='sUnidades' 
                    onChange={(e)=>setUnidad(e.target.value)}
                    value={unidad}
                >
                    { typeof unidades !== "undefined" ? (
                        unidades.map(item => (
                            <option key={item.id} value={item.id}>{item.nombre}</option>))
                    ) : null }
                </select>
            </div>

        <div className="pb-1 mt-5">
            <div className="w-full rounded-lg px-3 flex justify-center">
                <input
                    type="submit"
                    className="w-1/3 sm:w-1/4 shadow-md p-3 bg-indigo-600 text-gray-100 hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg"
                    value="Guardar Datos"
                />
                <button
                    type="button"
                    className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => setOpenVista(false)}
                >
                    Salir
                </button>
            </div>
        </div>
    </form>               
  )
}

export default FEditVariable