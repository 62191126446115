import React, { useState, forwardRef, useImperativeHandle, useContext } from 'react'
import axios from 'axios';
import { Url } from './Url';
import { AiOutlineCloudUpload } from 'react-icons/ai'
import { TiDeleteOutline } from 'react-icons/ti'
import ContextEstados from '../context/Estados';

const SubirImagenes = forwardRef((props, ref) => {
	const [file, setFile] = useState([]);
	const [selectedFile, setSelectedFile] = useState([]);
	const { setIdEquipo, setIdUsuarioNuevo, setIdRepuesto, setIdSolicitud } = useContext(ContextEstados);

	function uploadSingleFile(e) {
		let ImagesArray = Object.entries(e.target.files).map((e) =>
			URL.createObjectURL(e[1])
		);
		setFile([...file, ...ImagesArray]);
		let rData = [...selectedFile];
		rData.push(e.target.files[0])
		setSelectedFile(rData)
	}

	useImperativeHandle(
		ref,
		() => ({
			handleSubmit
		}),
	)

	function deleteFile(e) {
		const s = file.filter((item, index) => index !== e);
		setFile(s);
		const f = selectedFile.filter((item, index) => index !== e);
		setSelectedFile(f);
	}

	const handleSubmit = async () => {
		const archivos = new FormData();
		selectedFile.forEach(file => {
			archivos.append('avatar[]', file);
		});
		archivos.append("idpaquete", props.id);
		archivos.append("tipo", props.tipo);
		try {
			const response = await axios({
				method: 'POST',
				url: Url + 'api/uploadimages.php',
				data: archivos,
				headers: { "Content-Type": "multipart/form-data" },
			});
			setFile([]);
			setSelectedFile([]);
			setIdEquipo(0);
			setIdUsuarioNuevo(0);
			setIdRepuesto(0);
			setIdSolicitud(0);
		} catch (error) {
			console.log(error)
		}
	}

	return (
		<div className="flex w-full">
			{file.length > 0 &&
				file.map((item, index) => {
					return (
						<div className='lg:w-1/6 md:w-1/4 w-1/2 p-2' key={item}>
							<img className='rounded' src={item} alt="" />
							<button className='flex flex-col justify-center items-center w-full text-red-500 text-2xl bg-gray-300 border-2 border-red-200 p-2 rounded' onClick={() => deleteFile(index)}>
								<TiDeleteOutline />
							</button>
						</div>
					);
				})}
			<div className="lg:w-1/6 md:w-1/4 w-1/2 p-2 h-32">
				<label className='flex flex-col justify-center items-center w-full h-32 bg-gray-300 rounded-lg hover:cursor-pointer text-gray-600' htmlFor={props.idHtml}>
					<div className='text-3xl'>
						<AiOutlineCloudUpload />
					</div>
					<p className='text-center'>Agregar Imagen</p>
					<input
						className='hidden'
						type="file"
						id={props.idHtml}
						disabled={file.length === 5}
						onChange={uploadSingleFile}
						multiple
					/>
				</label>
			</div>
		</div>
	);
})

export default SubirImagenes
