import React, { useState, useEffect, useRef, useContext } from 'react'
import { useFormik } from "formik"
import axios from 'axios'
import * as Yup from "yup"
import ContextEstados from '../context/Estados'
import SubirImagenes from './SubirImagenes'
import Select from 'react-select';
import { Url } from './Url'
import Alerta from './Alerta'

const FEditSolicitud = () => {
    const [equipos, setEquipos] = useState([])
    const [view, setView] = useState(1)
    const [equipo, setEquipo] = useState(0)
    const [eqSelected, setEqSelected] = useState({})
    const [prioridades, setPrioridades] = useState([])
    const [prioridad, setPrioridad] = useState(0)
    const [sectores, setSectores] = useState([])
    const [sector, setSector] = useState(0)
    const [tiposFallas, setTiposFallas] = useState([])
    const [tipoFalla, setTipoFalla] = useState(0)
    const [tipoSol, setTipoSol] = useState(0)
    const [observaciones, setObservaciones] = useState("")
    const [saveObs, setSaveObs] = useState(false)
    const { solicitudSelected, setSolicitudSelected, idSolicitud, setIdSolicitud, setOpenVista, idUsuario, setMensaje } = useContext(ContextEstados);


    let tipoImagen = "solicitudes";
    let idHtml = "imagenesSolicitud";
    const childRef = useRef()

    useEffect(() => {
        setTipoSol(solicitudSelected.tipo)
    }, [])

    useEffect(() => {
        const consultaEquipos = async () => {
            const formData = new FormData()
            formData.append('idusuario', idUsuario)
            try {
                const response = await axios({
                    url: Url + 'api/equiposListado.php',
                    method: 'POST',
                    data: formData,
                })
                setEquipos(response.data.results);

                setEquipo(solicitudSelected.idequipo)
                //setEquipo(response.data.results[0].id);
            } catch (e) {
                console.log(e)
            }
        }
        consultaEquipos()
    }, [])

    useEffect(() => {
        equipos.forEach(element => {
            if (element.id === equipo) {
                setEqSelected(element)
            }
        });
    }, [equipos, equipo])

    useEffect(() => {
        const consultaSectores = async () => {
            const formData = new FormData()

            try {
                const response = await axios({
                    url: Url + 'api/sectoresList.php',
                    method: 'POST',
                    data: formData,
                })
                setSectores(response.data.results);
                setSector(solicitudSelected.sector)
            } catch (e) {
                console.log(e)
            }
        }
        consultaSectores()
    }, [])

    useEffect(() => {
        const consultaPrioridades = async () => {
            const url = Url + '/api/lPrioridades.php';
            const resultado = await axios.get(url);
            setPrioridades(resultado.data.results);
            setPrioridad(solicitudSelected.idprioridad)
        }
        consultaPrioridades()
    }, [])

    useEffect(() => {
        const consultaTipoFalla = async () => {
            const url = Url + '/api/tipoFallaList.php';
            const resultado = await axios.get(url);
            setTiposFallas(resultado.data.results);
            setTipoFalla(solicitudSelected.tipofalla)
        }
        consultaTipoFalla()
    }, [])


    useEffect(() => {
        if (idSolicitud !== 0) {
            childRef.current.handleSubmit();

        }
    }, [idSolicitud])

    useEffect(() => {
        const checkObs = () => {
            if (observaciones!=='') {
                setSaveObs(true)
            }
            else {
                setSaveObs(false)
            }
        }
        checkObs()
    }, [observaciones])

    const eliminarImagen = async () => {
        const formData = new FormData()
        formData.append('t', "solicitudes");
        formData.append('id', solicitudSelected.id);

        let oData = { ...solicitudSelected };
        oData.imagen = null;
        setSolicitudSelected(oData);
        try {
            const response = await axios({
                url: Url + 'api/deleteimages.php',
                method: 'POST',
                data: formData,
            })
            setIdSolicitud(response.data.results.id);
        } catch (e) {
            console.log(e)
        }
    }

    const formik = useFormik({
        initialValues: {
            descripcion: solicitudSelected.descripcion,
            solicitante: solicitudSelected.solicitante
        },
        validationSchema: Yup.object({
            descripcion: Yup.string()
                .required("Debe ingresar una descripción"),
            solicitante: Yup.string()
                .required("Debe ingresar un solicitante")
        }),
        onSubmit: async (valores, { resetForm }) => {
            const formData = new FormData()
            formData.append('id', solicitudSelected.id);
            formData.append('descripcion', valores.descripcion);
            formData.append('tipofalla', tipoFalla);
            formData.append('tiposolicitud', tipoSol);
            formData.append('solicitante', valores.solicitante);
            formData.append('equipo', equipo);
            formData.append('prioridad', prioridad);
            formData.append('sector', sector);
            formData.append('idusuario', idUsuario);
            try {
                const response = await axios({
                    url: Url + 'api/solicitudesSet.php',
                    method: 'POST',
                    data: formData,
                })
                console.log(response)
                setIdSolicitud(response.data.results.idsolicitud);
                resetForm({ values: '' });
                setOpenVista(false)
            } catch (e) {
                console.log(e)
            }
        }
    });

    const handleSelect = (val) => {
        setEquipo(val.id)
    }

    const addObservaciones = async () => {
        const formData = new FormData()
        formData.append('id', solicitudSelected.id);
        formData.append('observaciones', observaciones)
        formData.append('idusuario', idUsuario)
        try {
            const response = await axios({
                url: Url + 'api/addSolObs.php',
                method: 'POST',
                data: formData,
            })
            setObservaciones("")
            setMensaje("Se agregó la observación a la Solicitud de Trabajo")
            //setEquipo(response.data.results[0].id);
        } catch (e) {
            console.log(e)
        }
    }

    const finalizarSolicitud = async () => {
        const formData = new FormData()
        formData.append('id', solicitudSelected.id);
        formData.append('idusuario', idUsuario)
        try {
            const response = await axios({
                url: Url + 'api/finSol.php',
                method: 'POST',
                data: formData,
            })

            setView(1);
            setOpenVista(false)
            setIdSolicitud(response.data.results.idsolicitud);
            //setEquipo(response.data.results[0].id);
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <>
            {view === 1 ? (
                <form
                    className="w-full mx-auto p-2 rounded-2xl"
                    onSubmit={formik.handleSubmit}
                >
                    <div className="transition-all w-full sm:p-3">
                        <h2 className='text-3xl uppercase mb-3 text-center'>Editar Solicitud de Trabajo Nº {solicitudSelected.numero}</h2>
                        <div className="sm:items-start mt-5">
                            <label htmlFor="sEquipos" className="block text-gray-600 uppercase font-bold">Equipo / Activo</label>
                            <Select
                                options={equipos}
                                placeholder="Seleccione Equipo"
                                onChange={(value) => handleSelect(value)}
                                name='sEquipo'
                                value={eqSelected}
                            />
                        </div>
                        <div className="sm:items-start mt-5">
                            <label htmlFor="descripcion" className="block text-gray-600 uppercase font-bold">Descripción</label>
                            <textarea
                                className="py-2 px-3 w-full rounded border border-gray-300"
                                id="descripcion"
                                placeholder="Descripción de Solicitud"
                                type="text"
                                value={formik.values.descripcion}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            ></textarea>
                            {formik.touched.descripcion && formik.errors.descripcion ? (
                                <div className='w-full block mb-2 text-red-500'>
                                    <p>{formik.errors.descripcion}</p>
                                </div>
                            ) : null}
                        </div>
                        <div className="sm:items-start mt-5">
                            <label htmlFor="sFalla" className="block text-gray-600 uppercase font-bold">Tipo de Falla</label>
                            <select className='py-2 px-3 w-full rounded border border-gray-300' id='sFalla' value={tipoFalla} onChange={(e) => setTipoFalla(e.target.value)}>
                                {typeof tiposFallas !== "undefined" ? (
                                    tiposFallas.map(item => (
                                        <option key={item.id} value={item.id}>{item.nombre}</option>))
                                ) : null}
                            </select>
                        </div>
                        <div className="sm:items-start mt-5">
                            <label htmlFor="solicitante" className="block text-gray-600 uppercase font-bold">Solicitante</label>
                            <input
                                className="py-2 px-3 w-full rounded border border-gray-300"
                                id="solicitante"
                                placeholder="Solicitante del Trabajo"
                                type="text"
                                value={formik.values.solicitante}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.solicitante && formik.errors.solicitante ? (
                                <div className='w-full block mb-2 text-red-500'>
                                    <p>{formik.errors.solicitante}</p>
                                </div>
                            ) : null}
                        </div>
                        <div className="sm:items-start mt-5">
                            <label htmlFor="sPrioridades" className="block text-gray-600 uppercase font-bold">Prioridad</label>
                            <select
                                className='py-2 px-3 w-full rounded border border-gray-300'
                                id='sPrioridades'
                                onChange={(e) => setPrioridad(e.target.value)}
                                value={prioridad}
                            >
                                {typeof prioridades !== "undefined" ? (
                                    prioridades.map(item => (
                                        <option key={item.id} value={item.id}>{item.nombre}</option>))
                                ) : null}
                            </select>
                        </div>
                        <div className="sm:items-start mt-5">
                            <label htmlFor="sSector" className="block text-gray-600 uppercase font-bold">Sector</label>
                            <select
                                className='py-2 px-3 w-full rounded border border-gray-300'
                                id='sSector'
                                value={sector}
                                onChange={(e) => setSector(e.target.value)}
                            >
                                {typeof sectores !== "undefined" ? (
                                    sectores.map(item => (
                                        <option key={item.id} value={item.id}>{item.nombre}</option>))
                                ) : null}
                            </select>
                        </div>
                    </div>
                    <div className="pb-10">
                        {solicitudSelected.imagen ? (
                            <div className='lg:w-1/6 md:w-1/4 w-1/2 p-2'>
                                <img src={`${Url}${solicitudSelected.imagen}`} />
                                <button
                                    type='button'
                                    className='w-full bg-red-500 text-white rounded-b-md'
                                    onClick={() => eliminarImagen()}
                                >Eliminar</button>
                            </div>
                        ) : (
                            <SubirImagenes
                                id={solicitudSelected.id}
                                tipo={tipoImagen}
                                ref={childRef}
                                idHtml={idHtml}
                            />
                        )}
                    </div>
                    <Alerta tipo={'success'}/>
                    <div className="transition-all w-full flex mb-5">
                        <div className="w-full rounded-lg">
                            <label htmlFor="observaciones" className="block text-gray-600 uppercase font-bold">Observaciones / Respuesta</label>
                            <textarea
                                className="py-2 px-3 w-full rounded border border-gray-300"
                                id="observaciones"
                                placeholder="Agregar una observación, respuesta o comentario"
                                type="text"
                                value={observaciones}
                                onChange={(e) => setObservaciones(e.target.value)}
                            ></textarea>
                        </div>
                        <button
                            type='button'
                            className={`w-1/3 sm:w-1/4 shadow-md p-2 ml-2 mt-5  text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg ${saveObs ? 'bg-orange-500' : 'bg-orange-200'}`}
                            onClick={() => addObservaciones()}
                            disabled={!saveObs}
                        >Agregar Observación</button>
                    </div>
                    <div className="pb-1">
                        <div className="w-full rounded-lg px-3 flex justify-center">
                            <input
                                type="submit"
                                className="w-1/3 sm:w-1/4 shadow-md p-3 bg-indigo-600 text-gray-100 hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg"
                                value="Guardar Datos"
                            />
                            <button
                                type="button"
                                className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                                onClick={() => setOpenVista(false)}
                            >
                                Salir
                            </button>
                            <button
                                type="button"
                                className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-red-500 text-white hover:bg-red-400 transition-all uppercase font-bold rounded-lg"
                                onClick={() => setView(2)}
                            >
                                Finalizar Solicitud
                            </button>
                        </div>
                    </div>
                </form>
            ) : (
                <div className="transition-all w-full sm:p-3">
                    <h2 className='text-3xl mb-5 text-center'>Realmente desea Finalizar la Solicitud de Trabajo Nº {solicitudSelected.numero}</h2>
                    <div className="w-full rounded-lg px-3 flex justify-center">
                        <button
                            type="button"
                            className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-red-500 text-white hover:bg-red-400 transition-all uppercase font-bold rounded-lg"
                            onClick={() => finalizarSolicitud()}
                        >
                            Confirmar Finalizar
                        </button>
                        <button
                            type="button"
                            className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                            onClick={() => setView(1)}
                        >
                            Cancelar
                        </button>
                    </div>
                </div>
            )}
        </>
    )
}

export default FEditSolicitud