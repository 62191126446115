import React, { useEffect, useContext } from 'react'
import axios from 'axios';
import { FaEye, FaPen, FaTrash } from 'react-icons/fa'
import ContextEstados from '../context/Estados';
import { Url } from './Url';

const ListadoRecursos = () => {
    const { idRecursoMec, recursosMec, setRecursosMec, buscarRecurso, idUsuario, setRecMSelected, setTipoModal, setOpenVista } = useContext(ContextEstados);

    useEffect(() => {
        const consultaRecursos = async () => {
            const formData = new FormData()
            formData.append('busqueda', buscarRecurso)
            formData.append('idusuario', idUsuario)
            try {
                const response = await axios({
                    url: Url + 'api/recursosListado.php',
                    method: 'POST',
                    data: formData,
                })
                setRecursosMec(response.data.results)
            } catch (e) {
                console.log(e)
            }
        }
        consultaRecursos()
    }, [idRecursoMec, idUsuario, buscarRecurso])


    const verRecurso = async (item) => {
        setRecMSelected(item)
        setTipoModal()
        setOpenVista(true);
    }
    const editarRecurso = async (item) => {
        setRecMSelected(item);
        setTipoModal(90)
        setOpenVista(true);
    }
    const eliminarRecurso = async (item) => {
        setRecMSelected(item);
        setTipoModal(91)
        setOpenVista(true);
    }

    return (
        <div>
            <table className='table-auto w-full border-collapse border-spacing-5 border-slate-400 text-gray-700 rounded-xl mx-auto mt-1' >
                <thead>
                    <tr className='uppercase bg-blue-400 text-white'>
                        <th className='border-gray-400 p-2'>
                            Nombre o Descripción
                        </th>
                        <th className='border-gray-400 p-2'>
                            Acciones
                        </th>
                    </tr>
                </thead>
                <tbody >
                    {
                        recursosMec && (
                            recursosMec.map((item, index) => (
                                <tr
                                    key={index}
                                    className={`hover:bg-slate-200 border-b border-gray-200 ${index % 2 === 0 ? 'bg-white' : 'bg-slate-100'}`}
                                >
                                    <td className='border-gray-400 p-1 text-center'>{item.descripcion}</td>
                                    <td className='p-1 flex justify-center'>
                                        {/*}<button
                                            className='bg-indigo-500 p-3 rounded-md text-white'
                                            onClick={() => verRecurso(item)}
                                        >
                                            <FaEye />
                                        </button>{*/}
                                        <button
                                            className='bg-gray-400 p-3 rounded-md text-white ml-3'
                                            onClick={() => editarRecurso(item)}
                                        >
                                            <FaPen />
                                        </button>
                                        <button
                                            className='bg-red-400 p-3 rounded-md text-white ml-3'
                                            onClick={() => eliminarRecurso(item)}
                                        >
                                            <FaTrash />
                                        </button>
                                    </td>
                                </tr>
                            ))
                        )
                    }
                </tbody>
            </table>
        </div>
    )
}

export default ListadoRecursos