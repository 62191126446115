import React, { useState, useContext, useEffect, Fragment, useRef } from 'react'

import { Dialog, Transition } from '@headlessui/react'
import { AiFillHome, AiOutlineBarChart } from "react-icons/ai"
import { FaTruck, FaTools, FaToolbox, FaUserAlt, FaListUl, FaCalendarCheck, FaHandPaper, FaUserCog } from "react-icons/fa"
import { MdOutlinePhoneAndroid } from "react-icons/md"
import { FiMenu } from "react-icons/fi"
import { GrClose } from "react-icons/gr"
import { HiLocationMarker } from "react-icons/hi"
import { IoIosPeople } from "react-icons/io"
import { VscChecklist } from "react-icons/vsc"
import { ImTree } from "react-icons/im";
import authContext from '../context/auth/authContext'
import ContextEstados from '../context/Estados'

import SideLink from './SideLink'

const SideBar = () => {
	const [show, setShow] = useState(false)
	const AuthContext = useContext(authContext);
	const { cerrarSesion } = AuthContext;
	const { roles, sucursales, setTipoModal, setOpenVista } = useContext(ContextEstados)
	const mail = localStorage.getItem('sgpp_n');


	const cancelButtonRef = useRef(null)

	const chSucursal = () => {
		setTipoModal(50)
		setOpenVista(true);
	}

	useEffect(() => {
		const checkRoles = () => {
		}
		checkRoles()
	}, [roles])
	return (
		<div className='fixed z-40'>
			<button type='button'
				className={`${show ? "hidden" : "block"} z-50 relative top-2 left-2 text-white bg-slate-600 hover:bg-slate-500 flex items-center justify-center w-10 h-10 rounded-full`}
				onClick={() => setShow(true)}
			>
				<FiMenu />
			</button>
			<Transition.Root show={show} as={Fragment}>
				<Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setShow}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed z-10 inset-0 overflow-y-auto">
						<div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 w-0 translate-x-0"
								enterTo="opacity-100 w-full scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-x-0 scale-100"
								leaveTo="opacity-0 translate-x-4 translate-x-0"
							>
								<Dialog.Panel className="bg-black/90 fixed top-0 z-10 inset-0 text-left overflow-hidden overflow-y-auto shadow-xl transform transition-all 2xl:w-1/6 lg:w-1/4 md:w-1/2 w-11/12">
									<button
										className={`${!show ? "hidden" : "block"} relative top-2 left-2 text-white bg-gray-700 hover:bg-gray-600 flex items-center justify-center w-10 h-10 rounded-full`}
										type='button'
										value='Close Sidebar'
										onClick={() => setShow(false)}
									>
										<GrClose />
									</button>
									<div className="px-4 pt-2 sm:p-6">
										<div className="sm:flex sm:items-start">
											<div
												className={`${!show ? "hidden" : "block"}`}
											>
												<div className='overflow-y-auto flex-1'>
													<ul className="relative mt-10 mb-5 pb-5 border-b border-gray-500">
														{sucursales.length > 1 ? (
															<button
																className='w-full text-xl text-white p-2 border border-white rounded-lg' type='button' onClick={() => chSucursal()}>{sucursales[0].nombre}</button>
														) : (
															<p className='text-center text-white text-xl'>{ }</p>
														)}
													</ul>
													<ul className="relative mt-5 mb-5 pb-5 border-b border-gray-500">
														<SideLink link="/" id={0} label="Inicio"><AiFillHome /></SideLink>
														{(roles.includes("1") || roles.includes("10")) && (
															<>
																<SideLink link="/ubicaciones" id={10} label="Ubicaciones"><HiLocationMarker /></SideLink>
																<SideLink link="/equipos" id={1} label="Equipos / Activos"><FaTruck /></SideLink>
																<SideLink link="/tipos" id={12} label="Tipos de Equipos o Activos"><ImTree /></SideLink>
																<SideLink link="/tecnicos" id={2} label="Tecnicos"><IoIosPeople /></SideLink>
																<SideLink link="/repuestos" id={3} label="Repuestos"><FaToolbox /></SideLink>
																<SideLink link="/recursos" id={14} label="Recursos Mecánicos"><FaTools /></SideLink>
															</>
														)}
													</ul>
													<ul className="relative mb-5 pb-5 border-b border-gray-500">
														{(roles.includes("1") || roles.includes("21") || roles.includes("22") || roles.includes("31")) && (
															<SideLink link="/planes" id={4} label="Planes de Mantenimiento"><FaListUl /></SideLink>
														)
														}
														{(roles.includes("1") || roles.includes("23") || roles.includes("24") || roles.includes("32")) && (
															<SideLink link="/planeamiento" id={5} label="Planeamiento"><FaCalendarCheck /></SideLink>
														)
														}
														{(roles.includes("1") || roles.includes("25") || roles.includes("26") || roles.includes("33")) && (
															<SideLink link="/ordenes" id={6} label="Órdenes de Trabajo"><VscChecklist /></SideLink>
														)
														}
														{(roles.includes("1") || roles.includes("27") || roles.includes("28") || roles.includes("34")) && (
															<SideLink link="/solicitudes" id={7} label="Solicitudes de Trabajo"><FaHandPaper /></SideLink>
														)
														}
														{(roles.includes("1") || roles.includes("27") || roles.includes("28") || roles.includes("34")) && (
															<SideLink link="/reporting" id={8} label="Reportes"><AiOutlineBarChart /></SideLink>
														)
														}
													</ul>
													{roles.includes("1") && (
														<ul className="relative mb-5 pb-5 border-b border-gray-500">
															<SideLink link="/administracionUsuarios" id={9} label="Administrar Usuarios"><FaUserCog /></SideLink>
														</ul>
													)}
													<ul className="relative mb-5 pb-5 border-b border-gray-500">
														<a href="https://presidentenergy.com.ar/sistemagestion/res/app-release.apk" target="_blank" rel="noreferrer" id={12}>
															<button className='text-white flex' type='button'><MdOutlinePhoneAndroid className='mt-1 ml-4 mr-2' />Descargar Aplicación</button>

														</a>
													</ul>
													<ul className="relative mb-10 pb-10">
														<SideLink link="/perfil" id={11} label={mail}><FaUserAlt /></SideLink>
														<button className='bg-orange-500 hover:bg-orange-400 text-white text-center font-bold uppercase p-1 mb-2 rounded-xl w-full' onClick={() => cerrarSesion()}>Cerrar Sesión</button>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		</div>
	)
}

export default SideBar